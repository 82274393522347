import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenstorageService } from './tokenstorage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  API_URL: string = environment.serverUrl;

  constructor(private http: HttpClient ,private tokenStorage : TokenstorageService) { }

  authenticate(username, password) {
    var index = this.API_URL.indexOf("api/v1");
    return this.http.post<any>(this.API_URL.substring(0,index) + "authenticate", { username, password }).pipe(
      map(
        userData => {   
          this.tokenStorage.saveToken(userData.token);
          this.tokenStorage.saveUser(userData.userDTO);
          this.tokenStorage.saveMenu(JSON.stringify(userData.listSystemRoleMenuAccessDTO));                    
          return userData;
        }
      )
    );
  }
}