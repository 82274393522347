import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoleService } from 'src/services/role.service';
import { MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { TokenstorageService } from 'src/services/tokenstorage.service';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  dsRoles: any;

  constructor(private fb: FormBuilder, private apiRoleServ: RoleService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddRoleComponent>) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit() {
    this.createForm();
    this.loadAllRoles();
  }

  loadAllRoles() {
    this.apiRoleServ.getAll().subscribe(data => {
      this.dsRoles = data
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  checkIFRolesAdded(roleName: String): boolean {
    let searchRole = this.dsRoles.filter(t => t.roleName == roleName);
    if (searchRole && searchRole.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  createForm() {
    
    this.form = this.fb.group({
      "roleName": [null, [Validators.required]]
    });
  }

  onSubmit(btnName) {

    if (this.checkIFRolesAdded(this.form.value["roleName"]) == false) {
      if (btnName == "btnSave") {
        var formData = this.form.value;
        formData.id = 0;
        formData.createdBy = this.loggedUserId;
        formData.createdAt = new Date();
        this.apiRoleServ.create(formData).subscribe(data => {
          this.dialogRef.close({ refresh : true});
        });
      } else {
        if(btnName == "btnCancel") {
          this.dialogRef.close();
        }
      }
    } else {
      this.snackBar.open("Error Message", "Role already added", {
        duration: 2000,
      });
    }
  }

}
