import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InterLockService {
  API_URL: string = environment.serverUrl + "/interLockByPassRequest";

  constructor(private http : HttpClient) { }

  getAll(): Observable<any> {
    return this
      .http
      .get(`${this.API_URL}`);
  }

  getById(id : number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  } 

  getByTagId(id : number) {
    return this
      .http
      .get(`${this.API_URL}/getByTagId/${id}`);
  } 

  getByStatus(statusId : number) {
    return this
      .http
      .get(`${this.API_URL}/getByStatus/${statusId}`);
  } 

  getByStatusAndDateRange(statusId : number,startDate : Date ,endDate : Date) {   
    return this
      .http
      .get(`${this.API_URL}/getByStatusAndDateRange/${statusId}/${startDate}/${endDate}`);    
  }  

  create(debtor: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, debtor);
  }
}
