import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobCardService {

  API_URL: string = environment.serverUrl + "/jobCard";

  constructor(private http : HttpClient) { }

  getAll(): Observable<any> {
    return this
      .http
      .get(`${this.API_URL}`);
  }

  getById(id : number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  } 

  getAllByDateRange(startDate : Date ,endDate : Date,) {   
    return this
      .http
      .get(`${this.API_URL}/getAllByDateRange/${startDate}/${endDate}`);    
  }

  getAllByDepartmentTOBetweenDates(departmentTO : number,startDate : Date ,endDate : Date,) {   
    return this
      .http
      .get(`${this.API_URL}/getAllByDepartmentTOBetweenDates/${departmentTO}/${startDate}/${endDate}`);    
  }

  getAllByDepartmentFromBetweenDates(departmentFrom : number,startDate : Date ,endDate : Date,) {   
    return this
      .http
      .get(`${this.API_URL}/getAllByDepartmentFromBetweenDates/${departmentFrom}/${startDate}/${endDate}`);    
  }

  getAllJobcardByTypeBetweenDates(jobcardTypeId : number,startDate : Date ,endDate : Date,) {   
    return this
      .http
      .get(`${this.API_URL}/getAllJobcardByTypeBetweenDates/${jobcardTypeId}/${startDate}/${endDate}`);    
  }

  getAllByDepartmentToAndFromFromBetweenDates(departmentFrom : number,departmentTo : number,startDate : Date ,endDate : Date) {   
    return this
      .http
      .get(`${this.API_URL}/getAllByDepartmentToAndFromFromBetweenDates/${departmentFrom}/${departmentTo}/${startDate}/${endDate}`);    
  }

  getByFilterOptions(departmentFrom : number,departmentTo : number,startDate : Date ,endDate : Date) {   
    return this
      .http
      .get(`${this.API_URL}/getByFilterOptions/${departmentFrom}/${departmentTo}/${startDate}/${endDate}`);    
  } 
  
  getAllJobCardByUserIdAndStatus(assigneeId : number ,statusId : number) {   
    return this
      .http
      .get(`${this.API_URL}/getAllJobCardByUserIdAndStatus/${assigneeId}/${statusId}`);    
  }

  getAllActiveJobCardsOrByDepartment(departmentId : number) {   
    return this
      .http
      .get(`${this.API_URL}/getAllActiveJobCardsOrByDepartment/${departmentId}`);    
  }

  create(debtor: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, debtor);
  }
  
}
