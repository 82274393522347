<div class="container-fluid" style="margin-top: 10px;">
    <mat-card>
        <mat-card-header>
            <mat-card-title>My Dashboard</mat-card-title><span class="example-spacer"></span>
            <button mat-raised-button color="primary" (click)="refreshData()">Refresh</button>
        </mat-card-header>
        <mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
        </mat-progress-bar>
        <form fxLayout="column" [formGroup]="form">
            <mat-card-content>
                <mat-tab-group>
                    <mat-tab label="My Details">
                        <ng-template mat-tab-label>
                            <span class="material-icons class-menu-list" style="margin:5px;">
                                trending_down
                            </span>
                            Department Perfomance
                        </ng-template>
                        <mat-card>
                            <mat-card-content>
                                <div class="alert alert-danger" role="alert">
                                    Please Note More Reports Coming And Historical Reports
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <mat-form-field fxFlex>
                                            <mat-select formControlName="userDepartmentId" matInput
                                                placeholder="Department" (selectionChange)="onChangeDepartment($event)">
                                                <mat-option *ngFor="let item of dsDepartment" [value]="item.value">
                                                    {{item.text}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-md-2">
                                        <mat-form-field fxFlex>
                                            <mat-select formControlName="currentMonth" matInput placeholder="Month"
                                                (selectionChange)="onChangeMonth($event)">
                                                <mat-option *ngFor="let item of dsMonth" [value]="item.value">
                                                    {{item.text}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                   
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="chart-wrapper">
                                            <canvas baseChart [datasets]="barChartDataMonth"
                                                [labels]="barChartLabelsMonth" [options]="barChartOptionsMonth"
                                                [plugins]="barChartPluginsMonth" [legend]="barChartLegendMonth"
                                                [colors]="barChartColorsMonth" [chartType]="barChartTypeMonth">

                                            </canvas>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="chart-wrapper">
                                            <canvas baseChart [datasets]="barChartDataYear"
                                                [labels]="barChartLabelsYear" [options]="barChartOptionsYear"
                                                [colors]="barChartColorsYear" [legend]="barChartLegendYear"
                                                [chartType]="barChartTypeYear">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="My Details">
                        <ng-template mat-tab-label>
                            <span (click)="onClickDepartmentPerfomance()" class="material-icons class-menu-list" style="margin:5px;">
                                timeline
                            </span>
                           <span  (click)="onClickDepartmentPerfomance()">Department Technicians Perfomance</span>
                        </ng-template>
                        <mat-card>
                            <mat-card-content>
                                <div class="col-md-12">
                                    <div class="chart-wrapper" *ngIf="dsDepartmentResources.length > 0">                                                                                
                                        <canvas baseChart [datasets]="barChartDataResourceMonth" height="90px"
                                            [labels]="barChartLabelsResourceMonth" [options]="barChartOptionResourceMonth"
                                            [colors]="barChartColorsResourceColour" [legend]="barChartLegendResourceMonth"
                                            [chartType]="barChartTypeResourceMonth">
                                        </canvas>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="My Details">
                        <ng-template mat-tab-label>
                            <span class="material-icons class-menu-list" style="margin:5px;">
                                graphic_eq
                            </span>
                            Department Job Cards
                        </ng-template>
                        <mat-card>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-md-2">
                                        <mat-form-field>
                                            <mat-label>Date From</mat-label>
                                            <input matInput [matDatepicker]="dateFrom" formControlName="dateFrom"
                                                required (dateChange)="onStartDateChange($event)">
                                            <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                                            <mat-datepicker #dateFrom></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-form-field>
                                            <mat-label>Date To</mat-label>
                                            <input matInput [matDatepicker]="dateTO" formControlName="dateTO" required
                                                (dateChange)="onEndDateChange($event)">
                                            <mat-datepicker-toggle matSuffix [for]="dateTO"></mat-datepicker-toggle>
                                            <mat-datepicker #dateTO></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <table mat-table [dataSource]="dsDepartJobcards" matSort
                                            class="mat-elevation-z8">

                                            <ng-container matColumnDef="id">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id-Track No </th>
                                                <td mat-cell *matCellDef="let element"> TR-{{element.id}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="orderNumber">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Number</th>
                                                <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="departmentCode">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept From</th>
                                                <td mat-cell *matCellDef="let element"> {{element.departmentCode}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="departmentToCode">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept TO </th>
                                                <td mat-cell *matCellDef="let element"> {{element.departmentToCode}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="createdByName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                                                <td mat-cell *matCellDef="let element"> {{element.createdByName}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tagName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag Id</th>
                                                <td mat-cell *matCellDef="let element"> {{element.tagName}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="reqStartDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Req StartDate
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.reqStartDate | date : 'dd-MM-yyyy' }} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="reqEndDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Req EndDate </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.reqEndDate | date : 'dd-MM-yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="startDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.startDate | date : 'dd-MM-yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="endDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.endDate | date : 'dd-MM-yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="statusName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                                <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="createdAt">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.createdAt | date : 'dd-MM-yyyy'}} </td>
                                            </ng-container>


                                            <tr mat-header-row *matHeaderRowDef="displayedJobCardColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedJobCardColumns;"
                                                (click)="onEdit(row)"> </tr>

                                        </table>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="My Task Details" *ngIf="hideMyTask">
                        <ng-template mat-tab-label>
                            <span class="material-icons class-menu-list" style="margin:5px;">
                                build
                            </span>
                            My Job Cards
                        </ng-template>
                        <mat-card>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-md-3">
                                        <mat-form-field fxFlex>
                                            <mat-select formControlName="statusId" matInput placeholder="Status"
                                                (selectionChange)="onMyJobCardsStatus($event)">
                                                <mat-option *ngFor="let item of dsMyTaskStatus" [value]="item.id">
                                                    {{item.statusName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <table mat-table [dataSource]="dsMyJobCards" matSort class="mat-elevation-z8">

                                            <ng-container matColumnDef="id">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id-Track No </th>
                                                <td mat-cell *matCellDef="let element"> TR-{{element.id}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="orderNumber">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Number</th>
                                                <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="departmentCode">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept From</th>
                                                <td mat-cell *matCellDef="let element"> {{element.departmentCode}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="departmentToCode">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept TO </th>
                                                <td mat-cell *matCellDef="let element"> {{element.departmentToCode}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="createdByName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                                                <td mat-cell *matCellDef="let element"> {{element.createdByName}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tagName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag Id</th>
                                                <td mat-cell *matCellDef="let element"> {{element.tagName}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="reqStartDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Req StartDate
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.reqStartDate | date : 'dd-MM-yyyy' }} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="reqEndDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Req EndDate </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.reqEndDate | date : 'dd-MM-yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="startDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Start
                                                    Date </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.startDate | date : 'dd-MM-yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="endDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.endDate | date : 'dd-MM-yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="statusName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status
                                                </th>
                                                <td mat-cell *matCellDef="let element"> {{element.statusName}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="createdAt">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created
                                                    At </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.createdAt | date : 'dd-MM-yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="disclaimer">
                                                <td mat-footer-cell *matFooterCellDef colspan="9">
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedJobCardColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedJobCardColumns;"
                                                (click)="onEdit(row)"> </tr>
                                        </table>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </form>
    </mat-card>
</div>