import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDeleteDocumentComponent } from 'src/app/dialogs/confirm-delete-document/confirm-delete-document.component';

@Component({
  selector: 'app-confirm-approve-document-message',
  templateUrl: './confirm-approve-document-message.component.html',
  styleUrls: ['./confirm-approve-document-message.component.css']
})
export class ConfirmApproveDocumentMessageComponent implements OnInit {

  title: string;
  message: string;
  constructor(public dialogRef: MatDialogRef<ConfirmDeleteDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  
}