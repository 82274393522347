<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Bypass Request
</h1>
<mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
</mat-progress-bar>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Request Desc</mat-label>
                    <textarea matInput formControlName="requestDesc" required></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="row" >
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Equipment Tag</mat-label>
                    <input type="text" matInput [formControl]="equipmentTagControl" #autoCompleteInput 
                        [matAutocomplete]="auto" required>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEquipmentFn">
                        <mat-option *ngFor="let option of equipmentTagOptions | async" [value]="option">
                            {{option.instrumentTag}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Interlocks</mat-label>
                    <input matInput formControlName="interLocks" required> 
                    <mat-hint>Please separate interlocks by comma(",") for example 1,2,3</mat-hint>              
                </mat-form-field>
            </div>
        </div>        
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startDate" formControlName="startDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="endDate" formControlName="endDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Please specify process risk</mat-label>
                    <textarea matInput formControlName="processRisk" required></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Please specify engineering risk</mat-label>
                    <textarea matInput formControlName="engineeringRisk" required></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Please specify production risk</mat-label>
                    <textarea matInput formControlName="productionRisk" required></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row" >            
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="stateId" matInput placeholder="Requested State">
                        <mat-option *ngFor="let item of dsTagState" [value]="item.id">
                            {{item.stateName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6"  *ngIf="pageTitle != 'Add'">
                <mat-form-field fxFlex>
                    <mat-select formControlName="statusId" matInput placeholder="Status">
                        <mat-option *ngFor="let item of dsStatus" [value]="item.id" >
                            {{item.statusName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="pageTitle != 'Add' && currentStaus != 'Awaiting'">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="allocatedTo" matInput placeholder="Allocated To">
                        <mat-option *ngFor="let item of dsSystemUserAllocatedTo" [value]="item.id" >
                            {{item.firstName}} {{item.lastName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                
            </div>
        </div>
        <div class="row" *ngIf="pageTitle != 'Add'  && currentStaus != 'Awaiting'">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Final Comment</mat-label>
                    <textarea matInput formControlName="comment"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end" *ngIf="hideButtons" [ngStyle]="{'visibility':loading==false ? 'visible' : 'hidden'}">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>