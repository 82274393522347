import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { DepartmentService } from 'src/services/department.service';
import { JobCardStatusService } from 'src/services/job-card-status.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import * as XLSX from 'xlsx';
import { ExcelServiceService } from 'src/services/excel-service.service';
import { ReportsService } from 'src/services/reports.service';

@Component({
  selector: 'app-report-department-date-range',
  templateUrl: './report-department-date-range.component.html',
  styleUrls: ['./report-department-date-range.component.css']
})
export class ReportDepartmentDateRangeComponent implements OnInit {

  form: FormGroup;
  loading = false;
  dsDepartment: any[];
  dsStatus: any[];
  loggedUserId: number;
  wkStart: Date;
  wkEnd: Date;
  reportName: string;
  finalReportName: string;
  hideDepartment: boolean = false;

  public displayedColumns: string[] = ['id', 'departmentFrom', 'departmentTo', 'createdAt', 'createdBy', 'orderNumber', 'orderNumberDate', 'orderGeneratedBy',
    'requiredStartDate', 'requiredStartTime', 'requiredEndDate', 'requiredEndTime', 'startDate', 'startTime', 'completedOn',
    'jobCardType', 'statusName', 'tag', 'assignedTo'];

  public dsReportDataList = new MatTableDataSource<reportData>();

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private apiExcelServ: ExcelServiceService,
    private apiDepartment: DepartmentService, private apiReportServ: ReportsService, private tokenStorage: TokenstorageService,
    private dialog: MatDialog, private snackBar: MatSnackBar) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
    this.route.queryParams.subscribe(params => {
      this.reportName = params['rptName']
    });

  }

  ngOnInit(): void {
    this.createForm();
    this.loadDepartment();
    var dt = new Date();
    var currentWeekDay = dt.getDay();
    var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
    this.wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
    this.wkEnd = new Date(new Date(this.wkStart).setDate(this.wkStart.getDate() + 6));
    this.form.patchValue(
      {
        dateFrom: this.wkStart,
        dateTO: this.wkEnd
      });
    if (this.reportName == "All_Deparments_Created_Job_Card" || this.reportName == "All_Deparments_Completed_Job_Card") {
      this.hideDepartment = true;
    } else {
      this.hideDepartment = false;
    }
    this.finalReportName = this.reportName.replace(/_/g, " ");
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngAfterViewInit(): void {
    this.dsReportDataList.sort = this.sort;
    this.dsReportDataList.paginator = this.paginator;
  }

  createForm() {
    this.form = this.fb.group({
      dateFrom: [new Date(this.wkStart)],
      dateTO: [new Date(this.wkEnd)],
      department: [null]
    });
  }

  loadDepartment() {
    this.apiDepartment.getAll().subscribe(data => {
      this.dsDepartment = data as [];
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }


  refreshData() {
    debugger;
    this.loading = true;
    if (this.reportName === "All_Deparments_Created_Job_Card") {
      this.apiReportServ.getAllCreatedJobCardByDateRange(this.wkStart, this.wkEnd).subscribe(data => {
        this.dsReportDataList.data = data as reportData[];
        this.loading = false;
      }, error => {
        console.log(console.error);
      });
    } else if (this.reportName === "All_Deparments_Completed_Job_Card") {
      this.apiReportServ.getAllCompletedJobCardByDateRange(this.wkStart, this.wkEnd).subscribe(data => {
        this.dsReportDataList.data = data as reportData[];
        this.loading = false;
      }, error => {
        console.log(console.error);
      });
    } else if (this.reportName === "Deparment_Completed_Job_Card") {
      let departmentId = this.form.controls["department"].value;
      this.apiReportServ.generateDepartmentCompletedJobCards(this.wkStart, this.wkEnd ,departmentId).subscribe(data => {
        this.dsReportDataList.data = data as reportData[];
        this.loading = false;
      }, error => {
        console.log(console.error);
      });

    } else {
      if (this.reportName === "Deparment_Created_Job_Card") {
        let departmentId = this.form.controls["department"].value;
        this.apiReportServ.generateDepartmentCreatedJobCards(this.wkStart, this.wkEnd ,departmentId).subscribe(data => {
          this.dsReportDataList.data = data as reportData[];
          this.loading = false;
        }, error => {
          console.log(console.error);
        });
      }
    }

  }

  applyFilter(event: Event) {

  }

  onStartDateChange(event) {
    let selectedDateFrom = new Date(this.form.value["dateFrom"]);
    this.wkStart = new Date(selectedDateFrom.getFullYear(), selectedDateFrom.getMonth(), selectedDateFrom.getDate(), 23, 59, 0);
  }

  onEndDateChange(event) {
    let selectedDateTo = new Date(this.form.value["dateTO"]);
    this.wkEnd = new Date(selectedDateTo.getFullYear(), selectedDateTo.getMonth(), selectedDateTo.getDate(), 23, 59, 0);
  }

  generateReport() {
    this.refreshData();
  }
  exportTOExcel() {
    var dt = new Date();
    let year = dt.getFullYear();
    let month = (dt.getMonth() + 1).toString().padStart(2, "0");
    let day = dt.getDate().toString().padStart(2, "0");
    let fileName = this.reportName + day + "_" + month + "_" + year;
    this.apiExcelServ.exportAsExcelFile(this.dsReportDataList.data, fileName);
  }

}

export interface reportData {

  id: number;

  departmentFrom: string;

  departmentTo: string;

  createdAt: Date;

  createdBy: string;

  orderNumber: string;

  orderNumberDate: Date;

  orderGeneratedBy: string;

  requiredStartDate: Date;

  requiredStartTime: string;

  requiredEndDate: Date;

  requiredEndTime: string;

  startDate: Date;

  startTime: string;

  endDate : Date;

  endTime : string;

  completedOn: Date;

  jobCardType: string;

  jobCardDescription: string;

  rejectedReason: string;

  otherRejectionReason: string;

  statusName: string;

  tag: string;

  assignedTo: string;

  taskActualStartDate : Date;
	
	taskActualStartTime : string;
	
	taskActualEndDate : Date;
	
	taskActualEndTime : string;
}
