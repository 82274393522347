<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Add Entity
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>        
        <div class="row">
            <div class="col-md-12">
                <mat-form-field fxFlex>
                    <mat-label>Entity Name</mat-label>
                    <input matInput formControlName="systemEntityName" required>               
                </mat-form-field>
            </div>           
        </div>        
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')" [disabled]="!form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>

