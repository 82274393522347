<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Edit Role
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field fxFlex>
                    <mat-label>Role Name</mat-label>
                    <input matInput formControlName="roleName" required>
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="padding-left: 11px;">
            <h1 mat-dialog-title class="Mat-Pop-Up-color-title">
                Menu Access
            </h1>
        </div>
        <div class="row">
           <div class="col-md-12">
            <div class="alert alert-warning">
                <mat-selection-list formControlName ="menuList" #menuList (selectionChange)="onChangeMenu(menuList.selectedOptions.selected)">
                    <mat-list-option *ngFor="let item of dsMenu" [value]="item.id" [selected]="item.selected">
                        {{item.menuName}}
                    </mat-list-option>
                </mat-selection-list> 
            </div>              
            </div>
        </div>
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')"
            [disabled]="form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>
