import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Data } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EquipmentFailureTypeService } from 'src/services/equipment-failure-type.service';

@Component({
  selector: 'app-add-edit-equipment-failure-type',
  templateUrl: './add-edit-equipment-failure-type.component.html',
  styleUrls: ['./add-edit-equipment-failure-type.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AddEditEquipmentFailureTypeComponent implements OnInit {
  pageTitle: string = "";
  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  
  constructor(private fb: FormBuilder, private apiEquipmentFailureTypeServ: EquipmentFailureTypeService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar,
    public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditEquipmentFailureTypeComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    if (this.data) {
      this.pageTitle = "Edit Failure Type";
      this.form.patchValue(
        {
          failureTypeDesc: this.data.failureTypeDesc
        });
    } else {
      this.pageTitle = "Add Failure Type";
    }
  }

  createForm() {
    this.form = this.fb.group({
      "failureTypeDesc": [null, [Validators.required]]
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      var formData = this.form.value; 
      if (this.data) {
        formData.editedBy = this.loggedUserId;
        formData.editedAt = new Date();
        formData.id = this.data.id;
      } else {
        formData.id = 0;
        formData.createdBy = this.loggedUserId;
        formData.createdAt = new Date();
      } 
      this.apiEquipmentFailureTypeServ.create(formData).subscribe(data => {
        this.dialogRef.close({ refresh : true});
      });

    } else {
      if(btnName == "btnCancel"){
        this.dialogRef.close();
      }
    }
  }

}