import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { JobcardTypeService } from 'src/services/jobcard-type.service';
import { AddJobCarTypeComponent } from '../dialogs/add-job-car-type/add-job-car-type.component';
import { EditJobCarTypeComponent } from '../dialogs/edit-job-car-type/edit-job-car-type.component';

@Component({
  selector: 'app-list-jobcard-type',
  templateUrl: './list-jobcard-type.component.html',
  styleUrls: ['./list-jobcard-type.component.css']
})
export class ListJobcardTypeComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'name', 'description', 'createdAt'];

  public dsJobCardType = new MatTableDataSource<jobCardTypeData>();

  constructor(private router: Router, private apiJobCardTypeServ: JobcardTypeService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsJobCardType.sort = this.sort;
    this.dsJobCardType.paginator = this.paginator;
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dsJobCardType.filter = filterValue.trim().toLowerCase();

    if (this.dsJobCardType.paginator) {
      this.dsJobCardType.paginator.firstPage();
    }

  }

  refreshData() {
    this.loading = true;
    this.apiJobCardTypeServ.getAll().subscribe(data => { 
      this.dsJobCardType.data = data as jobCardTypeData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddJobCarTypeComponent, {
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    }); 
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditJobCarTypeComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

}

export interface jobCardTypeData {

  id : number;

	name : string;

	description : string;

	createdBy : number;

	createdAt : Date;

	editedBy : number;

	editedAt : Date;

	isActive : boolean;

}
