import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuService } from 'src/services/menu.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatListOption } from '@angular/material';
import { Data } from '@angular/router';
import { RoleMenuAccessService } from 'src/services/role-menu-access.service';
import { multicast } from 'rxjs/operators';
import { promise } from 'protractor';
import { resolve } from 'url';
import { RoleService } from 'src/services/role.service';


@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit {
  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  dsMenu: any = [];
  dsMenuToAccess : any = [];
  dsRoleMenuAccess : any;

  constructor(private fb: FormBuilder, private apiRoleServ: RoleService, private apiMenuServ: MenuService,
    private apiRoleMenuAccesServ : RoleMenuAccessService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditRoleComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit() {
    this.createForm();
    this.form.patchValue(
    {
      roleName: this.data.roleName
    });
    this.getData();
  }

  createForm() {
    this.form = this.fb.group({
      "roleName": [null, [Validators.required]],
      "menuList" : [null]
    });
  }

  async getData() {   
    const roleMenuAccess = await this.loadRoleMenuAccess(this.data.id) as any[];
    const roleMenu  = await this.loadMenu();
    this.dsRoleMenuAccess = roleMenuAccess;  
    for (var i = 0; i < roleMenu.length; i++) {
      let menuItem = roleMenuAccess.filter(ob => ob.menuId === roleMenu[i].id);      
      this.dsMenu.push(
        {
          "id" : roleMenu[i].id,
          "menuName" : roleMenu[i].menuName,
          "selected" : menuItem.length == 0 ? false : true
        });
    }
  }

  loadMenu() {
    return this.apiMenuServ.getAll().toPromise();    
  }

  loadRoleMenuAccess(roleId : number) {
    return this.apiRoleMenuAccesServ.getByRoleId(roleId).toPromise();
  }

  onChangeMenu(options: MatListOption[]) {
    console.log(options.map(o => o.value));
    this.dsMenuToAccess = options.map(o => o.value)
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
     this.updateMenuRoleInformation();
      
     this.dialogRef.close({ refresh : true});  

    } else {
      this.dialogRef.close();
    }
  }

  async updateMenuRoleInformation() {
    const saveRole = await this.saveRole();
    const deleteMenu = await this.deleteRoleMenuAccess();
    const saveMenu = await this.saveRoleMenuAccess();    
  }

  saveRole() {
    var formData = this.form.value;
    if(formData) {
      var finalFormData = {
        id : this.data.id,
        editedBy : this.loggedUserId,
        editedAt : new Date(),
        roleName : formData.roleName,
        isActive : true  
      }     
      return this.apiRoleServ.create(finalFormData).toPromise();
    }else{
      this.snackBar.open("Error Message","Please contact administator error happened.", {
        duration: 2000,
      });
    }

  }

  deleteRoleMenuAccess() : any {  
    if(this.dsMenuToAccess && this.dsMenuToAccess.length > 0) { 
      this.dsRoleMenuAccess.forEach(element => {   
        debugger;
        return this.apiRoleMenuAccesServ.delete(element.id).toPromise();     
      }); 
    }  
  }

  saveRoleMenuAccess(): any {
    this.dsMenuToAccess.forEach(element => {
      var menuAcess = {
         id : 0,
         createdBy : this.loggedUserId,
         createdAt : new Date(),
         isActive : true,
         systemRoleId : this.data.id,
         systemMenuId : element
      };
      return this.apiRoleMenuAccesServ.create(menuAcess).toPromise();
    });
  }

}
