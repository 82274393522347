import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DepartmentService } from 'src/services/department.service';
import { JobCardService } from 'src/services/job-card.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { JobCardDepartmentScheduleService } from 'src/services/job-card-department-schedule.service';
import { AddEditDepartmentJobCardScheduleComponent } from './add-edit-department-job-card-schedule/add-edit-department-job-card-schedule.component';
import { DateRangeCreateWeeksService } from 'src/services/date-range-create-weeks.service';
import { EditJobCardComponent } from '../dialogs/edit-job-card/edit-job-card.component';
import { Globals } from '../global-values/globals';

@Component({
  selector: 'app-list-department-schedule',
  templateUrl: './list-department-schedule.component.html',
  styleUrls: ['./list-department-schedule.component.css']
})
export class ListDepartmentScheduleComponent implements OnInit {

  form: FormGroup;
  loading = false;
  dsDepartment: any[] = [];
  dsStatus: any[];
  loggedUserId: number;
  loggedUserRoleName : string;
  department: number = -1;
  userAllowedToEditSchedule : boolean = true;
  wkStart: Date;
  wkEnd: Date;
  dsSelectedWeekSchedule: any[] = [];
  constructor(private fb: FormBuilder, private router: Router, private apiJobCardServ: JobCardService, private apiDepartmentServ: DepartmentService,
    private apiDepartmentJobSchedule: JobCardDepartmentScheduleService, private apiJobCardScheduleServ: JobCardDepartmentScheduleService, private tokenStorage: TokenstorageService,
    private apiCreateWeekCalendarServ: DateRangeCreateWeeksService, private globalValues : Globals, private dialog: MatDialog, private snackBar: MatSnackBar) {
    var user = this.tokenStorage.getUser();
    if (user) {
      debugger;
      this.loggedUserId = user.id;
      this.department = user.departmentId;
      this.loggedUserRoleName = user.roleName;
      if(this.loggedUserRoleName == this.globalValues.technicianRoleName) {
        this.userAllowedToEditSchedule = false;
      }

    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.loadDepartment();
    this.createForm();
    var dt = new Date();
    this.form.patchValue(
      {
        department: this.department,
        weekDate: dt
      });
    this.refreshDate(dt);
  }

  createForm() {
    this.form = this.fb.group({
      department: this.department,
      weekDate: new Date()
    });
  }

  refreshDate(selectedDate: Date) {
    const weekDateRange = this.apiCreateWeekCalendarServ.startAndEndOfWeek(selectedDate);
    this.wkStart = new Date(weekDateRange[0]);
    this.wkEnd = new Date(weekDateRange[1]);
    this.refreshData();
  }

  loadDepartment() {
    this.apiDepartmentServ.getAll().subscribe(data => {
      let departList: any = [];
      departList.push({ id: -1, departmentName: "All" });
      for (let o of data) {
        departList.push({ id: o.id, departmentName: o.departmentName });
      }
      this.dsDepartment = departList;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    });
  }

  refreshData() {
    this.loading = true;
    this.apiJobCardScheduleServ.getAllByDepartmentAndFromFromBetweenDates(this.department, this.wkStart, this.wkEnd).subscribe(res => {
      this.dsSelectedWeekSchedule = res as [];
      this.loading = false;
    }, error => {
      this.loading = true;
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    });
  }

  getByDay(dayOfWeek: string) {
    let results = this.dsSelectedWeekSchedule.filter(r => r.dayOfWeek == dayOfWeek);
    return results;
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditDepartmentJobCardScheduleComponent, {
      maxWidth: '97vw',
      maxHeight: '80vh',
      width: '97vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  onEdit(actionType ,jobCardId) { 
    this.apiJobCardServ.getById(jobCardId).subscribe(res => {
      const dialogRef = this.dialog.open(EditJobCardComponent, {
        data: res,
        maxWidth: '97vw',
        maxHeight: '99vh',
        width: '97vw',
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.refreshData();
        }
      });
    },error=>{
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    });   
  }

  actionSchedule(actionType, row) {

  }

  onDepartment(event) {
    this.department = this.form.value["department"];
    this.refreshData();
  }

  onWeekDateDateChange(event) {
    this.refreshDate(event.value);;
  }

}

export interface departmentJobCardScheduleData {

  id: number;

  departmentId: number;

  departmentName: string;

  departmentCode: string;

  trackNumber: string;

  jobCardId: number;

  orderNumber: string

  jobCardType: string;

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  startDate: Date;

  endDate: Date;

  isActive: boolean;

}
