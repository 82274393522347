import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from 'src/services/user.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { SupFunctionalLocationService } from 'src/services/sup-functional-location.service';
import { Data } from '@angular/router';

@Component({
  selector: 'app-edit-sup-functional-location',
  templateUrl: './edit-sup-functional-location.component.html',
  styleUrls: ['./edit-sup-functional-location.component.css']
})
export class EditSupFunctionalLocationComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string; 

  constructor(private fb: FormBuilder, private apiSupFuncLocationServ : SupFunctionalLocationService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<EditSupFunctionalLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
      }
  }

  ngOnInit(): void {
    this.createForm();
    this.form.patchValue(
      {
        locationName: this.data.locationName,
        description: this.data.description  
      });
  }

  createForm() {
    this.form = this.fb.group({
      locationName: [null, [Validators.required]],
      description: [null, [Validators.required]]     
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      let formData = this.form.value;
      let formSupFunctionLocationData = {
        id: this.data.id,
        editedBy : this.loggedUserId,
        editedAt : new Date(),
        locationName: formData.locationName,
        description: formData.description  
      }         
      this.apiSupFuncLocationServ.create(formSupFunctionLocationData).subscribe(data => {        
        this.dialogRef.close();
      });

    } else {
      if (btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}
