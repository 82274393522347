import { Component, OnInit, Inject, DebugNode, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteTrigger } from '@angular/material';
import { JobcardTypeService } from 'src/services/jobcard-type.service';
import { FunctionLocationService } from 'src/services/function-location.service';
import { JobCardService } from 'src/services/job-card.service';
import { Department } from 'src/models/department';
import { CreateUpdateJobCardDTO } from 'src/models/create-update-job-card-dto';
import { HourTimes } from 'src/models/hour-times';
import { ThakaduEquipmentService } from 'src/services/thakadu-equipment.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/services/user.service';
import { SystemUser } from 'src/models/system-user';
import { CreateUpdateJobCardTask } from 'src/models/create-update-job-card-task';
import { Globals } from 'src/app/global-values/globals';
import { JobCardTaskService } from 'src/services/job-card-task.service';
import { SortListPipe } from 'src/app/pipes/sort-list.pipe';
import { CreateUpdateInterLockByPassRequestDTO } from 'src/models/create-update-inter-lock-by-pass-request-dto.model';
import { InterLockService } from 'src/services/inter-lock.service';
import { Console } from 'console';
import { InterLockStateService } from 'src/services/inter-lock-state.service';
import { InterLockByPassStatusService } from 'src/services/inter-lock-by-pass-status.service';

@Component({
  selector: 'app-add-edit-inter-lock',
  templateUrl: './add-edit-inter-lock.component.html',
  styleUrls: ['./add-edit-inter-lock.component.css']
})
export class AddEditInterLockComponent implements OnInit {
  form: FormGroup;
  loading = false;
  loggedUserId: number;
  loggedUserDepartmentId : number;
  loggedUserRoleName : string;
  btnName: string;
  dsJobCardType: any;
  dsEquipment: any[] = [];
  dsDepartment: any;
  dsSystemUserAllocatedTo: SystemUser[];
  dsTagState: any[];
  dsStatus: any[];
  dsAllocatedToDepartmentUser;
  minDate: Date;
  pageTitle: string = "Add";
  nextDepartmentTo: number;
  equipmentTagControl = new FormControl();
  hideButtons: boolean = true;
  currentStaus: string;

  equipmentTagOptions: Observable<equipTagData[]>;

  constructor(private fb: FormBuilder, private apiEquipmentServ: ThakaduEquipmentService, private apiInterLockServ: InterLockService,
    private apiPersonServ: UserService, private apiInterLockStateServ: InterLockStateService, private apiInterLockStatusServ: InterLockByPassStatusService,
    private apiSystemUsersServ: UserService, private tokenStorage: TokenstorageService, private sortList: SortListPipe,
    private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditInterLockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.loggedUserDepartmentId = user.departmentId;  
      this.loggedUserRoleName = user.roleName;
      debugger;
    }
  }

  ngOnInit(): void {
    let date = new Date();
    this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.createForm();
    this.loadEquipmentTag();
    this.loadTagState();
    this.loadInterLockStatus();
    this.equipmentTagOptions = this.equipmentTagControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.instrumentTag),
        map(instrumentTag => instrumentTag ? this._filterEquipmentTag(instrumentTag) : this.dsEquipment.slice())
      );

    if (this.data) {
      this.pageTitle = "Edit";
      this.form.patchValue(
        {
          startDate: new Date(this.data["startDate"]),
          endDate: new Date(this.data["endDate"]),
          bypassReason: this.data["bypassReason"],
          bypassReasonAssociation: this.data["bypassReasonAssociation"],
          //equipmentTagControl: this.data["tagName"], 
          stateId: this.data["stateId"],
          statusId: this.data["statusId"],
          processRisk: this.data["processRisk"],
          engineeringRisk: this.data["engineeringRisk"],
          productionRisk: this.data["productionRisk"],
          comment: this.data["comment"],
          allocatedTo: this.data["allocatedTo"],
          requestDesc : this.data["requestDesc"],
          interLocks : this.data["interLocks"],
        });
      this.form.controls['stateId'].disable();
    }
  }

  loadTagState() {
    this.apiInterLockStateServ.getAll().subscribe(data => {
      this.dsTagState = data as any[];
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadAllocatedToUsers() {
    if (this.nextDepartmentTo) {
      this.apiSystemUsersServ.getAllUserByDepartmentId(this.nextDepartmentTo).subscribe(res => {
        this.dsSystemUserAllocatedTo = res as any[];
      }, error => {
        this.snackBar.open("Error Message", error.message, {
          duration: 2000,
        });
      });
    }
  }

  loadInterLockStatus() {
    this.apiInterLockStatusServ.getAll().subscribe(data => {
      this.dsStatus = data as any[];
      let dsNextStatus: any[] = [];    
      if (this.data) {
        let interLockNextStatus = this.dsStatus.filter(r => r["id"] == this.data["statusId"]);
        if (interLockNextStatus) {
          this.nextDepartmentTo = interLockNextStatus[0].departmentTo;
          this.loadAllocatedToUsers();
          this.currentStaus = interLockNextStatus[0].statusName;
          let nextStatusAllowed = interLockNextStatus[0].statusManagement.split(",") as [];
          nextStatusAllowed.forEach(element => {
            let res = this.dsStatus.filter(r => r.id == element);
            dsNextStatus.push({
              id: res[0].id,
              statusName: res[0].statusName,
              statusManagement: res[0].statusManagement,
              statusRoleManagement: res[0].statusRoleManagement,
              statusTatHrs: res[0].statusTatHrs,
              statusSequence: res[0].statusSequence,
              departmentTo: res[0].departmentTo
            });
          });
          this.dsStatus = dsNextStatus;         
          if (this.dsStatus && this.dsStatus.length == 1) {
            if (this.dsStatus[0].statusManagement.length == 1) {
              this.form.controls['startDate'].disable();
              this.form.controls['endDate'].disable();
              this.form.controls['processRisk'].disable();
              this.form.controls['engineeringRisk'].disable();
              this.form.controls['productionRisk'].disable();
              this.form.controls['stateId'].disable();
              this.form.controls['statusId'].disable();
              this.form.controls['allocatedTo'].disable();
              this.form.controls['comment'].disable();
              this.form.controls['requestDesc'].disable();
              this.form.controls['interLocks'].disable();
              this.hideButtons = false;
            }
          }else{
            if(this.nextDepartmentTo != this.loggedUserDepartmentId) {
              this.form.controls['startDate'].disable();
              this.form.controls['endDate'].disable();
              this.form.controls['processRisk'].disable();
              this.form.controls['engineeringRisk'].disable();
              this.form.controls['productionRisk'].disable();
              this.form.controls['stateId'].disable();
              this.form.controls['statusId'].disable();
              this.form.controls['allocatedTo'].disable();
              this.form.controls['comment'].disable();
              this.form.controls['requestDesc'].disable();
              this.form.controls['interLocks'].disable();
              this.hideButtons = false;
            }else{
              if(this.loggedUserRoleName.toLowerCase() == "basic"){
                this.form.controls['startDate'].disable();
                this.form.controls['endDate'].disable();
                this.form.controls['processRisk'].disable();
                this.form.controls['engineeringRisk'].disable();
                this.form.controls['productionRisk'].disable();
                this.form.controls['requestDesc'].disable();
                this.form.controls['interLocks'].disable();
                this.form.controls['stateId'].disable();               
                this.form.controls['allocatedTo'].disable();
                this.form.controls['comment'].setValidators([Validators.required]);
                this.form.controls['comment'].updateValueAndValidity();
                this.form.setErrors({ 'invalid': true });
              }else{
                this.form.controls['startDate'].disable();
                this.form.controls['endDate'].disable();
                this.form.controls['processRisk'].disable();
                this.form.controls['engineeringRisk'].disable();
                this.form.controls['productionRisk'].disable();
                this.form.controls['requestDesc'].disable();
                this.form.controls['interLocks'].disable();
                this.form.controls['stateId'].disable();       
                this.form.controls['statusId'].setValidators([Validators.required]);
                this.form.controls['statusId'].updateValueAndValidity();
                this.form.setErrors({ 'invalid': true });
              }
            }
          }
        }
      } else {
        this.dsStatus = data as any[];
      }

    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  displayEquipmentFn(equipment: equipTagData): string {
    return equipment && equipment.instrumentTag ? equipment.instrumentTag : '';
  }

  private _filterEquipmentTag(name: string): equipTagData[] {
    const filterValue = name.toLowerCase();
    return this.dsEquipment.filter(option => option.instrumentTag.toLowerCase().indexOf(filterValue) === 0);
  }

  createForm() {
    let defaultStartTime = new Date().toTimeString().slice(0, 2) + ":00";
    var today = new Date();
    today.setHours(today.getHours() + 1);
    let defaultsEndTime = today.toTimeString().slice(0, 2) + ":00";
    this.form = this.fb.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      processRisk: [null, [Validators.required]],
      engineeringRisk: [null, [Validators.required]],
      productionRisk: [null],
      stateId: [null],
      statusId: [null],
      comment: [null],
      allocatedTo: [null],
      requestDesc : [null],
      interLocks : [null]
    });
  }

  loadEquipmentTag() {
    if (this.data) {
      this.equipmentTagControl.setValue({ id: this.data["tagId"], instrumentTag: this.data["tagName"] });
    }
    this.apiEquipmentServ.getEquipmentAutoComplete().subscribe(data => {
      this.dsEquipment = data as equipTagData[];
      return this.dsEquipment;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }
 
  onSubmit(btnName) {
    if (btnName == "btnSave") {
      this.loading = true;
      let message = "Please select a tag.Please make use of selection."
      let equipmentTagControl = this.equipmentTagControl.value != null ? this.equipmentTagControl.value : null;
      if (!equipmentTagControl) {
        this.loading = false;
        this.equipmentTagControl.reset();
        this.snackBar.open("Error Message", message, {
          duration: 2000,
        });
        return;
      }

      if (!equipmentTagControl.instrumentTag) {
        this.equipmentTagControl.reset();
        this.loading = false;
        this.snackBar.open("Error Message", message, {
          duration: 2000,
        });
        return
      }

      if (this.form.valid) {
        this.apiInterLockServ.getByTagId(equipmentTagControl.id).subscribe(resData => {
          this.loading = false;
          let dsTag = resData as any[];  
          if (this.data) {
            let updateInterLock = new CreateUpdateInterLockByPassRequestDTO();
            updateInterLock.id = this.data["id"]
            updateInterLock.createdBy = this.data["createdBy"];
            updateInterLock.createdAt = new Date(this.data["createdAt"]);
            updateInterLock.editedAt = new Date();
            updateInterLock.editedBy = this.loggedUserId;
            updateInterLock.tagId = equipmentTagControl.id;
            updateInterLock.isActive = true;
            updateInterLock.stateId = this.data["stateId"];
            updateInterLock.requestDesc = this.data["requestDesc"];
            updateInterLock.interLocks = this.data["interLocks"];
            updateInterLock.statusDate = new Date();
            updateInterLock.startDate = new Date(this.data["startDate"]);
            updateInterLock.endDate = new Date(this.data["endDate"]);
            updateInterLock.processRisk = this.data["processRisk"]
            updateInterLock.engineeringRisk = this.data["engineeringRisk"]
            updateInterLock.productionRisk = this.data["productionRisk"]
            updateInterLock.statusId = this.form.value.statusId;
            updateInterLock.departmentId = this.data["departmentId"];           
            updateInterLock.approvedBy = this.data["approvedBy"] ? this.data["approvedBy"] : this.loggedUserId;
            updateInterLock.approvedDate = this.data["approvedDate"] ? new Date(this.data["approvedDate"]) : new Date();
            if (this.form.value.statusId == this.data["statusId"]) {
              updateInterLock.departmentIdTo = Number(this.data["departmentIdTo"]);
            } else {
              updateInterLock.departmentIdTo = this.nextDepartmentTo;
            }
            updateInterLock.allocatedTo = this.form.value.allocatedTo ? this.form.value.allocatedTo : this.data["allocatedTo"];
            updateInterLock.comment = this.form.value.comment ? this.form.value.comment : this.data["comment"];
            this.apiInterLockServ.create(updateInterLock).subscribe(res => {
              this.loading = false;
              this.dialogRef.close({ refresh: true });
            }, error => {
              this.snackBar.open("Error Message", error.message, {
                duration: 2000,
              });
            });

          } else {
            let insertInterLock = new CreateUpdateInterLockByPassRequestDTO();
            insertInterLock.id = 0;
            insertInterLock.createdBy = this.loggedUserId;
            insertInterLock.createdAt = new Date();
            insertInterLock.tagId = equipmentTagControl.id;
            insertInterLock.requestDesc = this.form.value.requestDesc;
            insertInterLock.interLocks = this.form.value.interLocks;
            insertInterLock.isActive = true;
            insertInterLock.stateId = this.form.value.stateId;
            insertInterLock.statusDate = new Date();
            insertInterLock.startDate = this.form.value.startDate;
            insertInterLock.endDate = this.form.value.endDate;
            insertInterLock.processRisk = this.form.value.processRisk;
            insertInterLock.engineeringRisk = this.form.value.engineeringRisk;
            insertInterLock.productionRisk = this.form.value.productionRisk;
            insertInterLock.departmentIdTo = 0;
            this.apiInterLockServ.create(insertInterLock).subscribe(res => {
              this.loading = false;
              this.dialogRef.close({ refresh: true });
            }, error => {
              this.snackBar.open("Error Message", error.message, {
                duration: 2000,
              });
            });

          }
        });
      } else {
        if (btnName == "btnCancel") {
          this.loading = false;
          this.dialogRef.close();
        }
      }
    }else{
      this.dialogRef.close({ refresh: true });
    }
  }

  statusOnChanged(event) {
    let interLockNextStatus = this.dsStatus.filter(r => r["id"] == event.value);
    this.nextDepartmentTo = interLockNextStatus[0].departmentTo;
  }

}

export interface equipTagData {
  id: number,
  instrumentTag: string;
}