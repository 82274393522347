import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { EquipmentFailureRootCauseService } from 'src/services/equipment-failure-root-cause.service';
import { AddEditEquipmentFailureRootCauseComponent } from '../dialogs/add-edit-equipment-failure-root-cause/add-edit-equipment-failure-root-cause.component';

@Component({
  selector: 'app-list-equipment-failure-root-cause',
  templateUrl: './list-equipment-failure-root-cause.component.html',
  styleUrls: ['./list-equipment-failure-root-cause.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListEquipmentFailureRootCauseComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'rootCauseDesc', 'createdAt'];

  public dsEquipmentRootCauseFailure = new MatTableDataSource<EquipmentFailureRootCauseData>();

  constructor(private router: Router, private apiEquipmentRootCauseFailureServ: EquipmentFailureRootCauseService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsEquipmentRootCauseFailure.sort = this.sort;
    this.dsEquipmentRootCauseFailure.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEquipmentRootCauseFailure.filter = filterValue.trim().toLowerCase();

    if (this.dsEquipmentRootCauseFailure.paginator) {
      this.dsEquipmentRootCauseFailure.paginator.firstPage();
    }
  }

  refreshData() {
    this.loading = true;
    this.apiEquipmentRootCauseFailureServ.getAll().subscribe(data => {
      this.dsEquipmentRootCauseFailure.data = data as any[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditEquipmentFailureRootCauseComponent, {
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditEquipmentFailureRootCauseComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }


}

export interface EquipmentFailureRootCauseData {

  id: number;

  rootCauseDesc: string;

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

}
