import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
    
    jobCardStatusAwaitingId : number =  1;
    jobCardStatusCompleteId : number = 6;
    jobCardStatusRejectionId : number = 3;
    jobCardStatusInProgressId : number = 5;
    jobCardStatusCloseId : number = 4;
    jobCardStatusApproved : number = 2;

    jobCardTaskStatusAwaitingId : number = 1;
    jobCardTaskStatusInProgresId : number = 2;
    jobCardTaskStatusRejectedId : number = 4;
    jobCardTaskStatusCompletedId : number = 3;

    technicianRoleName : string = "Basic";

    supervisorRoleId : number = 17;
    //Departments
    productionDepartmentId : number = 67;
    //processDepartmentId : number = 271;
    processDepartmentId : number = 21; //Production
    measurementControlDepartmentId : number = 56;
    sheqDepartmentId : number = 1;
    //InterlockStatus;
    interLockStatusWaitingId : number = 1;
    interLockStatusApprovedId : number = 2;
    interLockStatusInprogressId : number = 6;

    //Job Card Types;
    JobCardTypeUnplannedEquipmentFailer = 15;
    JObCardTypeUnplannedBreakDown = 69;
    jobCardTypeSheq = 1;
}