import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, Data } from '@angular/router';
import { JobcardTypeService } from 'src/services/jobcard-type.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-edit-job-car-type',
  templateUrl: './edit-job-car-type.component.html',
  styleUrls: ['./edit-job-car-type.component.css']
})

export class EditJobCarTypeComponent implements OnInit {

  loading: boolean = true;
  form: FormGroup;
  btnName: string;
  dsCompanyType: any;
  loggedUserId: number;

  constructor(private fb: FormBuilder, private router: Router, private apiJobcardTypeServ: JobcardTypeService, private tokenStorage: TokenstorageService, private dialog: MatDialog, private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditJobCarTypeComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.form.patchValue({
      name: this.data.name,
      description: this.data.description
    });
  }

  createForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      description: [null, Validators.required]
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      var formData = this.form.value;
      var finalFormData = {
        description : formData.description,
        name : formData.name,
        id : this.data.id,
        editedBy : this.loggedUserId,
        editedAt : new Date()
      }  
      this.apiJobcardTypeServ.create(finalFormData).subscribe(data =>{
        this.dialogRef.close({ refresh : true});
      })
    } else {
      if(btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }

  }

}

