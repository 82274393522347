import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { EquipmentService } from 'src/services/equipment.service';
import { AddEquipmentComponent } from '../dialogs/add-equipment/add-equipment.component';
import { EditEquipmentComponent } from '../dialogs/edit-equipment/edit-equipment.component';

@Component({
  selector: 'app-list-equipment',
  templateUrl: './list-equipment.component.html',
  styleUrls: ['./list-equipment.component.css']
})
export class ListEquipmentComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'description', 'model', 'serialNumber', 'companyName', 'equipmentTypeDescription' , 'createdAt'];

  public dsEquipment = new MatTableDataSource<equipmentData>();

  constructor(private router: Router, private apiEquipmentServ: EquipmentService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsEquipment.sort = this.sort;
    this.dsEquipment.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEquipment.filter = filterValue.trim().toLowerCase();

    if (this.dsEquipment.paginator) {
      this.dsEquipment.paginator.firstPage();
    }
  }

  refreshData() {
    this.loading = true;
    this.apiEquipmentServ.getAll().subscribe(data => {  
      debugger;   
      this.dsEquipment.data = data as equipmentData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }
  
  onCreate() {
    const dialogRef = this.dialog.open(AddEquipmentComponent, {
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });  
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditEquipmentComponent, {
      data: row,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });
  }

}

export interface equipmentData {

  id : number; 
		 
  model : string;
 
  serialNumber : string;
 
  description : string;	 
    
  createdBy : number;

  createdAt : Date; 

  editedBy : number;

  editedAt : Date;

  isActive : boolean;
  
  companyId : number;
  
  companyName : string;
  
 equipmentTypeId : number;
  
 equipmentTypeDescription : string;

}
