import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { EntityService } from 'src/services/entity.service';
import { AddEntityComponent } from '../dialogs/add-entity/add-entity.component';
import { EditEntityComponent } from '../dialogs/edit-entity/edit-entity.component';

@Component({
  selector: 'app-list-entity',
  templateUrl: './list-entity.component.html',
  styleUrls: ['./list-entity.component.css']
})
export class ListEntityComponent implements OnInit {

  public displayedColumns: string[] = ['id', 'systemEntityName', 'createdAt'];

  public dsEntity = new MatTableDataSource<entitydata>();

  constructor(private router: Router, private apiEnityServ: EntityService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsEntity.sort = this.sort;
    this.dsEntity.paginator = this.paginator;
  }

  refreshData() {
    this.apiEnityServ.getAll().subscribe(data => {
      this.dsEntity.data = data as entitydata[];
    }, error => {
      if (error.status == 404) {
        console.log("No Record Found");
      } else {
        console.log(error);
      }
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEntityComponent, {
      data: null,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEntity.filter = filterValue.trim().toLowerCase();

    if (this.dsEntity.paginator) {
      this.dsEntity.paginator.firstPage();
    }
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditEntityComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }
}

export interface entitydata {
 id : number;
 systemEntityName : string;
 individual : boolean,
 titleName : string; 
 createdBy : number;
 createdAt : Date;
 editedBy: number;
 editedAt : Date;
 isActive :boolean; 
}
