import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { RoutineMaintenanceTagPlanService } from 'src/services/routine-maintenance-tag-plan.service';
import { AddEditRoutineMaintenanceTagPlanComponent } from '../dialogs/add-edit-routine-maintenance-tag-plan/add-edit-routine-maintenance-tag-plan.component';

@Component({
  selector: 'app-list-routine-maintenance-tag-plan',
  templateUrl: './list-routine-maintenance-tag-plan.component.html',
  styleUrls: ['./list-routine-maintenance-tag-plan.component.css']
})
export class ListRoutineMaintenanceTagPlanComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'routineMaintenanceTypeName', 'departmentName', 'jobCardTypeDesc', 'tagName', 'startDate', 'frequencyDays', 'frequecyRepeat', 'createdAt'];

  public dsRoutineMaintenance = new MatTableDataSource<routineMaintenanceTypeData>();

  constructor(private apiRoutineMaintenanceServ : RoutineMaintenanceTagPlanService, private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.refreshData();
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngAfterViewInit(): void {
    this.dsRoutineMaintenance.sort = this.sort;
    this.dsRoutineMaintenance.paginator = this.paginator;
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dsRoutineMaintenance.filter = filterValue.trim().toLowerCase();

    if (this.dsRoutineMaintenance.paginator) {
      this.dsRoutineMaintenance.paginator.firstPage();
    }
  }  

  refreshData() {
    this.loading = true;
    this.apiRoutineMaintenanceServ.getAll().subscribe(data => { 
      this.dsRoutineMaintenance.data = data as routineMaintenanceTypeData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }
  onCreate() {
    const dialogRef = this.dialog.open(AddEditRoutineMaintenanceTagPlanComponent, {
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    }); 
    dialogRef.afterClosed().subscribe(result => {    
      if (result) {
        this.refreshData();
      }
    }); 
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditRoutineMaintenanceTagPlanComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });
    dialogRef.afterClosed().subscribe(result => {      
      if (result) {
        this.refreshData();
      }
    });
  }
}

  export interface routineMaintenanceTypeData {

    id : number;
  
    routineMaintenanceTypeId : number,

    routineMaintenanceTypeName : String;
  
    jobCardTypeId : number;

    jobCardTypeDesc : String;

    tagId : number;

    tagName : String;

    startDate : Date,

    frequencyDays : number;

    frequecyRepeat : number;
  
    createdBy : number;
  
    createdAt : Date;
  
    editedBy : number;
  
    editedAt : Date;
    
    departmentId : number;

    departmentName : String;
  
    isActive : boolean;

    instruction : String;
  
  }
