import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobCardDepartmentResourceStatsService {

  API_URL: string = environment.serverUrl + "/jobCardsStatsResourceAllocation";

  constructor(private http : HttpClient) { }

  getJobCardStatsByDepartment(department : number, month : number ,year : number) {  
    debugger;
    return this
      .http
      .get(`${this.API_URL}/getJobCardStatsByDepartment/${department}/${month}/${year}`);    
  }

  getJobCardStatsByResource(resourceId : number, month : number ,year : number) {  
    debugger; 
    return this
      .http
      .get(`${this.API_URL}/getJobCardStatsByResource/${resourceId}/${month}/${year}`);    
  }

}
