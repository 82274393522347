<div class="container-fluid" style="margin-top: 10px;">
    <mat-card>
        <form fxLayout="column" [formGroup]="form">
            <mat-card-header>
                <mat-card-title>List Of InterLocks Bypass</mat-card-title><span class="example-spacer"></span>
                <button mat-raised-button color="primary" (click)="refreshData()">Refresh</button><div class="spaceBetweenButtons"></div><button mat-raised-button color="primary" (click)="onCreate()">Add</button>
            </mat-card-header>
            <br>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Enter Search">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="statusId" matInput placeholder="Status"
                                (selectionChange)="onStatus($event)">
                                <mat-option *ngFor="let item of dsStatus" [value]="item.id">
                                    {{item.statusName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table mat-table [dataSource]="dsInterlock" matSort class="mat-elevation-z8">
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="departmentName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept From </th>
                                <td mat-cell *matCellDef="let element"> {{element.departmentName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="createdName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                                <td mat-cell *matCellDef="let element"> {{element.createdName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="departmentToName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept To </th>
                                <td mat-cell *matCellDef="let element"> {{element.departmentToName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="tagName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.tagName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.startDate  | date : 'dd-MM-yyyy'}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.endDate  | date : 'dd-MM-yyyy'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="statusName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="stateName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Requested State </th>
                                <td mat-cell *matCellDef="let element"> {{element.stateName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Create Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'dd-MM-yyyy'}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEdit(row)"></tr>
                        </table>
                    </div>
                </div>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40, 50, 60 ,70, 80 ,90 ,100]">
                </mat-paginator>
            </mat-card-content>
        </form>
    </mat-card>
</div>