import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ThakaduEquipmentService } from 'src/services/thakadu-equipment.service';
import { AddEditThakaduEquipmentComponent } from '../dialogs/add-edit-thakadu-equipment/add-edit-thakadu-equipment.component';

@Component({
  selector: 'app-list-thakadu-equipment',
  templateUrl: './list-thakadu-equipment.component.html',
  styleUrls: ['./list-thakadu-equipment.component.css']
})
export class ListThakaduEquipmentComponent implements OnInit {

  public displayedColumns: string[] = ['id', 'areaCode', 'instrumentCode','sequenceEquipNo','sequenceInstrumentNo', 'instrumentTag', 'equipmentCategoryDesc' ,'rioPanelNo', 'createdAt'];

  public dsEquipment = new MatTableDataSource<equipmentThakaduData>();

  constructor(private router: Router, private apiEquipmentServ: ThakaduEquipmentService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsEquipment.sort = this.sort;
    this.dsEquipment.paginator = this.paginator;
  }

  refreshData() {
    this.apiEquipmentServ.getAll().subscribe(data => {
      this.dsEquipment.data = data as equipmentThakaduData[];
    }, error => {
      if (error.status == 404) {
        console.log("No Record Found");
      } else {
        console.log(error);
      }
    });
  }
  
  onCreate() {
    const dialogRef = this.dialog.open(AddEditThakaduEquipmentComponent, {
      data: null,
      maxWidth: '85vw',
      maxHeight: '85vh',
      width: '85vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEquipment.filter = filterValue.trim().toLowerCase();

    if (this.dsEquipment.paginator) {
      this.dsEquipment.paginator.firstPage();
    }
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditThakaduEquipmentComponent, {
      data: row,
      maxWidth: '85vw',
      maxHeight: '85vh',
      width: '85vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
    
  }

}

export interface equipmentThakaduData {

  id : number;

  createdBy : number;

  createdAt : Date;

  editedBy : number;

  editedAt : Date;

  isActive :boolean; 

  areaCode : string;

  instrumentCode : string;

  sequenceEquipNo : string;

  sequenceInstrumentNo : string;

  instrumentTag : string;

  rioPanelNo : string;

  equipmentCategoryDesc : string;

 }
