import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobCardTaskActionService {

  API_URL: string = environment.serverUrl + "/jobCardTaskAction";

  constructor(private http : HttpClient) { }

  getAll(): Observable<any> {
    return this
      .http
      .get(`${this.API_URL}`);
  }

  getById(id : number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  } 

  create(debtor: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, debtor);
  }

}
