import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { JobCardService } from 'src/services/job-card.service';
import { AddJobCardComponent } from '../dialogs/add-job-card/add-job-card.component';
import { EditJobCardComponent } from '../dialogs/edit-job-card/edit-job-card.component';
import { JobcardTypeService } from 'src/services/jobcard-type.service';
import { JobCardTaskService } from 'src/services/job-card-task.service';
import { logging } from 'protractor';
import { DepartmentService } from 'src/services/department.service';
import { JobCardStatusService } from 'src/services/job-card-status.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';

@Component({
  selector: 'app-list-job-cards',
  templateUrl: './list-job-cards.component.html',
  styleUrls: ['./list-job-cards.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListJobCardsComponent implements OnInit {

  form: FormGroup;
  loading = false;
  dsDepartment : any[];
  dsStatus : any[];
  loggedUserId : number;
  departmentTo : number  = -1;
  departmentFrom : number = -1; 
  statusId : number = -1;
  wkStart : Date; 
  wkEnd : Date;

  public displayedColumns: string[] = ['id', 'createdAt', 'createdByName', 'jobCardTypeDesc', 'departmentCode', 'departmentToCode', 'tagName','reqStartDate', 'reqStartTime', 'reqEndDate', 'reqEndTime', 'orderNumber', 'completedOn', 'statusName'];

  public dsJobCards = new MatTableDataSource<jobCardData>();

  constructor(private fb: FormBuilder ,private router: Router, private apiJobCardServ: JobCardService, private apiJobCardtaskServ: JobCardTaskService, private apiDepartmentServ : DepartmentService, private apiStatusServ : JobCardStatusService, 
    private tokenStorage: TokenstorageService,private dialog: MatDialog, private snackBar: MatSnackBar) { 
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.departmentTo = user.departmentId;
    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {    
    this.loadDepartment();
    this.loadJobCardStatus();
    this.createForm();
    var dt = new Date();
    var currentWeekDay = dt.getDay();
    var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
    this.wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
    this.wkEnd = new Date(new Date(this.wkStart).setDate(this.wkStart.getDate() + 6));
    this.form.patchValue(
    {
      departmentTo: this.departmentTo,  
      departmentFrom : this.departmentFrom,
      dateFrom: this.wkStart,
      dateTO: this.wkEnd, 
      statusId : this.statusId    
    });
    this.loadJobCardByDepartmentAndStatus();
  }

  createForm() {    
    this.form = this.fb.group({
      departmentTo : this.departmentTo,
      departmentFrom : this.departmentFrom,
      dateFrom: [new Date(this.wkStart)],
      dateTO: [new Date(this.wkEnd)],
      statusId :  this.statusId
    });    
  }

  refreshData(){
    this.loadJobCardByDepartmentAndStatus();
  }

  ngAfterViewInit(): void {
    this.dsJobCards.sort = this.sort;
    this.dsJobCards.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsJobCards.filter = filterValue.trim().toLowerCase();

    if (this.dsJobCards.paginator) {
      this.dsJobCards.paginator.firstPage();
    }
  } 

  loadDepartment() {  
    this.apiDepartmentServ.getAll().subscribe(data => {
      let departList: any = [];
      departList.push({id : -1 , departmentName : "All" });
      for(let o of data) {
        departList.push({id : o.id , departmentName : o.departmentName });
      }
      this.dsDepartment = departList;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    });
  }

  loadJobCardStatus() {
    let ds : any[] = [];
    this.apiStatusServ.getAll().subscribe(data => {
      this.dsStatus = data as [];
      ds.length = 0;
      ds.push({id : -1 ,statusName : "All"});
      for(let i = 0 ; i < this.dsStatus.length ; i++){
        ds.push({id : this.dsStatus[i].id ,statusName : this.dsStatus[i].statusName});
      }
      this.dsStatus = ds;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    });
  }  

  onCreate() {
    const dialogRef = this.dialog.open(AddJobCardComponent, {
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadJobCardByDepartmentAndStatus();
      }
    });
  }

  loadJobCardByDepartmentAndStatus() {    
    this.loading = true;    
    this.apiJobCardServ.getByFilterOptions(this.departmentFrom, this.departmentTo ,this.wkStart , this.wkEnd).subscribe(data => {     
      if(this.statusId > 0) {      
        let dsJoCardFilter = data as jobCardData[]; 
        this.dsJobCards.data = dsJoCardFilter.filter(r => r.statusId == this.statusId)
      } else{
        this.dsJobCards.data = data as jobCardData[]; 
      }
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onStartDateChange(event) {
    let selectedDateFrom = new Date(this.form.value["dateFrom"]);
    this.wkStart = new Date(selectedDateFrom.getFullYear(), selectedDateFrom.getMonth(), selectedDateFrom.getDate(), 23, 59, 0);
    this.loadJobCardByDepartmentAndStatus();
  }

  onEndDateChange(event) {
    let selectedDateTo = new Date(this.form.value["dateTO"]);
    this.wkEnd = new Date(selectedDateTo.getFullYear(), selectedDateTo.getMonth(), selectedDateTo.getDate(), 23, 59, 0);
    this.loadJobCardByDepartmentAndStatus();
  }


  onDepartmentFrom(event) {
    this.departmentFrom = this.form.value["departmentFrom"];
    this.loadJobCardByDepartmentAndStatus(); 
  }
  onDepartmentTO(event) {
    this.departmentTo = this.form.value["departmentTo"];
    this.loadJobCardByDepartmentAndStatus(); 
  }

  onStatus(event) {   
    let statusId = this.form.value["statusId"];
    this.statusId =  statusId;
    this.loadJobCardByDepartmentAndStatus(); 
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditJobCardComponent, {
      data: row,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });

    dialogRef.afterClosed().subscribe(result => {     
      if (result) {
        this.loadJobCardByDepartmentAndStatus();    
      }
    });
  }
}


export interface jobCardData {

  id: number;

  departmentId: number;

  departmentName: string;

  departmentCode : string;

  departmentIdTo: number;

  departnameTO: string;

  departmentToCode : string;

  statusId: number;

  statusName: string;

  startTime: Date;

  endTime: Date;

  completedOn: Date;

  description: string;

  createdBy: number;

  createdByName : String;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  isActive: boolean;

  jobCardTypeDesc: string;

  jobCardTypeId: number;

  isApproved: boolean;

  tagId: number;

  tagName: string;
  
  slaPercentage: number;

  orderNumber: string;

}
