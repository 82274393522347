export class CreateUpdateDepartmentDocumentDTO {

    id : number;
	
	createdBy : number;
	
	createdAt : Date;
	
	editedBy : number;
	
	editedAt : Date;
	
	documentTypeId : number;
	
	visibleToDepartment : string;
	
	awsImageRef : string;
}
