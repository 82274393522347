import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { EquipmentCategoryService } from 'src/services/equipment-category.service';
import { AddEditEquipmentCategoryComponent } from '../dialogs/add-edit-equipment-category/add-edit-equipment-category.component';

@Component({
  selector: 'app-list-equipment-category',
  templateUrl: './list-equipment-category.component.html',
  styleUrls: ['./list-equipment-category.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListEquipmentCategoryComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'equipmentCategoryDesc','createdAt'];

  public dsEquipmentCategory = new MatTableDataSource<EquipmentCategoryData>();

  constructor(private router: Router, private apiEquipmentCategoryServ: EquipmentCategoryService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsEquipmentCategory.sort = this.sort;
    this.dsEquipmentCategory.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEquipmentCategory.filter = filterValue.trim().toLowerCase();

    if (this.dsEquipmentCategory.paginator) {
      this.dsEquipmentCategory.paginator.firstPage();
    }
  }

  refreshData() {
    this.loading = true;
    this.apiEquipmentCategoryServ.getAll().subscribe(data => {  
      this.dsEquipmentCategory.data = data as EquipmentCategoryData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditEquipmentCategoryComponent, {
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if (result) { 
        this.refreshData();
      }
    }); 
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditEquipmentCategoryComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {     
        this.refreshData();
      }
    });
  }

}

export interface EquipmentCategoryData {

  id : number;

  equipmentCategoryDesc : string;

  createdBy : number;
	
	createdAt : Date;
	
	editedBy : number;
	
	editedAt : Date;

}
