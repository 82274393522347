<div class="container-fluid" style="margin-top: 10px;">
    <form fxLayout="column" [formGroup]="form">
        <mat-card>
            <mat-card-header>
                <mat-card-title>List Of Department Documents</mat-card-title>
            </mat-card-header>
            <mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
            </mat-progress-bar>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="department" matInput placeholder="Department"
                                (selectionChange)="onClickDepartment($event)">
                                <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                                    {{item.departmentCode}}  {{item.departmentName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Enter Search">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <br>
                        <table mat-table [dataSource]="dsDocument" matSort class="mat-elevation-z8">
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="documentTypeName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Document Type Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.documentTypeName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="documentName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Document Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.documentName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="departmentCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Department Code </th>
                                <td mat-cell *matCellDef="let element"> {{element.departmentCode}} </td>
                            </ng-container>

                            <ng-container matColumnDef="departmentName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Department Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.departmentName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Create Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'dd-MM-yyyy'}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                <td mat-cell *matCellDef="let element" class="action-link">
                                    <button mat-button color="success" (click)="actionDocument('download',element)">
                                        Download </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    </form>
</div>