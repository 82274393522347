<div class="container-fluid" style="margin-top: 10px;">
    <mat-card>
        <mat-card-header>
            <mat-card-title>List Of Company</mat-card-title><span class="example-spacer"></span>
            <button mat-raised-button color="primary" (click)="refreshData()">Refresh</button> <div class="spaceBetweenButtons"></div><button mat-raised-button color="primary" (click)="onCreate()">Add</button>
        </mat-card-header>
        <br>
        <mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}"></mat-progress-bar>
        <mat-card-content>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Enter Search">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table mat-table [dataSource]="dsCompany" matSort class="mat-elevation-z8">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="companyName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Description </th>
                            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                        </ng-container>

                        <ng-container matColumnDef="companyTypeDecription">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.companyTypeDecription}} </td>
                        </ng-container>

                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Create Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEdit(row)"></tr>
                    </table>
                </div>
            </div>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40, 50, 60 ,70, 80 ,90 ,100]">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>


