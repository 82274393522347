import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { UserService } from 'src/services/user.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { SupFunctionalLocationService } from 'src/services/sup-functional-location.service';

@Component({
  selector: 'app-add-sup-functional-location',
  templateUrl: './add-sup-functional-location.component.html',
  styleUrls: ['./add-sup-functional-location.component.css']
})
export class AddSupFunctionalLocationComponent implements OnInit {
  
  form: FormGroup;
  loggedUserId: number;
  btnName: string; 

  constructor(private fb: FormBuilder, private apiSupFuncLocationServ : SupFunctionalLocationService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddSupFunctionalLocationComponent>) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
      }
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      locationName: [null, [Validators.required]],
      description: [null, [Validators.required]]     
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      var formData = this.form.value;
      formData.id = 0;
      formData.createdBy = this.loggedUserId;
      formData.createdAt = new Date();      
      this.apiSupFuncLocationServ.create(formData).subscribe(data => {        
        this.dialogRef.close();
      });

    } else {
      if (btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}
