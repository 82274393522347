import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CompanyService } from 'src/services/company.service';
import { AddCompanyComponent } from '../dialogs/add-company/add-company.component';
import { EditCompanyComponent } from '../dialogs/edit-company/edit-company.component';

@Component({
  selector: 'app-list-company',
  templateUrl: './list-company.component.html',
  styleUrls: ['./list-company.component.css']
})
export class ListCompanyComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id','companyName', 'description', 'companyTypeDecription', 'createdAt'];

  public dsCompany = new MatTableDataSource<companyData>();

  constructor(private router: Router, private apiCompanyServ: CompanyService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsCompany.sort = this.sort;
    this.dsCompany.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsCompany.filter = filterValue.trim().toLowerCase();

    if (this.dsCompany.paginator) {
      this.dsCompany.paginator.firstPage();
    }
  }

  refreshData() {
    this.loading = true;
    this.apiCompanyServ.getAll().subscribe(data => {
      this.dsCompany.data = data as companyData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddCompanyComponent, {
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });    
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditCompanyComponent, {
      data: row,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });
  }


}

export interface companyData {

  id : number;
	
	companyName : string;
	
	description : string;
	
	createdBy : number;
	
	createdAt : Date;
	
	editedBy : number;
	
	editedAt : Date;
	
	isActive : boolean;
	
	companyTypeId : number;
	
	companyTypeDecription : string;

}
