import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CompanyTypeService } from 'src/services/company-type.service';
import { Data } from '@angular/router';

@Component({
  selector: 'app-add-editcompany-type',
  templateUrl: './add-editcompany-type.component.html',
  styleUrls: ['./add-editcompany-type.component.css']
})
export class AddEditcompanyTypeComponent implements OnInit {

  pageTitle: string = "";
  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  dsRoles: any;

  constructor(private fb: FormBuilder, private apiCompanyTypeServ: CompanyTypeService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditcompanyTypeComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    if (this.data) {
      this.pageTitle = "Edit Company Type";
      this.form.patchValue(
        {
          description: this.data.description
        });
    } else {
      this.pageTitle = "Add Company Type";
    }
  }

  createForm() {
    this.form = this.fb.group({
      "description": [null, [Validators.required]]
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {

      var formData = this.form.value; 
      if (this.data) {
        formData.editedBy = this.loggedUserId;
        formData.editedAt = new Date();
        formData.id = this.data.id;
      } else {
        formData.id = 0;
        formData.createdBy = this.loggedUserId;
        formData.createdAt = new Date();
      }
      this.apiCompanyTypeServ.create(formData).subscribe(data => {
        this.dialogRef.close();
      });

    } else {
      this.dialogRef.close();
    }
  }

}
