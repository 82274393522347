<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Edit Job Card Task
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="alert alert-warning" role="alert" *ngIf="data.jobCardStatus == 'Awaiting'">
            Please note for a task to be actioned jobcard must be approved by the supervisor
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="departmentId" matInput placeholder="Department">
                        <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="assigneeId" matInput placeholder="Person">
                        <mat-option *ngFor="let item of dsPerson" [value]="item.id">
                            {{item.firstName}} {{item.lastName}} - {{item.employeeNumber}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startDate" formControlName="startDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="startTime" matInput placeholder="Start Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="endDate" formControlName="endDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>

            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="endTime" matInput placeholder="End Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>              
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="description" required></textarea>
                  </mat-form-field>
            </div>
        </div> 
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Actual Start Date</mat-label>
                    <input matInput [matDatepicker]="actualStartDate" formControlName="actualStartDate">
                    <mat-datepicker-toggle matSuffix [for]="actualStartDate"></mat-datepicker-toggle>
                    <mat-datepicker #actualStartDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="actualStartTime" matInput placeholder="Atcual Start Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Actual End Time</mat-label>
                    <input matInput [matDatepicker]="actualEndDate" formControlName="actualEndDate">
                    <mat-datepicker-toggle matSuffix [for]="actualEndDate"></mat-datepicker-toggle>
                    <mat-datepicker #actualEndDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="actualEndTime" matInput placeholder="Actual End Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">          
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="repairType" multiple  matInput placeholder="Repair Type" required>
                        <mat-option *ngFor="let item of dsRepairType" [value]="item.equipmentRepairTypeId">
                            {{item.equipmentRepairTypeDesc}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="rootCause" multiple  matInput placeholder="Root Cause" required>
                        <mat-option *ngFor="let item of dsRootCause" [value]="item.equipmentFailureRootCauseId">
                            {{item.equipmentFailureRootCauseDesc}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>   
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="failureType" multiple  matInput placeholder="Failure Type" required>
                        <mat-option *ngFor="let item of dsFailureType" [value]="item.equipmentFailureTypeId">
                            {{item.equipmentFailureTypeDesc}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>  
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="component" multiple  matInput placeholder="Component" required>
                        <mat-option *ngFor="let item of dsComponent" [value]="item.equipmentComponentId">
                            {{item.equipmentComponentDesc}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
        </div>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="jobCardTaskActionId" matInput placeholder="Job Action Type">
                        <mat-option *ngFor="let item of dsJobCardTaskAction" [value]="item.id">
                            {{item.jobTaskActionDesc}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="statusId" matInput placeholder="Status" (selectionChange)="onChangeStatus($event)">
                        <mat-option *ngFor="let item of dsStatus" [value]="item.id">
                            {{item.statusName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>                    
             </div> 
             <div class="col-md-3" *ngIf="data.statusId == 3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="delayReasonId" matInput placeholder="Status/Delay Reason" (selectionChange)="onChangeReason($event)">
                        <mat-option *ngFor="let item of dsJobCardDelayReason" [value]="item.id">
                            {{item.delayReason}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
            <div class="col-md-3" *ngIf="data.delayReasonId == 4">
                <mat-form-field fxFlex>
                    <mat-label>Other Reason</mat-label>
                    <input matInput type="text" formControlName="otherReason">
                </mat-form-field>
            </div>
        </div>     
        <div class="row">      
            <div class="col-md-12" *ngIf="data.statusId == 3">
                <mat-form-field>
                    <mat-label>Action Comment</mat-label>
                    <textarea matInput formControlName="finalComment"></textarea>
                  </mat-form-field>
            </div>
        </div>      
    </div>
    <span class="spacer"></span>   
    <div mat-dialog-actions align="end" *ngIf="allowTaskAction && (dsStatus.length != 1 && data.departmentId == loggedUserDepartmentId)">        
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')"
            [disabled]="!form.invalid">Cancel</button>
            <button *ngIf="!checkIfTaskAssigned" mat-raised-button color="primary" (click)="onSubmit('btnAssignTechnician')">Assign Technician</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
    <div mat-dialog-actions align="end" *ngIf="allowSpecialApproval">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')"
            [disabled]="!form.invalid">Cancel</button>
            <button *ngIf="!checkIfTaskAssigned" mat-raised-button color="primary" (click)="onSubmit('btnAssignTechnician')">Assign Technician</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>