<div class="container-fluid" style="margin-top: 10px;">
    <form fxLayout="column" [formGroup]="form">
        <mat-card>
            <mat-card-header>
                <mat-card-title>List Weekly Job Cards Scheduled</mat-card-title><span
                    class="example-spacer"></span>
                <button mat-raised-button color="primary" (click)="refreshData()">Refresh</button>
                <div class="spaceBetweenButtons"></div><button *ngIf="userAllowedToEditSchedule" [ngStyle]="{'visibility':loading==false ? 'visible' : 'hidden'}" mat-raised-button color="primary"
                    (click)="onCreate()">Add</button>                    
            </mat-card-header>
            <br>
            <mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
            </mat-progress-bar>
            <mat-card-content>
                <div class="row">

                    <div class="col-md-2">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="department" matInput placeholder="Department"
                                (selectionChange)="onDepartment($event)">
                                <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                                    {{item.departmentName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-label>Week Date</mat-label>
                            <input matInput [matDatepicker]="weekDate" formControlName="weekDate" required
                                (dateChange)="onWeekDateDateChange($event)">
                            <mat-datepicker-toggle matSuffix [for]="weekDate"></mat-datepicker-toggle>
                            <mat-datepicker #weekDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-5">
                        <span class="mat-caption">&nbsp;</span><br>
                        <span class="mat-body"> <span style="font-weight: 500;"> Start Week : </span> {{wkStart |
                            date: 'dd-MM-yyyy'}} - <span style="font-weight: 500;"> End Week : </span> {{wkEnd | date:
                            'dd-MM-yyyy'}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-striped">
                            
                            <thead>
                                <tr>
                                    <th>Sun</th>
                                    <th>Mon</th>
                                    <th>Tues</th>
                                    <th>Wed</th>
                                    <th>Thurs</th>
                                    <th>Fri</th>
                                    <th>Sat</th>
                                </tr>
                            </thead>
                            <tr>
                                <td>
                                     <span *ngFor="let item of getByDay('Sunday'); let i = index">
                                        <div class="card" style="margin-bottom: 10px; background-color: whitesmoke;">
                                            <div class="card-body">
                                                <p style="font-weight: 500;">{{item.trackNumber}} -{{item.departmentCode}} - {{item.jobCardType}} </p>
                                                <p style="font-size: x-small;">{{item.jobCardStatus}}</p>
                                                <p style="font-size: x-small;">{{item.allocatedTo}}</p>
                                                <p style="font-size: x-small;">{{item.orderNumber}}</p>
                                                <button mat-raised-button class="mat-success" (click)="onEdit('edit',item.jobCardId)">
                                                    View</button>
                                            </div>
                                        </div>
                                    </span>
                                </td>
                                <td>
                                    <span *ngFor="let item of getByDay('Monday'); let i = index">
                                        <div class="card" style="margin-bottom: 10px; background-color: whitesmoke;">
                                            <div class="card-body">
                                                <p style="font-weight: 500;">{{item.trackNumber}} -{{item.departmentCode}} - {{item.jobCardType}} </p>
                                                <p style="font-size: x-small;">{{item.jobCardStatus}}</p>
                                                <p style="font-size: x-small;">{{item.allocatedTo}}</p>
                                                <p style="font-size: x-small;">{{item.orderNumber}}</p>
                                                <button mat-raised-button class="mat-success" (click)="onEdit('edit',item.jobCardId)">
                                                    View</button>
                                            </div>
                                        </div>              
                                    </span>
                                </td>
                                <td>
                                    <span *ngFor="let item of getByDay('Tuesday'); let i = index">
                                        <div class="card" style="margin-bottom: 10px; background-color: whitesmoke;">
                                            <div class="card-body">
                                                <p style="font-weight: 500;">{{item.trackNumber}} -{{item.departmentCode}} - {{item.jobCardType}} </p>
                                                <p style="font-size: x-small;">{{item.jobCardStatus}}</p>
                                                <p style="font-size: x-small;">{{item.allocatedTo}}</p>
                                                <p style="font-size: x-small;">{{item.orderNumber}}</p>
                                                <button mat-raised-button class="mat-success" (click)="onEdit('edit',item.jobCardId)">
                                                    View</button>
                                            </div>
                                        </div>
                                    </span>
                                </td>
                                <td>
                                    <span *ngFor="let item of getByDay('Wednesday'); let i = index">
                                        <div class="card" style="margin-bottom: 10px; background-color: whitesmoke;">
                                            <div class="card-body">
                                                <p style="font-weight: 500;">{{item.trackNumber}} -{{item.departmentCode}} - {{item.jobCardType}} </p>
                                                <p style="font-size: x-small;">{{item.jobCardStatus}}</p>
                                                <p style="font-size: x-small;">{{item.allocatedTo}}</p>
                                                <p style="font-size: x-small;">{{item.orderNumber}}</p>
                                                <button mat-raised-button class="mat-success" (click)="onEdit('edit',item.jobCardId)">
                                                    View</button>
                                            </div>
                                        </div>
                                    </span>
                                </td>
                                <td>
                                    <span *ngFor="let item of getByDay('Thursday'); let i = index">
                                        <div class="card" style="margin-bottom: 10px; background-color: whitesmoke;">
                                            <div class="card-body">
                                                <p style="font-weight: 500;">{{item.trackNumber}} -{{item.departmentCode}} - {{item.jobCardType}} </p>
                                                <p style="font-size: x-small;">{{item.jobCardStatus}}</p>
                                                <p style="font-size: x-small;">{{item.allocatedTo}}</p>
                                                <p style="font-size: x-small;">{{item.orderNumber}}</p>
                                                <button mat-raised-button class="mat-success" (click)="onEdit('edit',item.jobCardId)">
                                                    View</button>
                                            </div>
                                        </div>
                                    </span>
                                </td>
                                <td>
                                    <span *ngFor="let item of getByDay('Friday'); let i = index">
                                        <div class="card" style="margin-bottom: 10px; background-color: whitesmoke;">
                                            <div class="card-body">
                                                <p style="font-weight: 500;">{{item.trackNumber}} -{{item.departmentCode}} - {{item.jobCardType}} </p>
                                                <p style="font-size: x-small;">{{item.jobCardStatus}}</p>
                                                <p style="font-size: x-small;">{{item.allocatedTo}}</p>
                                                <p style="font-size: x-small;">{{item.orderNumber}}</p>
                                                <button mat-raised-button class="mat-success" (click)="onEdit('edit',item.jobCardId)">
                                                    View</button>
                                            </div>
                                        </div>
                                    </span>
                                </td>
                                <td>
                                    <span *ngFor="let item of getByDay('Saturday'); let i = index">
                                        <div class="card" style="margin-bottom: 10px; background-color: whitesmoke;">
                                            <div class="card-body">  
                                                <p style="font-weight: 500;">{{item.trackNumber}} -{{item.departmentCode}} - {{item.jobCardType}} </p>
                                                <p style="font-size: x-small;">{{item.jobCardStatus}}</p>
                                                <p style="font-size: x-small;">{{item.allocatedTo}}</p>
                                                <p style="font-size: x-small;">{{item.orderNumber}}</p>
                                                <button mat-raised-button class="mat-success" (click)="onEdit('edit',item.jobCardId)">
                                                    View</button>
                                            </div>
                                        </div>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</div>