import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  API_URL: string = environment.serverUrl + "/reports";

  constructor(private http: HttpClient) { }

  getAllCreatedJobCardByDateRange(dateFrom: Date, dateTo: Date) {
    return this
      .http
      .get(`${this.API_URL}/getAllCreatedJobCardByDateRange/${dateFrom}/${dateTo}`);
  }

  getAllCompletedJobCardByDateRange(dateFrom: Date, dateTo: Date) {
    return this
      .http
      .get(`${this.API_URL}/generateAllDepartmentCompletedJobCards/${dateFrom}/${dateTo}`);
  }

  generateDepartmentCreatedJobCards(dateFrom: Date, dateTo: Date , department : number) {
    return this
      .http
      .get(`${this.API_URL}/generateDepartmentCreatedJobCards/${dateFrom}/${dateTo}/${department}`);
  }

  generateDepartmentCompletedJobCards(dateFrom: Date, dateTo: Date , department : number) {
    return this
      .http
      .get(`${this.API_URL}/generateDepartmentCompletedJobCards/${dateFrom}/${dateTo}/${department}`);
  }

}
