import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenstorageService } from './tokenstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdServiceService {

  constructor(private router: Router,
    private tokenServ: TokenstorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenServ.isUserLoggedIn())
      return true;

    this.router.navigate(['']);
    return false;

  }
}
