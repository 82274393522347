import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EntityService } from 'src/services/entity.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-add-entity',
  templateUrl: './add-entity.component.html',
  styleUrls: ['./add-entity.component.css']
})
export class AddEntityComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  dsEntities: any;

  constructor(private fb: FormBuilder, private apiEntityServ: EntityService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEntityComponent>) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit() {
    this.createForm();
    this.loadAllEntities();    
  }

  createForm() {
    this.form = this.fb.group({
      "systemEntityName": [null, [Validators.required]]
    });
  }

  loadAllEntities() {
    this.apiEntityServ.getAll().subscribe(data => {
      this.dsEntities = data;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  checkIfEntityAdded(entityName: String): boolean {
    let searchEntity = this.dsEntities.filter(t => t.systemEntityName == entityName);
    if (searchEntity && searchEntity.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  onSubmit(btnName) {
    if (this.checkIfEntityAdded(this.form.value["systemEntityName"]) == false) {
      if (btnName == "btnSave") {
        var formData = this.form.value;
        formData.id = 0;
        formData.createdBy = this.loggedUserId;
        formData.createdAt = new Date();
        this.apiEntityServ.create(formData).subscribe(data => {
          this.dialogRef.close({ refresh : true});  
        });
      } else {
        if(btnName == "btnCancel") {
          this.dialogRef.close();
        }
      }
    } else {
      this.snackBar.open("Error Message", "Entity already added", {
        duration: 2000,
      });
    }
  }

}
