<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    {{pageTitle}} : {{data.documentName}}
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-warning">
                    <mat-selection-list formControlName="departmentList" #departmentList
                        (selectionChange)="onChangeDocumentAccess(departmentList.selectedOptions.selected)">
                        <mat-list-option *ngFor="let item of dsDepartment" [value]="item.id" [selected]="item.selected">
                            {{item.departmentName}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
            </div>
        </div>
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')"
            [disabled]="form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>