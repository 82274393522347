<div class="container-fluid" style="margin-top: 10px;">
    <form fxLayout="column" [formGroup]="form">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Report Name : {{finalReportName}}</mat-card-title>
            </mat-card-header>
            <br>
            <mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
            </mat-progress-bar>
            <mat-card-content>
                <div class="row" *ngIf ="hideDepartment">
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Enter Search">
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-label>Date From</mat-label>
                            <input matInput [matDatepicker]="dateFrom" formControlName="dateFrom" required
                                (dateChange)="onStartDateChange($event)">
                            <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                            <mat-datepicker #dateFrom></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-label>Date To</mat-label>
                            <input matInput [matDatepicker]="dateTO" formControlName="dateTO" required
                                (dateChange)="onEndDateChange($event)">
                            <mat-datepicker-toggle matSuffix [for]="dateTO"></mat-datepicker-toggle>
                            <mat-datepicker #dateTO></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-5" align="end">
                        <button (click)="generateReport()" class="btn btn-success">Generate Report</button><span class="spaceBetweenButtons"></span> <button
                            (click)="exportTOExcel()" class="btn btn-danger">Export TO Excel</button>
                    </div>
                </div>

                <div class="row"  *ngIf ="!hideDepartment">
                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Enter Search">
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="department" matInput placeholder="Department">
                                <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                                    {{item.departmentName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-label>Date From</mat-label>
                            <input matInput [matDatepicker]="dateFrom" formControlName="dateFrom" required
                                (dateChange)="onStartDateChange($event)">
                            <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                            <mat-datepicker #dateFrom></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-label>Date To</mat-label>
                            <input matInput [matDatepicker]="dateTO" formControlName="dateTO" required
                                (dateChange)="onEndDateChange($event)">
                            <mat-datepicker-toggle matSuffix [for]="dateTO"></mat-datepicker-toggle>
                            <mat-datepicker #dateTO></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3" align="end">
                        <button (click)="generateReport()" class="btn btn-success">Generate Report</button><span class="spaceBetweenButtons"></span> <button
                            (click)="exportTOExcel()" class="btn btn-danger">Export TO Excel</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table mat-table [dataSource]="dsReportDataList" matSort class="mat-elevation-z8">

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Track No </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="departmentFrom">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept From </th>
                                <td mat-cell *matCellDef="let element"> {{element.departmentFrom}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="departmentTo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept To </th>
                                <td mat-cell *matCellDef="let element"> {{element.departmentTo}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'dd-MM-yyyy'}}</td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>  

                            <ng-container matColumnDef="createdBy">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                                <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="orderNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No </th>
                                <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="orderNumberDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.orderNumberDate | date : 'dd-MM-yyyy'}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="orderGeneratedBy">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Gen By </th>
                                <td mat-cell *matCellDef="let element"> {{element.orderGeneratedBy}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="requiredStartDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Req Start Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.requiredStartDate | date : 'dd-MM-yyyy'}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="requiredStartTime">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Req Start Time </th>
                                <td mat-cell *matCellDef="let element"> {{element.requiredStartTime}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="requiredEndDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Req End Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.requiredEndDate | date : 'dd-MM-yyyy'}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="requiredEndTime">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Req End Time </th>
                                <td mat-cell *matCellDef="let element"> {{element.requiredEndTime}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.startDate | date : 'dd-MM-yyyy'}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="startTime">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Time </th>
                                <td mat-cell *matCellDef="let element"> {{element.requistartTimeredEndTime}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="completedOn">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Completed On </th>
                                <td mat-cell *matCellDef="let element"> {{element.completedOn | date : 'dd-MM-yyyy'}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="jobCardType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> JobCard Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.jobCardType}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="statusName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
                                <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="tag">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag</th>
                                <td mat-cell *matCellDef="let element"> {{element.tag}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="assignedTo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Assigned To</th>
                                <td mat-cell *matCellDef="let element"> {{element.assignedTo}} </td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>
                          
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"> </tr>

                            
                        </table>
                        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40, 50, 60 ,70, 80 ,90 ,100]">
                        </mat-paginator>
                    </div>
                </div>              
            </mat-card-content>
        </mat-card>
    </form>
</div>