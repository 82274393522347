export class CreateUpdateDocumentDTO {

    id : number;
	
	createdBy : number;
	
	createdAt : Date;
	
	editedBy : number;
	
	editedAt : Date;
	
	documentTypeId : number;
	
	awsImageRef : string;

	documentName : string;
	    
}
