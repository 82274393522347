<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Add Job Card
</h1>
<mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
</mat-progress-bar>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content> 
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="departmentIdTo" matInput placeholder="Department To" (selectionChange)="onChangeDepartment($event)">
                        <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>      
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="jobCardTypeId" matInput placeholder="Job Card Type">
                        <mat-option *ngFor="let item of dsJobCardType" [value]="item.id">
                            {{item.name}} - {{item.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">              
                <mat-form-field class="example-full-width">
                    <mat-label>Equipment Tag</mat-label>
                    <input type="text" matInput [formControl]="equipmentTagControl" [matAutocomplete]="auto" required>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEquipmentFn">
                      <mat-option *ngFor="let option of equipmentTagOptions | async" [value]="option">
                        {{option.instrumentTag}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
        </div> 
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Required Start Date</mat-label>
                    <input matInput [matDatepicker]="reqStartDate" formControlName="reqStartDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="reqStartDate"></mat-datepicker-toggle>
                    <mat-datepicker #reqStartDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="reqStartTime" matInput placeholder="Required Start Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Required End Date</mat-label>
                    <input matInput [matDatepicker]="reqEndDate" formControlName="reqEndDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="reqEndDate"></mat-datepicker-toggle>
                    <mat-datepicker #reqEndDate></mat-datepicker>
                </mat-form-field>

            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="reqEndTime" matInput placeholder="Required End Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Main Description</mat-label>
                    <textarea matInput formControlName="description" required></textarea>
                  </mat-form-field>
            </div>
            <div class="col-md-6">                
            </div>   
        </div>        
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end" [ngStyle]="{'visibility':loading==false ? 'visible' : 'hidden'}">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>


