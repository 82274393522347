import { Component, OnInit, Inject } from '@angular/core';
import { Router, Data } from '@angular/router';
import { CompanyTypeService } from 'src/services/company-type.service';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { CompanyService } from 'src/services/company.service';
import { ContactService } from 'src/services/contact.service';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {

  loading: boolean = true;
  form: FormGroup;
  btnName: string;
  dsCompanyType: any;
  loggedUserId: number;

  constructor(private fb: FormBuilder, private router: Router, private apiCompanyTypeServ: CompanyTypeService, private apiCompanyServ: CompanyService, private apiContactServ: ContactService, private tokenStorage: TokenstorageService, private dialog: MatDialog, private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddCompanyComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.loadCompanyType();
  }

  createForm() {
    this.form = this.fb.group({
      companyTypeId: [null, Validators.required],
      companyName: [null, Validators.required],
      description: [null, Validators.required],
      cell: [""],
      email: ["", Validators.required],
      phoneNumber: [""],
      faxNumber: [""],
      physicalAddressLine1: ["", Validators.required],
      physicalAddressLine2: ["", Validators.required],
      physicalAddressCode: ["", Validators.required],
      postalAddressLine1: [""],
      postalAddressLine2: [""],
      postalAddressCode: [""]
    });
  }

  loadCompanyType() {
    this.loading = true;
    this.apiCompanyTypeServ.getAll().subscribe(data => {
      this.dsCompanyType = data;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  async onSubmit(btnName) {

    try {
      if (btnName == "btnSave") {     
        const company = await this.submitCompany();
        const contact = await this.submitCompanyContact(company["id"]);
        this.dialogRef.close();
      } else {
        if(btnName == "btnCancel"){
          this.dialogRef.close();
        }
      }

    } catch (error) {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      return;

    }
  }

  submitCompany() {

    var formData = this.form.value;
    formData.id = 0;
    formData.createdBy = this.loggedUserId;
    formData.createdAt = new Date();
    return this.apiCompanyServ.create(formData).toPromise();

  }

  submitCompanyContact(entityId) {

    var formContactData = {
      id: 0,
      physicalAddressLine1: this.form.value.physicalAddressLine1,
      physicalAddressLine2: this.form.value.physicalAddressLine2,
      physicalAddressCode: this.form.value.physicalAddressCode,
      postalAddressLine1: this.form.value.postalAddressLine1,
      postalAddressLine2: this.form.value.postalAddressLine2,
      postalAddressCode: this.form.value.postalAddressCode,
      faxNumber: this.form.value.faxNumber,
      phoneNumber: this.form.value.phoneNumber,
      cellNumber: this.form.value.cell,
      email: this.form.value.email,
      isActive: true,
      entityName: "Company",
      entityId: entityId,
    }
    return this.apiContactServ.create(formContactData).toPromise();

  }

}
