<div class="container-fluid" style="margin-top: 10px;">    
    <mat-card>
        <mat-card-content>
            <div class="row">
                <div class="col-md-12" style="color: #0A0045; font-size: 20px;margin-bottom: 10px;font-weight: 500;">
                    List Of Menu
                </div>
            </div>
            <div class="row card-Menu-dev">
                <div class="col-md-3" *ngFor="let item of adminMenu">
                    <mat-card class="example-card" (click)="onClickMenu(item)">
                        <mat-card-header>
                            <div mat-card-avatar><span class="material-icons">
                                    {{item.icon}}
                                </span></div>
                            <mat-card-title style="margin-left:-19px;">{{item.title}}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p class="mat-contentDesc">
                                {{item.description}}
                            </p>
                        </mat-card-content>
                    </mat-card>

                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>