<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    {{pageTitle}}
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">

            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Area Code</mat-label>
                    <input matInput formControlName="areaCode" required>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Instrument Code</mat-label>
                    <input matInput formControlName="instrumentCode" required>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Sequence Equipment No</mat-label>
                    <input matInput formControlName="sequenceEquipNo" required>
                </mat-form-field>
            </div>
        </div>
        <div class="row"> 

            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Sequence Instrument No</mat-label>
                    <input matInput formControlName="sequenceInstrumentNo">
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Instrument Tag</mat-label>
                    <input matInput formControlName="instrumentTag" (ngModelChange)="onChangeInstrumentTagEvent($event)">
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Rio Panel No</mat-label>
                    <input matInput formControlName="rioPanelNo">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-select formControlName="equipmentCategoryId" matInput placeholder="Equipment Category">
                        <mat-option *ngFor="let item of dsEquipmentCategory" [value]="item.id">
                            {{item.equipmentCategoryDesc}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
        </div>
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')"
            [disabled]="!form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>
