import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { DocumentTypeService } from 'src/services/document-type.service';
import { AddEditDocumentTypeComponent } from '../dialogs/add-edit-document-type/add-edit-document-type.component';

@Component({
  selector: 'app-list-document-types',
  templateUrl: './list-document-types.component.html',
  styleUrls: ['./list-document-types.component.css']
})
export class ListDocumentTypesComponent implements OnInit {
  
  public displayedColumns: string[] = ['id','departmentName', 'departmentCode', 'documentTypeName', 'createdAt'];

  public dsDocumentType = new MatTableDataSource<documentTypedata>();

  constructor(private router: Router, private apiDocumentTypeServ: DocumentTypeService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsDocumentType.sort = this.sort;
    this.dsDocumentType.paginator = this.paginator;
  }

  refreshData() {
    this.apiDocumentTypeServ.getAll().subscribe(data => {
     this.dsDocumentType.data = data as documentTypedata[];
    }, error => {
      if (error.status == 404) {
        console.log("No Record Found");
      } else {
        console.log(error);
      }
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditDocumentTypeComponent, {
      data: null,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsDocumentType.filter = filterValue.trim().toLowerCase();

    if (this.dsDocumentType.paginator) {
      this.dsDocumentType.paginator.firstPage();
    }
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditDocumentTypeComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }
}

export interface documentTypedata {

 id : number;

 documentTypeName : string;

 createdBy : number;

 createdAt : Date;

 editedBy: number;

 editedAt : Date;

 isActive :boolean; 

 departmentCode : string;

 departmentName : string;

}
