<div class="container-fluid" style="margin-top: 10px;">
    <mat-card>
        <mat-card-header>
            <mat-card-title>List Of Reports</mat-card-title>
        </mat-card-header>
        <br>
        <mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
        </mat-progress-bar>
        <mat-card-content>
            <form [formGroup]="form">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field fxFlex>
                        <mat-label>Report Name</mat-label>
                        <mat-select formControlName="reportName" matInput placeholder="Reports" (selectionChange)="reportOnChanged($event)">
                            <mat-option *ngFor="let item of dsReports" [value]="item.id">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
        </mat-card-content>
    </mat-card>
</div>
