import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CompanyTypeService } from 'src/services/company-type.service';
import { AddEditcompanyTypeComponent } from '../dialogs/add-editcompany-type/add-editcompany-type.component';

@Component({
  selector: 'app-list-company-type',
  templateUrl: './list-company-type.component.html',
  styleUrls: ['./list-company-type.component.css']
})
export class ListCompanyTypeComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'description', 'createdAt'];

  public dsCompanyType = new MatTableDataSource<companyTypeData>();

  constructor(private router: Router, private apiCompanyTypeServ: CompanyTypeService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsCompanyType.sort = this.sort;
    this.dsCompanyType.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsCompanyType.filter = filterValue.trim().toLowerCase();

    if (this.dsCompanyType.paginator) {
      this.dsCompanyType.paginator.firstPage();
    }
  }

  refreshData() {
    this.loading = true;
    this.apiCompanyTypeServ.getAll().subscribe(data => {
      this.dsCompanyType.data = data as companyTypeData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditcompanyTypeComponent, {
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });    
  }
  
  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditcompanyTypeComponent, {
      data: row,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });
  }

}

export interface companyTypeData {

  id: number;

  description: string;

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  isActive: boolean;
  
}
