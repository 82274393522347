<h1 mat-dialog-title>
    {{pageTitle}}
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select formControlName="routineMaintenanceTypeId" matInput
                        placeholder="Routine Type" required>
                        <mat-option *ngFor="let item of dsRoutineMatenanceType" [value]="item.id">
                            {{item.routineTypeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Equipment Tag</mat-label>
                    <input type="text" matInput [formControl]="equipmentTagControl" #autoCompleteInput
                        [matAutocomplete]="auto" required>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEquipmentFn">
                        <mat-option *ngFor="let option of equipmentTagOptions | async" [value]="option">
                            {{option.instrumentTag}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select formControlName="jobCardTypeId" matInput placeholder="Job Card Type" required>
                        <mat-option *ngFor="let item of dsJobCardType" [value]="item.id">
                            {{item.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select formControlName="departmentId" matInput placeholder="Department" required>
                        <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Start Execution Date</mat-label>
                    <input matInput [matDatepicker]="startDate" formControlName="startDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Frequency/Days</mat-label>
                    <input matInput type="number" formControlName="frequencyDays" required>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Repeat</mat-label>
                    <mat-select formControlName="frequecyRepeat" required>
                        <mat-option value="true">Yes</mat-option>
                        <mat-option value="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">            
                <mat-form-field>
                    <mat-label>Instruction To Check/Follow</mat-label>
                    <textarea matInput formControlName="instruction" required></textarea>
                </mat-form-field>                           
            </div>
        </div>        
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end" *ngIf="hideButtons"
        [ngStyle]="{'visibility':loading==false ? 'visible' : 'hidden'}">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')"
            [disabled]="!form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>