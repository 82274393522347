import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipmentCategoryRootCauseFailureLinkService {

  API_URL: string = environment.serverUrl + "/equipmentCategoryRootCauseFailureLink";

  constructor(private http : HttpClient) { }

  getAll(): Observable<any> {
    return this
      .http
      .get(`${this.API_URL}`);
  }

  getById(id : number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  } 

  getAllEquipmentCategoryRootCauseLinkByCategoryId(equipmentCategoryId : number) {   
    return this
      .http
      .get(`${this.API_URL}/getAllEquipmentCategoryRootCauseLinkByCategoryId/${equipmentCategoryId}`);    
  }

  create(equipmentRootCause: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, equipmentRootCause);
  }

  bulkInsertEquipmentCategoryRootCauseLink(equipmentCategoryRootCause: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}/bulkInsertEquipmentCategoryRootCauseLink`, equipmentCategoryRootCause);
  }

}