import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  isLoggedIn: boolean = false;
  currentLoggedUser: string = "";
  menuAccess: any;
  isExpanded : boolean = true;

  constructor(private tokenServ: TokenstorageService, private router: Router) { }

  ngOnInit() {
    this.isLoggedIn = this.tokenServ.isUserLoggedIn();
    var user = this.tokenServ.getUser();
    if (user) {
      this.currentLoggedUser = user.firstName + " " + user.lastName;
      let arrayMenu = JSON.parse(this.tokenServ.getMenu());
      this.menuAccess = arrayMenu.sort(function (a, b) {
        return a.menuSequence - b.menuSequence;
      });

    }
  }

  onLogout() {
    this.tokenServ.signOut();
    window.location.reload();
  }

}