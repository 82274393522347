import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { DepartmentService } from 'src/services/department.service';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.css']
})

export class AddDepartmentComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  dsEntities: any;

  constructor(private fb: FormBuilder, private apiDepartment: DepartmentService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddDepartmentComponent>) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      "departmentName": [null, [Validators.required]],
      "departmentCode" : [null, [Validators.required]]
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      var formData = this.form.value;
      formData.id = 0;
      formData.createdBy = this.loggedUserId;
      formData.createdAt = new Date();
      this.apiDepartment.create(formData).subscribe(data => {
        this.dialogRef.close({ refresh : true}); 
      });
    } else {
      if(btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}
