import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.css']
})
export class AdminMenuComponent implements OnInit {

  adminMenu: any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.adminMenu = [
      {
        title: 'User',
        description: 'Manager User',
        icon: "supervisor_account",
        router: "UsersList"
      },
      {
        title: 'Entity',
        description: 'Manager Entity',
        icon: "store",
        router: "EntityList"
      },
      {
        title: 'Roles',
        description: 'Manager Roles',
        icon: "person_pin",
        router: "RolesList"
      },
      {
        title: 'Departments',
        description: 'Departments',
        icon: "school",
        router: "DepartmentList"
      },
      {
        title: "Job Card Type",
        description: 'Job Card Type',
        icon: "layers",
        router: "JobcardTypeList"       
      },
      {
        title: "Equipment",
        description: 'Equipment',
        icon: "event_seat",
        router: "ThakaduEquipmentTypeList"       
      },
      {
        title: "Equipment Category",
        description: 'Equipment Category',
        icon: "event",
        router: "ThakaduEquipmentCategoryList"       
      },
      {
        title: "Equipment Component",
        description: 'Equipment Component',
        icon: "settings_input_component",
        router: "EquipmentComponentList"       
      },
      {        
        title: "Equipment Failure Type",
        description: 'Equipment Failure Type',
        icon: "invert_colors_off",
        router: "EquipmentFailureTypeList" 
      },
      {        
        title: "Failure Root Cause",
        description: 'Equipment Failure Root Cause',
        icon: "hearing",
        router: "EquipmentRootCauseFailureList" 
      },
      {        
        title: "Equipment Repair Type",
        description: 'Equipment Repair Type',
        icon: "gps_fixed",
        router: "EquipmentRepairTypeList" 
      },
      {
        title: "Repair Symptons Links",
        description: 'Equipment Category Repair Symptons Links',
        icon: "gps_fixed",
        router: "EquipmentRepairTypeSymptonsList" 
        
      },      
      {
        title: "Document Type",
        description: 'Document Type',
        icon: "description",
        router: "DocumentTypeList"       
      },
      {
        title: "Document Upload",
        description: 'Document Upload',
        icon: "cloud_upload",
        router: "adminDocumentList"       
      }
      /*{
        title: 'Company Type',
        description: 'Company Type',
        icon: "picture_in_picture",
        router: "CompanyTypeList"
      },
      {
        title: 'Company',
        description: 'Company',
        icon: "web",
        router: "CompanyList"
        
      } , 
      {
        title: 'Equipment Type',
        description: 'Equipment Type',
        icon: "devices",
        router: "EquipmentTypeList"        
      },
      {
        title: "Equipment",
        description: 'Equipment',
        icon: "developer_mode",
        router: "EquipmentList"       
      },
      {
        title: "Job Card Type",
        description: 'Job Card Type',
        icon: "gavel",
        router: "JobcardTypeList"       
      },
      {
        title: "Sup Functional Location",
        description: 'Sup Functional Location',
        icon: "location_on",
        router: "SupFunationalLocationList"       
      },
      {
        title: "Functional Location",
        description: 'Functional Location',
        icon: "pin_drop",
        router: "FunctionLocationList"
      }*/

    ];
  }

  onClickMenu(item) {
    this.router.navigate([item.router]);
  }

  public onCardClick(evt: MouseEvent) {
    console.log(evt);
  }
  
}

