<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    {{pageTitle}}
</h1>

<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
    </mat-progress-bar>
    <div mat-dialog-content> 
        <div class="row">
            <div class="col-md-12">
                <mat-form-field fxFlex>
                    <mat-select formControlName="departmentId" matInput placeholder="Department" (selectionChange)="onChangeDepartment($event)">
                        <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>      
       </div>   
        <div class="row">
            <div class="col-md-12">
                <mat-form-field fxFlex>
                    <mat-select formControlName="documentTypeId" matInput placeholder="Document Type">
                        <mat-option *ngFor="let item of dsDocumentType" [value]="item.id">
                            {{item.documentTypeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>      
       </div>       
        <div class="row">
            <div class="col-md-12">
                <mat-form-field fxFlex>
                    <mat-label>Document Name</mat-label>
                    <input matInput formControlName="documentName" required>               
                </mat-form-field>
            </div>  
                    
        </div> 
        <div>
            <div class="col-md-12" style="border: 0 !important; margin-bottom: 10px;">          
                <input  #inputUploadFile type="file" style="border: 0px;"
                formControlName="fileName" (change)="onFileChanged($event)">                    
            </div> 
        </div>       
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')" [disabled]="form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>
