import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentService } from 'src/services/department.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { AddDepartmentComponent } from '../dialogs/add-department/add-department.component';
import { EditDepartmentComponent } from '../dialogs/edit-department/edit-department.component';

@Component({
  selector: 'app-list-department',
  templateUrl: './list-department.component.html',
  styleUrls: ['./list-department.component.css']
})
export class ListDepartmentComponent implements OnInit {

  public displayedColumns: string[] = ['id', 'departmentName', 'departmentCode', 'createdAt'];

  public dsDepartment = new MatTableDataSource<departmentData>();

  constructor(private router: Router, private apiDepartmentSerc: DepartmentService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsDepartment.sort = this.sort;
    this.dsDepartment.paginator = this.paginator;
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddDepartmentComponent, {
      data: { roleData : ''},
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditDepartmentComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsDepartment.filter = filterValue.trim().toLowerCase();

    if (this.dsDepartment.paginator) {
      this.dsDepartment.paginator.firstPage();
    }
  }

  refreshData() {
    this.apiDepartmentSerc.getAll().subscribe(data => {
      this.dsDepartment.data = data as departmentData[];
    }, error => {
      if (error.status == 404) {
        console.log("No Record Found");
      } else {
        console.log(error);
      }
    });
  }

}

export interface departmentData {
  id : number;
  departmentName : string; 
  createdBy : number;
  createdAt : Date;
  editedBy: number;
  editedAt : Date;
  isActive :boolean; 
 }
