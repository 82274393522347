import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class JobCardTaskService {

  API_URL: string = environment.serverUrl + "/jobCardTask";

  constructor(private http : HttpClient) { }

  getAll(): Observable<any> {
    return this
      .http
      .get(`${this.API_URL}`);
  }

  getAllJobCardTaskByJobCardId(jobcardId : number) {   
    return this
      .http
      .get(`${this.API_URL}/getAllJobCardTaskByJobCardId/${jobcardId}`);    
  }
  
  getJobAllTaskByDateRangeAndDepartment(departmentId : number ,startDate : Date, endDate : Date) {   
    return this
      .http
      .get(`${this.API_URL}/getJobAllTaskByDateRangeAndDepartment/${startDate}/${endDate}/${departmentId}`);    
  }

  getTaskAssignedToUserByUserIdAndStatusId(assigneeId : number ,statusId : number) {   
    return this
      .http
      .get(`${this.API_URL}/getTaskAssignedToUserByUserIdAndStatusId/${assigneeId}/${statusId}`);    
  }
 
  getById(id : number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  } 

  create(debtor: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, debtor);
  }
}