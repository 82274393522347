import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { AuthenticationService } from 'src/services/authentication.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { JobCardService } from 'src/services/job-card.service';

import { EditJobCardComponent } from '../dialogs/edit-job-card/edit-job-card.component';
import { Globals } from '../global-values/globals';
import { JobCardStatusService } from 'src/services/job-card-status.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { JobCardsStatsService } from 'src/services/job-cards-stats.service';
import { DepartmentService } from 'src/services/department.service';
import { JobCardDepartmentResourceStatsService } from 'src/services/job-card-department-resource-stats.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  form: any = {};
  loading: boolean = false;
  loggedUserId: number;
  userDepartmentId: number;
  hideMyTask: boolean = false;
  dsMyTaskStatus: any[] = [];
  jobCardStatusId: number = -1;
  dsDepartment: any[] = [];
  dsDepartmentResources: any[] = [];
  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth() + 1;

  barChartLabelsMonth: Label[] = [];
  barChartDataMonth: ChartDataSets[] = [{ data: [], label: '' }];
  barChartColorsMonth: Color[] = [];
  barChartTypeMonth: ChartType = 'bar';
  barChartLegendMonth = true;

  barChartLabelsYear: Label[] = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  barChartDataYear: ChartDataSets[] = [{ data: [], label: '' }];
  barChartLegendYear = true;
  barChartTypeYear: ChartType = 'bar';
  barChartColorsYear: Color[] = [];

  barChartLabelsResourceMonth: Label[] = [];
  barChartDataResourceMonth: ChartDataSets[] = [{ data: [], label: '' }];
  barChartLegendResourceMonth = true;
  barChartTypeResourceMonth: ChartType = 'bar';
  barChartColorsResourceColour: Color[] = [];

  public displayedJobCardColumns: string[] = ['id', 'orderNumber', 'departmentCode', 'departmentToCode', 'tagName', 'reqStartDate', 'reqEndDate', 'startDate', 'endDate', 'statusName', 'createdByName', 'createdAt'];

  public displayedMyJobCardColumns: string[] = ['id', 'orderNumber', 'departmentCode', 'departmentToCode', 'tagName', 'reqStartDate', 'reqEndDate', 'startDate', 'endDate', 'statusName', 'createdByName', 'createdAt'];

  public dsDepartJobcards = new MatTableDataSource<jobCardData>();

  public dsMyJobCards = new MatTableDataSource<jobCardData>();

  constructor(private formBuilder: FormBuilder, private apiJobCardServ: JobCardService,
    private apiJobcardsStatusServ: JobCardStatusService, private snackBar: MatSnackBar,
    private authService: AuthenticationService, private tokenStorage: TokenstorageService,
    private apiJObcardStatsServ: JobCardsStatsService, private apiDepartmentServ: DepartmentService,
    private globalValues: Globals, private apiJobCardDepartmentResourceStatsServ: JobCardDepartmentResourceStatsService,
    private apiUsersServ: UserService, private dialog: MatDialog) {

    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.userDepartmentId = user.departmentId;
    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.createForm();
    this.loadAllDepartmentUsers();
    this.loadDepartmentJobCardByDateRange();
    this.loadAllUserJobCards();
    this.loadJobCardStatus(); 
    this.loadMonthlyDepartmentJobCardStats();
    this.loadYearlyDepartmentJobCardStats();
    this.loadMonthlyDepartmentJobCardResourceStats();
    this.loadDepartment();
  }

  loadAllDepartmentUsers() {
    this.barChartLabelsResourceMonth.length = 0;
    this.dsDepartmentResources.length = 0;
    this.apiUsersServ.getAllUserByDepartmentId(this.userDepartmentId).subscribe(res => {
      let ds = res as [];
      for (let i = 0; i < ds.length; i++) { 
        this.dsDepartmentResources.push(ds[i]["firstName"] + " " + ds[i]["lastName"]); 
        this.barChartLabelsResourceMonth.push(ds[i]["firstName"] + " " + ds[i]["lastName"]);
      }
    }, error => {
      console.log(error);
    });
  }

  loadDepartment() {
    this.barChartDataYear.length = 0;
    this.barChartDataYear.push({ data: [], label: "" });
    this.apiDepartmentServ.getAll().subscribe(data => {
      let departList: any = [];
      for (let o of data) {
        departList.push({ value: o.id, text: o.departmentName });
      }
      this.dsDepartment = departList;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    });
  }

  loadMonthlyDepartmentJobCardStats() {
    let dsMonthData: any[] = [];
    this.barChartLabelsMonth.length = 0;
    this.apiJObcardStatsServ.getMonthlyDepartmentJobCardStats(this.userDepartmentId, this.currentMonth, this.currentYear).subscribe(res => {
      let ds = res as [];
      for (let i = 0; i < ds.length; i++) {
        this.barChartLabelsMonth.push(ds[i]["statusName"]);
        dsMonthData.push(ds[i]["statsCount"]);
      }
      this.barChartDataMonth.length = 0;
      this.barChartDataMonth.push({ data: dsMonthData as [], label: 'Department Jobcard Stats' });
      this.barChartColorsMonth.push({ backgroundColor: '#0A0045' })
    }, error => {
      console.log(error);
    });
  }

  loadYearlyDepartmentJobCardStats() {
    this.apiJObcardStatsServ.getYearDepartmentJobCardStats(this.userDepartmentId, this.currentYear).subscribe(res => {
      let ds = res as [];
      for (let indexStatus = 0; indexStatus < this.dsMyTaskStatus.length; indexStatus++) {
        let dsMonthData: any[] = [];
        for (let month = 0; month < 12; month++) {
          let resMonth = ds.filter(r => r["statsMonth"] == month + 1 && r["statusId"] == this.dsMyTaskStatus[indexStatus].id);
          if (resMonth.length == 0) {
            dsMonthData.push(0.001);
          } else {
            if (resMonth[0]["statsCount"] == 0) {
              dsMonthData.push(0.001);
            } else {
              dsMonthData.push(resMonth[0]["statsCount"]);
            }
          }
        }
        if (this.barChartDataYear[0].label == "") {
          this.barChartDataYear.length = 0;
          this.barChartDataYear.push({ data: dsMonthData as [], label: this.dsMyTaskStatus[indexStatus].statusName });
        } else {
          this.barChartDataYear.push({ data: dsMonthData as [], label: this.dsMyTaskStatus[indexStatus].statusName });
        } 
      }
      this.barChartColorsYear.push({ backgroundColor: 'blue' },
        { backgroundColor: 'green' },
        { backgroundColor: 'Black' },
        { backgroundColor: 'Maroon' },
        { backgroundColor: 'Indigo' },
        { backgroundColor: 'Orange' },
        { backgroundColor: 'red' })

    }, error => {
      console.log(error);
    });
  }

  loadMonthlyDepartmentJobCardResourceStats() { 
    this.apiJobCardDepartmentResourceStatsServ.getJobCardStatsByDepartment(this.userDepartmentId, this.currentMonth, this.currentYear).subscribe(res => {
      let ds = res as [];
      debugger;
      for (let indexStatus = 0; indexStatus < this.dsMyTaskStatus.length; indexStatus++) {
        let dsMonthData: any[] = [];
        for (let indexResource = 0; indexResource < this.dsDepartmentResources.length; indexResource++) {  
          let resoureName = this.dsDepartmentResources[indexResource];
          let statsusName =  this.dsMyTaskStatus[indexStatus].statusName; 
          let resMonth =  ds.filter(r => r["resourceName"] == resoureName && r["statusName"] == statsusName );      
          if (resMonth.length == 0) {
             dsMonthData.push(0.001);
          } else {    
            if (resMonth[0]["statsCount"] == 0) {
              dsMonthData.push(0.001);
            } else {
              dsMonthData.push(resMonth[0]["statsCount"]);
            }
          }   
        }
        if (this.barChartDataResourceMonth[0].label == "") {
          this.barChartDataResourceMonth.length = 0;
          this.barChartDataResourceMonth.push({ data: dsMonthData as [], label: this.dsMyTaskStatus[indexStatus].statusName });
        } else {
          this.barChartDataResourceMonth.push({ data: dsMonthData as [], label: this.dsMyTaskStatus[indexStatus].statusName});
        }
      }
      this.barChartColorsResourceColour.push({ backgroundColor: 'blue' },
        { backgroundColor: 'green' },
        { backgroundColor: 'Black' },
        { backgroundColor: 'Maroon' },
        { backgroundColor: 'Indigo' },
        { backgroundColor: 'Orange' },
        { backgroundColor: 'red' })
    }, error => {
      console.log(error);
    });
  }

  barChartOptionsMonth: ChartOptions = {
    responsive: true,
  };

  barChartOptionsYear: ChartOptions = {
    responsive: true,
  };

  barChartOptionResourceMonth : ChartOptions = {
    responsive : true,
  }

  barChartPluginsMonth = [];

  loadJobCardStatus() {
    this.apiJobcardsStatusServ.getAutoCompleteStatus().subscribe(data => {
      this.dsMyTaskStatus = data as [];
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    })
  }

  dsMonth = [{
    value: 1,
    text: "Jan"
  },
  {
    value: 2,
    text: "Feb"
  },
  {
    value: 3,
    text: "March"
  },
  {
    value: 4,
    text: "April"
  },
  {
    value: 5,
    text: "May"
  },
  {
    value: 6,
    text: "June"
  },
  {
    value: 7,
    text: "July"
  },
  {
    value: 8,
    text: "Aug"
  },
  {
    value: 9,
    text: "Sep"
  },
  {
    value: 10,
    text: "Oct"
  },
  {
    value: 11,
    text: "Nov"
  },
  {
    value: 12,
    text: "Dec"
  }]

  ds = [{
    value: 2021,
    text: 2021
  }]


  refreshData() {
    this.loadDepartmentJobCardByDateRange();
    this.loadAllUserJobCards();
  }

  ngAfterViewInit(): void {
    this.dsDepartJobcards.sort = this.sort;
    this.dsDepartJobcards.paginator = this.paginator;
  }

  onStartDateChange(event) {
    this.loadDepartmentJobCardByDateRange();
  }

  onEndDateChange(event) {
    this.loadDepartmentJobCardByDateRange();
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditJobCardComponent, {
      data: row,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadDepartmentJobCardByDateRange();
      }
    });
  }

  onEditJobTask(row) {
    this.apiJobCardServ.getById(row.jobCardId).subscribe(res => {
      const dialogRef = this.dialog.open(EditJobCardComponent, {
        data: res,
        maxWidth: '97vw',
        maxHeight: '99vh',
        width: '97vw',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loadDepartmentJobCardByDateRange();
        }
      });

    }, error => {
      console.log(error);
    });
  }

  loadDepartmentJobCardByDateRange() {
    let global = new Globals();
    let selectedDateFrom = new Date(this.form.value["dateFrom"]);
    let selectedDateTo = new Date(this.form.value["dateTO"]);
    var fromDate = new Date(selectedDateFrom.getFullYear(), selectedDateFrom.getMonth(), selectedDateFrom.getDate() - 1, 23, 59, 0);
    var toDate = new Date(selectedDateTo.getFullYear(), selectedDateTo.getMonth(), selectedDateTo.getDate(), 23, 59, 0);
    if (this.userDepartmentId == global.productionDepartmentId) {
      this.hideMyTask = false;
      this.apiJobCardServ.getAllByDepartmentFromBetweenDates(this.userDepartmentId, fromDate, toDate).subscribe(res => {
        this.dsDepartJobcards.data = res as jobCardData[];
        console.log(this.dsDepartJobcards.data);
      }, error => {
        console.log(error);
      });
    } else if (this.userDepartmentId == global.sheqDepartmentId) {
      this.hideMyTask = false;
      this.apiJobCardServ.getAllJobcardByTypeBetweenDates(this.globalValues.jobCardTypeSheq, fromDate, toDate).subscribe(res => {
        this.dsDepartJobcards.data = res as jobCardData[];
        console.log(this.dsDepartJobcards.data);
      }, error => {
        console.log(error);
      });
    } else {
      this.hideMyTask = true;
      this.apiJobCardServ.getAllByDepartmentTOBetweenDates(this.userDepartmentId, fromDate, toDate).subscribe(res => {
        this.dsDepartJobcards.data = res as jobCardData[];
        console.log(this.dsDepartJobcards.data);
      }, error => {
        console.log(error);
      });
    }
  }

  loadAllUserJobCards() {
    let global = new Globals();
    this.apiJobCardServ.getAllJobCardByUserIdAndStatus(this.loggedUserId, this.jobCardStatusId).subscribe(res => {
      this.dsMyJobCards.data = res as jobCardData[];
    }, error => {
      console.log(error);
    });
  }

  createForm() {
    var dt = new Date(); // current date of week
    var currentWeekDay = dt.getDay();
    var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
    var wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
    var wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));

    this.form = this.formBuilder.group({
      dateFrom: [new Date(wkStart)],
      dateTO: [new Date(wkEnd)],
      statusId: [this.jobCardStatusId],
      currentMonth: this.currentMonth,
      userDepartmentId: this.userDepartmentId
    });
  }

  getTotalJobCards() {
    return this.dsDepartJobcards.data.length;
  }

  onMyJobCardsStatus(event) {
    this.jobCardStatusId = event.value;
    this.loadAllUserJobCards();
  }

  onChangeStatsMonth(event) {
    var user = this.tokenStorage.getUser();
  }

  onChangeMonth(event) {
    this.barChartDataYear.length = 0;       
    this.barChartDataYear.push({ data: [], label: "" });
   

    this.barChartDataResourceMonth.length = 0;
    this.barChartDataResourceMonth.push({ data: [], label: "" });

    this.currentMonth = event.value;  
    this.loadMonthlyDepartmentJobCardStats();
    this.loadYearlyDepartmentJobCardStats();
    this.loadDepartmentJobCardByDateRange();
    this.loadMonthlyDepartmentJobCardResourceStats();
  }

  onChangeDepartment(event) {    
    this.barChartDataYear.length = 0;
    this.barChartDataYear.push({ data: [], label: "" });

    this.barChartDataResourceMonth.length = 0;
    this.barChartDataResourceMonth.push({ data: [], label: "" });    
    this.userDepartmentId = event.value;  
    this.loadAllDepartmentUsers();
    this.loadMonthlyDepartmentJobCardStats();
    this.loadYearlyDepartmentJobCardStats();
    this.loadDepartmentJobCardByDateRange();
    this.loadMonthlyDepartmentJobCardResourceStats();
  }

  onClickDepartmentPerfomance() { 
    debugger;  
    this.barChartDataResourceMonth.length = 0;
    this.barChartDataResourceMonth.push({ data: [], label: "" });     
    this.loadMonthlyDepartmentJobCardResourceStats();
  }  

}

export interface jobCardData {

  id: number;

  departmentId: number;

  departmentName: string;

  departmentCode: string;

  departmentIdTo: number;

  departnameTO: string;

  departmentToCode: string;

  statusId: number;

  statusName: string;

  startTime: Date;

  endTime: Date;

  completedOn: Date;

  description: string;

  createdBy: number;

  createdByName: String;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  isActive: boolean;

  jobCardTypeDesc: string;

  jobCardTypeId: number;

  isApproved: boolean;

  tagId: number;

  tagName: string;

  slaPercentage: number;

  orderNumber: string;

  reqStartDate: Date;

  reqEndDate: Date;

  startDate: Date;

  endDate: Date;

}