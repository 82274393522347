<div class="container-fluid" style="margin-top: 10px;">
    <mat-card>
        <mat-card-header>
            <mat-card-title>List Of Routine Maintenance Jobs</mat-card-title><span class="example-spacer"></span>
            <button mat-raised-button color="primary" (click)="refreshData()">Refresh</button><div class="spaceBetweenButtons"></div>
            <button mat-raised-button color="primary" (click)="onCreate()">Add</button>
        </mat-card-header>
        <br>
        <mat-card-content>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Enter Search">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table mat-table [dataSource]="dsRoutineMaintenance" matSort class="mat-elevation-z8">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="routineMaintenanceTypeName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Routine Type</th>
                            <td mat-cell *matCellDef="let element"> {{element.routineMaintenanceTypeName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="departmentName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                            <td mat-cell *matCellDef="let element"> {{element.departmentName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="jobCardTypeDesc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Card Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.jobCardTypeDesc}} </td>
                        </ng-container>

                        <ng-container matColumnDef="tagName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.tagName}} </td>
                        </ng-container>


                        <ng-container matColumnDef="startDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.startDate  | date : 'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="frequencyDays">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Frequency </th>
                            <td mat-cell *matCellDef="let element"> {{element.frequencyDays}} </td>
                        </ng-container>

                        <ng-container matColumnDef="frequecyRepeat">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Frequency Repeat </th>
                            <td mat-cell *matCellDef="let element"> {{element.frequecyRepeat}} </td>
                        </ng-container>
                                                
                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Create Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEdit(row)"></tr>
                    </table>
                </div>
            </div>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40, 50, 60 ,70, 80 ,90 ,100]">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>


