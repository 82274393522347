import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { debug } from 'console';
import { error } from 'protractor';
import { CreateUpdateDepartmentAssignedDocumentDTO } from 'src/models/create-update-department-assigned-document-dto';
import { CreateUpdateSystemUserDocumentActionDTO } from 'src/models/create-update-system-user-document-action-dto';
import { AwsS3DocumentService } from 'src/services/aws-s3-document.service';
import { DepartmentAssignedDocumentService } from 'src/services/department-assigned-document.service';
import { DepartmentService } from 'src/services/department.service';
import { DocumentService } from 'src/services/document.service';
import { SystemUserDocumentActionService } from 'src/services/system-user-document-action.service';
import { TokenstorageService } from 'src/services/tokenstorage.service'; import { Globals } from '../global-values/globals';
import { ConfirmApproveDocumentMessageComponent } from './confirm-approve-document-message/confirm-approve-document-message.component';
;

@Component({
  selector: 'app-list-user-document-access',
  templateUrl: './list-user-document-access.component.html',
  styleUrls: ['./list-user-document-access.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListUserDocumentAccessComponent implements OnInit {
  form: FormGroup;
  loading = false;
  loggedUserId: number;
  loggedUserDepartmentId: number;
  loggedIsSuperVisor: boolean = false;
  dsDepartment: any[] = [];

  public displayedColumns: string[] = ['id', 'departmentName', 'departmentCode', 'documentTypeName', 'documentName', 'createdAt', 'action'];

  public dsDocument = new MatTableDataSource<documentdata>();

  constructor(private fb: FormBuilder, private apiDepartmentServ: DepartmentService, private apiDocumentServ: DocumentService, private apiUserActionDocumentServ: SystemUserDocumentActionService,
    private tokenStorage: TokenstorageService,
    private snackBar: MatSnackBar, private httpClient: HttpClient,
    private awsS3DocumentServ: AwsS3DocumentService,
    private dialog: MatDialog) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.loggedUserDepartmentId = user.departmentId;
      this.loading = false;
    }

  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.loadDepartments();
    this.createForm();
    this.apiDocumentServ.getDepartmentDocumentById(this.loggedUserDepartmentId).subscribe(data => {
      this.dsDocument.data = data as documentdata[];
    }, error => {
      console.log(error);
    });
    this.form.patchValue({
      department: this.loggedUserDepartmentId
    });
  }

  createForm() {
    this.form = this.fb.group({
      department: new FormControl({ value: this.loggedUserDepartmentId }, Validators.compose([Validators.required])),
    });
  }

  ngAfterViewInit(): void {
    this.dsDocument.sort = this.sort;
    this.dsDocument.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsDocument.filter = filterValue.trim().toLowerCase();

    if (this.dsDocument.paginator) {
      this.dsDocument.paginator.firstPage();
    }
  }

  loadDepartments() {
    this.loading = true;
    this.apiDepartmentServ.getAll().subscribe(data => {
      this.loading = false;
      let dsResults = data as department[];
      this.dsDepartment.push({
        id: -1,
        departmentName: "-- All --",    
        departmentCode: "",
      });
      for(let i = 0 ; i < dsResults.length ; i++) {
        this.dsDepartment.push({
          id: dsResults[i].id,
          departmentName: dsResults[i].departmentName,  
          departmentCode: dsResults[i].departmentCode,  
        });
      }    
   });
  }

  onClickDepartment(event) {   
    this.loading = true;
    if(event.value == -1) {
      this.apiDocumentServ.getAll().subscribe(data => {
        this.dsDocument.data = data as documentdata[];
        this.loading = false;
      });
    }else{
      let department = this.dsDepartment.filter(r => r.id == event.value);
      if (department) {       
        this.apiDocumentServ.getDepartmentDocumentById(department[0].id).subscribe(data => {
          this.dsDocument.data = data as documentdata[];
          this.loading = false;
        });
      }
    }
  }

  actionDocument(actionType: string, row: any) {
    if (actionType == "download") {
      this.loading = true;
      let fileName = row.awsImageRef;
      this.awsS3DocumentServ.getByFileName(fileName).subscribe(res => {
        const file = res;
        const url = window.URL.createObjectURL(new Blob([res as BlobPart], { type: res.type }));
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;
        link.download = fileName;
        link.click();
        let systemUserDocumentDownload = new CreateUpdateSystemUserDocumentActionDTO();
        systemUserDocumentDownload.id = 0;
        systemUserDocumentDownload.createdAt = new Date();
        systemUserDocumentDownload.createdBy = this.loggedUserId;
        systemUserDocumentDownload.documentId = row.documentId;
        systemUserDocumentDownload.acceptedDowloadedDocument = true;
        this.apiUserActionDocumentServ.create(systemUserDocumentDownload).subscribe(res => {
          this.loading = false;
        }, error => {
          this.loading = false;
          console.log(error);
        });
      }, error => {
        console.log(error);
      });

    } else if (actionType == "approve") {
      const confirmDialog = this.dialog.open(ConfirmApproveDocumentMessageComponent, {
        data: {
          title: 'Confirm Document Approval',
          message: 'Are you sure, you want to approve document : ' + row.documentName
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.ngOnInit();
        }
      });

    } else {

    }
  }

}

export interface department {

  id: number;

  departmentName: string;

  departmentCode: string;

}

export interface documentdata {

  id: number;

  documentTypeId: number;

  documentTypeName: string;

  documentName: string;

  departmentName: string;

  departmentCode: string;

  departmentId: number;

  awsImageRef: string;

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  isActive: boolean;

}