import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { EquipmentCategoryData } from 'src/app/list-equipment-category/list-equipment-category.component';
import { EquipmentCategoryService } from 'src/services/equipment-category.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';

@Component({
  selector: 'app-add-edit-equipment-category',
  templateUrl: './add-edit-equipment-category.component.html',
  styleUrls: ['./add-edit-equipment-category.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditEquipmentCategoryComponent implements OnInit {

  pageTitle: string = "";
  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  constructor(private fb: FormBuilder, private apiEquipmentCategoryServ: EquipmentCategoryService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar,
    public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditEquipmentCategoryComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    if (this.data) {
      this.pageTitle = "Edit Category";
      this.form.patchValue(
        {
          equipmentCategoryDesc: this.data.equipmentCategoryDesc
        });
    } else {
      this.pageTitle = "Add Category";
    }
  }

  createForm() {
    this.form = this.fb.group({
      "equipmentCategoryDesc": [null, [Validators.required]]
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      var formData = this.form.value; 
      if (this.data) {
        formData.editedBy = this.loggedUserId;
        formData.editedAt = new Date();
        formData.id = this.data.id;
      } else {
        formData.id = 0;
        formData.createdBy = this.loggedUserId;
        formData.createdAt = new Date();
      } 
      this.apiEquipmentCategoryServ.create(formData).subscribe(data => {
        this.dialogRef.close({ refresh : true});
      });

    } else {
      if(btnName == "btnCancel"){
        this.dialogRef.close();
      }
    }
  }

}

