import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { JobCardTaskService } from 'src/services/job-card-task.service';
import { JobCardDelayReasonService } from 'src/services/job-card-delay-reason.service';
import { UserService } from 'src/services/user.service';
import { SystemUser } from 'src/models/system-user';
import { JobCardTaskDelayReason } from 'src/models/job-card-task-delay-reason';
import { CreateUpdateJobCardTask } from 'src/models/create-update-job-card-task'
import { DepartmentService } from 'src/services/department.service';
import { Department } from 'src/models/department';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { HourTimes } from 'src/models/hour-times';
import { Globals } from 'src/app/global-values/globals';
import { JobCardTaskActionService } from 'src/services/job-card-task-action.service';

@Component({
  selector: 'app-add-job-card-task',
  templateUrl: './add-job-card-task.component.html',
  styleUrls: ['./add-job-card-task.component.css']
})
export class AddJobCardTaskComponent implements OnInit {

  loggedUserId: number;
  loggedUserDepartmentId : number;
  edited : boolean  = false;
  isDisabled: boolean = true;
  btnName : string;
  form: FormGroup;
  dsDepartment : Department[];
  dsPerson : SystemUser[];
  dsJobCardTaskAction : any[] = [];
  dsTimes : any[];
  dsJobCardDelayReason : JobCardTaskDelayReason[];
  minDate: Date;

  constructor(private fb: FormBuilder, private apiJobCardTaskServ : JobCardTaskService,private apiDepartmentServ : DepartmentService,
    private apiPersonServ : UserService,private apiJobCardDelayReasonServ : JobCardDelayReasonService, private tokenStorage: TokenstorageService,
    private apiJobCardTaskActionServ : JobCardTaskActionService, private snackBar: MatSnackBar, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddJobCardTaskComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) { 
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
        this.loggedUserDepartmentId = user.departmentId;
      }
  }

  ngOnInit(): void {
    let date = new Date();
    this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.createForm();
    this.loadWorkDepartment();
    this.loadSystemPerson(this.loggedUserDepartmentId);
    this.loadJobCardDelayReason();
    this.loadJobCarTimes();
    this.loadJobCardActionTask();
  }

  createForm() {
    let defaultStartTime = new Date().toTimeString().slice(0,2) + ":00";
    var today = new Date();
    today.setHours(today.getHours() + 1);
    let defaultsEndTime = today.toTimeString().slice(0,2) + ":00";    
    this.form = this.fb.group({
      departmentId: [this.loggedUserDepartmentId, [Validators.required]],
      assigneeId: [null, [Validators.required]],
      startDate: [new Date(), [Validators.required]],
      startTime: [defaultStartTime, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
      endTime: [defaultsEndTime, [Validators.required]],
      actualStartDate: [null],
      actualStartTime: [null],
      actualEndDate: [null],
      actualEndTime: [null],
      description : [null, [Validators.required]],
      jobCardTaskActionId : [null, [Validators.required]]
    });
  }

  loadWorkDepartment() {
    this.apiDepartmentServ.getAll().subscribe(data =>{
      this.dsDepartment = data as Department[];
    });
  }

  loadJobCardActionTask() {
    this.apiJobCardTaskActionServ.getAll().subscribe(data => {
      this.dsJobCardTaskAction = data as any[];
    })
  }

  loadSystemPerson(departmenId : number) {
    this.apiPersonServ.getAllUserByDepartmentId(departmenId).subscribe(data => {
      this.dsPerson = data as SystemUser[];
    })
  }

  loadJobCarTimes() {
    let hourTimes = new HourTimes();
    this.dsTimes = hourTimes.getAllTimes();
  }

  loadJobCardDelayReason() {
    this.apiJobCardDelayReasonServ.getAll().subscribe(data => {
      this.dsJobCardDelayReason = data as JobCardTaskDelayReason[];
    })
  }
 

  onChangeDepartment(event) { 
    this.loadSystemPerson(event.value);
  }

  onSubmit(btnName) {
    if(btnName == "btnSave") {
      let dtoJobCardTask = new CreateUpdateJobCardTask();
      dtoJobCardTask.setId(0);
      dtoJobCardTask.setCreatedBy(this.loggedUserId);
      dtoJobCardTask.setCreatedAt(new Date());
      dtoJobCardTask.setDepartmentId(this.form.value.departmentId);
      dtoJobCardTask.setAssigneeId(this.form.value.assigneeId);
      dtoJobCardTask.setStartDate(this.form.value.startDate);
      dtoJobCardTask.setStartTime(this.form.value.startTime);
      dtoJobCardTask.setEndDate(this.form.value.endDate);
      dtoJobCardTask.setEndTime(this.form.value.endTime);
      dtoJobCardTask.setJobCardId(this.data.id);
      dtoJobCardTask.setDescription(this.form.value.description);
      let global = new Globals(); 
      dtoJobCardTask.setStatusId(global.jobCardStatusAwaitingId) ;    
      this.apiJobCardTaskServ.create(dtoJobCardTask).subscribe(data => {
        this.dialogRef.close({ refresh : true});      
      },error =>{
          this.snackBar.open("Error Message", error.message, {
            duration: 2000,
          });
      }); 

    }else{
      if(btnName == "btnCancel") {
        this.dialogRef.close({ refresh : true});     
      }
    }
  }

}
