import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatListOption, MatPaginator, MatSnackBar, MatSort, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { debug } from 'console';
import { Globals } from 'src/app/global-values/globals';
import { environment } from 'src/environments/environment';
import { CreateUpdateDepartmentAssignedDocumentDTO } from 'src/models/create-update-department-assigned-document-dto';
import { DepartmentAssignedDocumentService } from 'src/services/department-assigned-document.service';
import { DepartmentDocumentService } from 'src/services/department-document.service';
import { DepartmentService } from 'src/services/department.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-add-edit-document-access',
  templateUrl: './add-edit-document-access.component.html',
  styleUrls: ['./add-edit-document-access.component.css']
})
export class AddEditDocumentAccessComponent implements OnInit {

  loading = false;
  form: FormGroup;
  loggedUserId: number;
  pageTitle: string;
  btnName: string;
  dsDepartment : any[] = [];
  dsDepartmentDocumentAccess : any[] = [];

  constructor(private formBuilder: FormBuilder,private apiSystemUser : UserService, private apiDepartmentServ: DepartmentService,
    private apiUserDepartmentDocumentAccess : DepartmentAssignedDocumentService, private tokenStorage: TokenstorageService,
    private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditDocumentAccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data, private httpClient: HttpClient) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.loading = false;
    }
    if(this.data){
      this.pageTitle = "Edit Department Document Access";
    }else{
      this.pageTitle = "Add Department Document Access";
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.getData();
  }

  createForm() {
    this.form = this.formBuilder.group({
      "departmentList" : [null]
    });
  } 

  async getData() {
    const department = await this.loadDepartment() as any[]; 
    debugger;
    const departmentAccess = await this.loadDepartmentDocumentAccess(this.data.id)  as any[];
    for (var i = 0; i < department.length; i++) {
      let documentAccess = departmentAccess.filter(ob => ob.departmentId === department[i].id);     
      this.dsDepartment.push(
        {
          "id" : department[i].id,
          "departmentName" : department[i].departmentName,
          "documentId": documentAccess.length > 0 ? documentAccess[0].id : 0,
          "selected" : documentAccess.length == 0 ? false : true
       });
    }
  }

  loadDepartmentDocumentAccess(id : number) {
    debugger;
    return this.apiUserDepartmentDocumentAccess.getByDocumentId(id).toPromise();
  }

  loadDepartment() {
    return this.apiDepartmentServ.getAll().toPromise();
  }

  onChangeDocumentAccess(options: MatListOption[]) {
    console.log(options.map(o => o.value));
    this.dsDepartmentDocumentAccess = options.map(o => o.value)
  }

  deleteDepartmentDocumentAccess() : any {  
    if(this.dsDepartmentDocumentAccess && this.dsDepartmentDocumentAccess.length > 0) {
      this.dsDepartment.forEach(element => { 
        if(element.documentId == 0) return;  
        return this.apiUserDepartmentDocumentAccess.delete(element.documentId).toPromise();     
      }); 
    }  
  }

  async updateDepartmentDocumentAccess() {
    const deleteDocumentAccess = await this.deleteDepartmentDocumentAccess();
    this.saveDepartmentDocumentAccess();
  }

  saveDepartmentDocumentAccess() {
    let role = new Globals();
    this.dsDepartmentDocumentAccess.forEach(element => {      
      this.apiSystemUser.getAllUserByDepartmentAndRoleId(element,role.supervisorRoleId).subscribe(resData => {
        let dsUser = resData as [];
        if(dsUser.length > 0) {
          let departmentDocAccess = new CreateUpdateDepartmentAssignedDocumentDTO();
          departmentDocAccess.id = 0;
          departmentDocAccess.createdAt = new Date();
          departmentDocAccess.createdBy = this.loggedUserId;
          departmentDocAccess.requiredSupervisorApproval = true;
          departmentDocAccess.supervisorId = resData[0]["id"];
          departmentDocAccess.departmentId = resData[0]["departmentId"];
          departmentDocAccess.documentId = this.data.id;
          this.apiUserDepartmentDocumentAccess.create(departmentDocAccess).subscribe(res => {
          },error =>{
            console.log(error);
          });
        }
      },error=>{
        console.log(error);  
      });       
    });
  }

  onSubmit(buttonType) {
    if (buttonType == "btnSave") {
      this.updateDepartmentDocumentAccess();
      this.dialogRef.close({ refresh : true});  
    } else {
      if (buttonType == "btnCancel") {
        this.dialogRef.close({ refresh: true });
      }
    }
  }

}
