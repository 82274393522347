<div class="container-fluid" style="margin-top: 10px;">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Equipment Repairs Symptoms Link</mat-card-title>
        </mat-card-header>
        <br>
        <mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
        </mat-progress-bar>
        <form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
            <mat-card-content>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="equipmentCategoryId" matInput placeholder="Equipment Category" (selectionChange)="onSelectEquipmentCategory($event)"  required>
                                <mat-option *ngFor="let item of dsEquipmentCategory" [value]="item.id">
                                    {{item.equipmentCategoryDesc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>                   
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="repairType" multiple  matInput placeholder="Repair Type" required>
                                <mat-option *ngFor="let item of dsRepairType" [value]="item.id">
                                    {{item.repairTypeDesc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>                   
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="rootCause" multiple  matInput placeholder="Root Cause" required>
                                <mat-option *ngFor="let item of dsRootCause" [value]="item.id">
                                    {{item.rootCauseDesc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>                   
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="failureType" multiple  matInput placeholder="Failure Type" required>
                                <mat-option *ngFor="let item of dsFailureType" [value]="item.id">
                                    {{item.failureTypeDesc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>                   
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field fxFlex>
                            <mat-select formControlName="component" multiple  matInput placeholder="Component" required>
                                <mat-option *ngFor="let item of dsComponent" [value]="item.id">
                                    {{item.equipmentComponentDesc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>                   
                </div>
                <span class="spacer"></span>
                <div class="row">
                    <div class="col-md-12" align="end">
                        <button mat-raised-button style="margin-right: 10px;" color="primary" (click)="onSubmit('btnCancel')" [disabled]="!form.invalid">Cancel</button>
                        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
                    </div>
                </div>
            </mat-card-content>
        </form>
    </mat-card>
</div>