import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { FunctionLocationService } from 'src/services/function-location.service';
import { AddFunctionLocationComponent } from '../dialogs/add-function-location/add-function-location.component';
import { EditSupFunctionalLocationComponent } from '../dialogs/edit-sup-functional-location/edit-sup-functional-location.component';

@Component({
  selector: 'app-list-function-location',
  templateUrl: './list-function-location.component.html',
  styleUrls: ['./list-function-location.component.css']
})
export class ListFunctionLocationComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'functionalLocName','description', 'supFunctionalLocName',  'equipmentSerial', 'equipmentDescription','createdAt'];

  public dsFuncLocation = new MatTableDataSource<functionalLocationData>();

  constructor(private router: Router, private apiFunLocationServ: FunctionLocationService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsFuncLocation.sort = this.sort;
    this.dsFuncLocation.paginator = this.paginator;
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dsFuncLocation.filter = filterValue.trim().toLowerCase();

    if (this.dsFuncLocation.paginator) {
      this.dsFuncLocation.paginator.firstPage();
    }

  }

  refreshData() {
    this.loading = true;
    this.apiFunLocationServ.getAll().subscribe(data => {
      this.dsFuncLocation.data = data as functionalLocationData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  
  onCreate() {
    const dialogRef = this.dialog.open(AddFunctionLocationComponent, {
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });  
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditSupFunctionalLocationComponent, {
      data: row,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });
  }
}

export interface functionalLocationData {

  id: number;

  functionalLocName: string;

  description: string;

  gpsLocation : string,

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  isActive: boolean;

  supFunctionalLocationId : number;

  supFunctionalLocName : String;

  equipmentId : number;

  equipmentSerial : string;

  equipmentDescription : string;

}
