import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EntityService } from 'src/services/entity.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';

@Component({
  selector: 'app-edit-entity',
  templateUrl: './edit-entity.component.html',
  styleUrls: ['./edit-entity.component.css']
})
export class EditEntityComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string;

  constructor(private fb: FormBuilder,private apiEntityServ: EntityService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditEntityComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit() {
    this.createForm();
    this.form.patchValue(
    {
      systemEntityName: this.data.systemEntityName
    });
  }

  createForm() {
    this.form = this.fb.group({
      "systemEntityName": [null, [Validators.required]]
    });
  }

  onSubmit(btnName){
    if(btnName =="btnSave") {      
      var formData = this.form.value;
      if(formData) {
        var finalFormData = {
          id : this.data.id,
          systemEntityName : formData.systemEntityName,          
          editedBy : this.loggedUserId,
          editedAt : new Date(),  
        } 
        this.apiEntityServ.create(finalFormData).subscribe(data => {
          this.dialogRef.close({ refresh : true});  
        });
    }else{
      this.snackBar.open("Error Message","Please contact administator error happened.", {
        duration: 2000,
      });
    }
    }else{
      if(btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}
