import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { CompanyService } from 'src/services/company.service';
import { EquipmentTypeService } from 'src/services/equipment-type.service';
import { EquipmentService } from 'src/services/equipment.service';

@Component({
  selector: 'app-add-equipment',
  templateUrl: './add-equipment.component.html',
  styleUrls: ['./add-equipment.component.css']
})
export class AddEquipmentComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName : string;
  dsCompany: any;
  dsEquipmentType : any;

  constructor(private fb: FormBuilder, private tokenStorage: TokenstorageService, private apiCompanyServ : CompanyService, private apiEquipmentTypeServ : EquipmentTypeService,
     private apiEquipmentServ : EquipmentService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddEquipmentComponent> , @Inject(MAT_DIALOG_DATA) public data: Data) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
      }
  }

  ngOnInit(): void {
    this. createForm();
    this.loadCompany();
    this.loadEquipmentType();
  }

  createForm() {
    this.form = this.fb.group({
      description: [null, [Validators.required]],
      model: [null, [Validators.required]],
      serialNumber: [null, Validators.required],
      equipmentTypeId: [null, Validators.required],
      companyId: [null, Validators.required],
      barcode : null     
    });
  }

  loadCompany() {
    this.apiCompanyServ.getAll().subscribe(data => {
      this.dsCompany = data
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadEquipmentType() {
    this.apiEquipmentTypeServ.getAll().subscribe(data =>{
      this.dsEquipmentType = data;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });

  }

  onSubmit(btnName) {
    if(btnName =="btnSave") { 
        var formData = this.form.value;
        var formEuipmentData = {
        id : 0,
        model : formData.model,
        serialNumber : formData.serialNumber,
        description : formData.description,
        companyId : formData.companyId,
        equipmentTypeId : formData.equipmentTypeId,
        barcode : formData.barcode,
        createdBy :this.loggedUserId,
        createdAt : new Date()
      }
      this.apiEquipmentServ.create(formEuipmentData).subscribe(data => {
        this.dialogRef.close();
      }, error => {
        this.snackBar.open("Error Message", error.message, {
          duration: 2000,
        });
      });

    }else{
      this.dialogRef.close();
    }

  }

}
