export class CreateUpdateDepartmentAssignedDocumentDTO {

    id : number;

	documentId : number;
	
	createdBy : number;
	
	createdAt : Date;
	
	editedBy : number;
	
	editedAt : Date;
	
	departmentId : number;
	
	requiredSupervisorApproval : boolean;
	
	supervisorId : number;
	
    supervisorApprovedDate : Date;
    
}
