<h1 mat-dialog-title>
    Add Functional Location
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>       
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Functional Location Name</mat-label>
                    <input matInput formControlName="functionalLocName" required>               
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Functional Location Desc</mat-label>
                    <input matInput formControlName="description" required>                
                </mat-form-field>
            </div>            
        </div>  
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="equipmentId" matInput placeholder="Equipment">
                        <mat-option *ngFor="let item of dsEquipment" [value]="item.id">
                            {{item.model}} - {{item.serialNumber}} - {{item.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="supFunctionalLocationId" matInput placeholder="Sup Functional Location">
                        <mat-option *ngFor="let item of dsSupFunctionalLocation" [value]="item.id">
                            {{item.locationName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>    
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')" [disabled]="!form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>



