import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AwsS3DocumentService {

  API_URL: string = environment.serverUrl + "/awsS3Upload";

  constructor(private http: HttpClient) { }

  getByFileName(keyname: string) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      responseType: 'blob',
      observe: 'response'
    });

    return this
      .http
      .get(`${this.API_URL}/download/${keyname}`,
        {
          headers: headers, responseType: 'blob'
        });
  }

  deleteFile(keyname: string): Observable<object> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',  
       observe: 'response'
    });

    return this
      .http
      .delete(`${this.API_URL}/delete/${keyname}`,
        {
          headers: headers, responseType: 'blob'
        });
  }

}
