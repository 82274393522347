import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class JobCardsStatsService {

  API_URL: string = environment.serverUrl + "/jobCardstats";

  constructor(private http : HttpClient) { }

  getMonthlyDepartmentJobCardStats(department : number, month : number ,year : number) {   
    return this
      .http
      .get(`${this.API_URL}/getMonthlyDepartmentJobCardStats/${department}/${month}/${year}`);    
  }

  getYearDepartmentJobCardStats(department : number,year : number) {   
    return this
      .http
      .get(`${this.API_URL}/getYearDepartmentJobCardStats/${department}/${year}`);    
  }

}
