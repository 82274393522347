import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { ThakaduEquipmentService } from 'src/services/thakadu-equipment.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { equipmentThakaduData } from 'src/app/list-thakadu-equipment/list-thakadu-equipment.component';
import { EquipmentCategoryService } from 'src/services/equipment-category.service';

@Component({
  selector: 'app-add-edit-thakadu-equipment',
  templateUrl: './add-edit-thakadu-equipment.component.html',
  styleUrls: ['./add-edit-thakadu-equipment.component.css']
})
export class AddEditThakaduEquipmentComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  //dsCompany: any;
  //dsEquipment: any;
  dsEquipmentCategory : any;
  pageTitle: string;

  constructor(private fb: FormBuilder, private apiEquipment: ThakaduEquipmentService, private tokenStorage: TokenstorageService,
    private apiEquipmentCategoryServ : EquipmentCategoryService, private snackBar: MatSnackBar, public dialog: MatDialog, 
    public dialogRef: MatDialogRef<AddEditThakaduEquipmentComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
      }
      if(data == null){
        this.pageTitle = "Add Equipment";
      }else{
        this.pageTitle = "Edit Equipment";
      }
     }

  ngOnInit(): void {
    this.createForm();
    this.loadEquipmentCategory();
    if(this.data) {
      this.form.patchValue(
        {     
          areaCode: this.data.areaCode,
          instrumentCode: this.data.instrumentCode,
          sequenceEquipNo: this.data.sequenceEquipNo,
          sequenceInstrumentNo: this.data.sequenceInstrumentNo,
          instrumentTag: this.data.instrumentTag,
          rioPanelNo: this.data.rioPanelNo,
          equipmentCategoryId : this.data.equipmentCategoryId      
        });
    }   
  }

  /*loadAllEquipment() {
    this.apiEquipment.getAll().subscribe(res => {
      this.dsEquipment = res as equipmentThakaduData
    }, error => {
        this.snackBar.open("Error Message", error.message, {
          duration: 2000,
        });
    });
  }*/

  loadEquipmentCategory() {
    this.apiEquipmentCategoryServ.getAll().subscribe(res => {
      this.dsEquipmentCategory = res as any[];
      debugger;
    },error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    })
  }

  createForm() {
    this.form = this.fb.group({
      areaCode: [null, [Validators.required]],
      instrumentCode: [null, [Validators.required]],
      sequenceEquipNo: [null, Validators.required],
      sequenceInstrumentNo: [null, Validators.required],
      instrumentTag: [null, Validators.required],
      rioPanelNo: [null, Validators.required],
      equipmentCategoryId : [null, Validators.required]
    });
  }

  onChangeInstrumentTagEvent(event) {
    let tagSplit = event.split("-");
    if(tagSplit && tagSplit.length == 3) {
      this.form.controls.areaCode.setValue(tagSplit[0]);
      this.form.controls.instrumentCode.setValue(tagSplit[1]);
      this.form.controls.sequenceEquipNo.setValue(tagSplit[2].substr(0,3));
      this.form.controls.instrumentTag.setValue(event);
      this.form.controls.sequenceInstrumentNo.setValue(tagSplit[2].substr(3));  
      this.form.controls.rioPanelNo.setValue(tagSplit[2].substr(3));     
    } 
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      let formEquipmentData = null;
      var formData = this.form.value;  
      if(this.data != null)
      {
        formEquipmentData = {
          id: this.data.id,  
          areaCode: formData.areaCode,
          instrumentCode: formData.instrumentCode,
          sequenceEquipNo: formData.sequenceEquipNo,
          sequenceInstrumentNo: formData.sequenceInstrumentNo,
          instrumentTag: formData.instrumentTag,
          rioPanelNo: formData.rioPanelNo,         
          editedBy : this.loggedUserId,
          editedAt : new Date(),
          equipmentCategoryId : formData.equipmentCategoryId,
        }

      }else{
        formEquipmentData = {
          id: 0,
          areaCode: formData.areaCode,
          instrumentCode: formData.instrumentCode,
          sequenceEquipNo: formData.sequenceEquipNo,
          sequenceInstrumentNo: formData.sequenceInstrumentNo,
          instrumentTag: formData.instrumentTag,
          rioPanelNo: formData.rioPanelNo,
          createdBy: this.loggedUserId,
          createdAt: new Date(),
          equipmentCategoryId : formData.equipmentCategoryId,
        }
      }
      this.apiEquipment.create(formEquipmentData).subscribe(data => {
        this.dialogRef.close({ refresh : true});  
      }, error => {
        this.snackBar.open("Error Message", error.message, {
          duration: 2000,
        });
      });

    } else {
      if(btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}

