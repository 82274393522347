import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debug } from 'console';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DepartmentAssignedDocumentService {
  API_URL: string = environment.serverUrl + "/departmentAssignedDocument";

  constructor(private http : HttpClient) { }

  getAll(): Observable<any> {
    return this
      .http
      .get(`${this.API_URL}`);
  }

  getDocumentByDocumentIdDepartmentIdSupervisorId(documentId : number,departmentId : number,supervisorId : number ) {
    return this
      .http
      .get(`${this.API_URL}/getDocumentByDocumentIdDepartmentIdSupervisorId/${documentId}/${departmentId}/${supervisorId}`);
  } 

  getById(id : number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  } 

  getDepartmentId(id : number) {
    return this
      .http
      .get(`${this.API_URL}/getDepartmentId/${id}`);
  }

  getByDocumentId(id : number) {
    return this
      .http
      .get(`${this.API_URL}/getByDocumentId/${id}`);
  }

  create(debtor: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, debtor);
  }

  delete(id : number) {
    return this
      .http
      .delete(`${this.API_URL}/${id}`);
  }

  deleteByDocumentId(id : number) {
    return this
      .http
      .delete(`${this.API_URL}/deleteByDocumentId/${id}`);
  }
  
}