import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoleService } from 'src/services/role.service';
import { TitleService } from 'src/services/title.service';
import { EntityService } from 'src/services/entity.service';
import { UserService } from 'src/services/user.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { AddUserComponent } from '../add-user/add-user.component';
import { Data } from '@angular/router';
import { DepartmentService } from 'src/services/department.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName : string;
  dsRoles: any;
  dsTitle: any;
  dsEntity : any;
  dsDepartment : any;

  constructor(private fb: FormBuilder, private apiRoleServ: RoleService, private apiTitleServ: TitleService, private apiEntityServ : EntityService, private apiUserServ : UserService, private tokenStorage: TokenstorageService, 
    private departmentServ : DepartmentService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddUserComponent> , @Inject(MAT_DIALOG_DATA) public data: Data) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
      }
  }

  ngOnInit() {
    this.createForm();
    this.loadRoles();
    this.loadTitles();
    this.loadSystemEntity();
    this.loadSystemDepartment();   
    this.form.patchValue(
      {
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        idNumber: this.data.idNumber,
        employeeNumber: this.data.employeeNumber,
        logUserName: this.data.logUserName,
        systemRoleId: this.data.roleId,
        titleName: this.data.titleName,
        logUserPassword : "password01",
        systemEntityId : this.data.entityId,
        upDatePassword : false, 
        departmentId : this.data.departmentId,       
      });
  }

  createForm() {
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      idNumber: [null, Validators.required],
      employeeNumber: [null, Validators.required],
      logUserName: [null, Validators.required],
      systemRoleId: [null, Validators.required],
      titleName: [null, Validators.required],
      logUserPassword: [null, Validators.required],
      systemEntityId : [null, Validators.required],
      upDatePassword : [true,Validators.required],
      departmentId : [null,Validators.required]
    });
  }

  loadRoles() {
    this.apiRoleServ.getAll().subscribe(data => {
      this.dsRoles = data
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadTitles() {  
    this.apiTitleServ.getAll().subscribe(data => {
      this.dsTitle = data
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadSystemEntity() {
    this.apiEntityServ.getAll().subscribe(data => {
      this.dsEntity = data;     
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadSystemDepartment() {
    this.departmentServ.getAll().subscribe(data => {
      this.dsDepartment = data;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  onSubmit(btnName) {
    if(btnName =="btnSave") {      
      var formData = this.form.value;
      if(formData) {
        var finalFormData = {
          id : this.data.id,
          firstName : formData.firstName,
          lastName : formData.lastName,
          titleName : formData.titleName,
          idNumber : formData.idNumber,
          logUserName : formData.logUserName,
          logUserPassword : formData.logUserPassword,
          upDatePassword : formData.upDatePassword,
          employeeNumber : formData.employeeNumber,
          systemRoleId : formData.systemRoleId,
          systemEntityId : formData.systemEntityId,
          editedBy : this.loggedUserId,
          departmentId : formData.departmentId,
          editedAt : new Date(),  
        };
        this.apiUserServ.create(finalFormData).subscribe(data => {
          this.dialogRef.close({ refresh : true});  
        });
    }else{
      this.snackBar.open("Error Message","Please contact administator error happened.", {
        duration: 2000,
      });
    }
    }else{
      if(btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }
}
