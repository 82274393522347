import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { EquipmentComponentService } from 'src/services/equipment-component.service';
import { AddEditEquipmentCategoryComponent } from '../dialogs/add-edit-equipment-category/add-edit-equipment-category.component';
import { AddEditEquipmentComponentComponent } from '../dialogs/add-edit-equipment-component/add-edit-equipment-component.component';

@Component({
  selector: 'app-list-equipment-component',
  templateUrl: './list-equipment-component.component.html',
  styleUrls: ['./list-equipment-component.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListEquipmentComponentComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'equipmentComponentDesc','createdAt'];

  public dsEquipmentComponent = new MatTableDataSource<EquipmentComponentData>();

  constructor(private router: Router, private apiEquipmentComponentServ: EquipmentComponentService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsEquipmentComponent.sort = this.sort;
    this.dsEquipmentComponent.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEquipmentComponent.filter = filterValue.trim().toLowerCase();

    if (this.dsEquipmentComponent.paginator) {
      this.dsEquipmentComponent.paginator.firstPage();
    }
  }

  refreshData() {
    this.loading = true;
    this.apiEquipmentComponentServ.getAll().subscribe(data => {  
      this.dsEquipmentComponent.data = data as any[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditEquipmentComponentComponent, {
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if (result) { 
        this.refreshData();
      }
    }); 
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditEquipmentComponentComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {     
        this.refreshData();
      }
    });
  }

}

export interface EquipmentComponentData {

  id : number;

  equipmentComponentDesc : string;

  createdBy : number;
	
	createdAt : Date;
	
	editedBy : number;
	
	editedAt : Date;

}
