import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { RoleService } from 'src/services/role.service';
import { AddRoleComponent } from '../dialogs/add-role/add-role.component';
import { EditRoleComponent } from '../dialogs/edit-role/edit-role.component';

@Component({
  selector: 'app-list-roles',
  templateUrl: './list-roles.component.html',
  styleUrls: ['./list-roles.component.css']
})
export class ListRolesComponent implements OnInit {

  public displayedColumns: string[] = ['id', 'roleName', 'createdAt'];

  public dsRole = new MatTableDataSource<roleData>();

  constructor(private router: Router, private apiRoleServ: RoleService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsRole.sort = this.sort;
    this.dsRole.paginator = this.paginator;
  }

  refreshData() {
    this.apiRoleServ.getAll().subscribe(data => {
      this.dsRole.data = data as roleData[];
    }, error => {
      if (error.status == 404) {
        console.log("No Record Found");
      } else {
        console.log(error);
      }
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddRoleComponent, {
      data: { roleData : ''},
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    }); 
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsRole.filter = filterValue.trim().toLowerCase();

    if (this.dsRole.paginator) {
      this.dsRole.paginator.firstPage();
    }
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditRoleComponent, {
      data : row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });     
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    }); 
  }
}

export interface roleData {
 id : number;
 roleName : string; 
 createdBy : number;
 createdAt : Date;
 editedBy: number;
 editedAt : Date;
 isActive :boolean; 
}
