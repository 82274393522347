import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { EquipmentFailureTypeService } from 'src/services/equipment-failure-type.service';
import { AddEditEquipmentFailureTypeComponent } from '../dialogs/add-edit-equipment-failure-type/add-edit-equipment-failure-type.component';

@Component({
  selector: 'app-list-equipment-failure-type',
  templateUrl: './list-equipment-failure-type.component.html',
  styleUrls: ['./list-equipment-failure-type.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListEquipmentFailureTypeComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'failureTypeDesc', 'createdAt'];

  public dsEquipmentFailureType = new MatTableDataSource<EquipmentComponentData>();

  constructor(private router: Router, private apiEquipmentFailureTypeServ: EquipmentFailureTypeService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsEquipmentFailureType.sort = this.sort;
    this.dsEquipmentFailureType.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEquipmentFailureType.filter = filterValue.trim().toLowerCase();

    if (this.dsEquipmentFailureType.paginator) {
      this.dsEquipmentFailureType.paginator.firstPage();
    }
  }

  refreshData() {
    this.loading = true;
    this.apiEquipmentFailureTypeServ.getAll().subscribe(data => {
      this.dsEquipmentFailureType.data = data as any[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditEquipmentFailureTypeComponent, {
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditEquipmentFailureTypeComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

}

export interface EquipmentComponentData {

  id: number;

  equipmentComponentDesc: string;

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;
}

