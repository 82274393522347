<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    {{pageTitle}}
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>         
        <div class="row" style="padding-left: 30px; border: 0 !important; margin-bottom: 10px;">          
            <input  #inputUploadFile type="file" style="border: 0px;"
            formControlName="fileName" (change)="onFileChanged($event)">                    
        </div>       
        <div class="row">
            <div class="col-md-12">
                <mat-form-field fxFlex>
                    <mat-select formControlName="documentTypeId" matInput placeholder="Document Type">
                        <mat-option *ngFor="let item of dsDocumentType" [value]="item.id">
                            Department [{{item.departmentName}}] - Document [{{item.documentTypeName}}]
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>        
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')" [disabled]="!form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>
