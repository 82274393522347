<h1 mat-dialog-title>
    Add Equipment
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" required>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Model</mat-label>
                    <input matInput formControlName="model" required>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Serial Number</mat-label>
                    <input matInput formControlName="serialNumber" required>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-select formControlName="equipmentTypeId" matInput placeholder="Equipment Type">
                        <mat-option *ngFor="let item of dsEquipmentType" [value]="item.id">
                            {{item.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-select formControlName="companyId" matInput placeholder="Company">
                        <mat-option *ngFor="let item of dsCompany" [value]="item.id">
                            {{item.companyName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Barcode</mat-label>
                    <input matInput formControlName="barcode">
                </mat-form-field>
            </div>
        </div>
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')"
            [disabled]="!form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>