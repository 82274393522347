import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TokenstorageService } from 'src/services/tokenstorage.service';

@Component({
  selector: 'app-list-reports',
  templateUrl: './list-reports.component.html',
  styleUrls: ['./list-reports.component.css']
})
export class ListReportsComponent implements OnInit {

  form: FormGroup;
  loading = false;
  loggedUserId: number;
  btnName: string;

  constructor(private fb: FormBuilder,private router: Router, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      reportName: ["", Validators.required]
    });
  }

  reportOnChanged(events){
    this.router.navigate(["ReportDepartByDateRange"],{ queryParams: { rptName: events.value } });
  }

  dsReports = [
    {
      value: "All Deparments Created Job Card",
      id: "All_Deparments_Created_Job_Card"
    },
    {
      value: "All Deparments Completed Job Card",
      id: "All_Deparments_Completed_Job_Card"
    },
    {
      value: "Deparment Created Job Card",
      id: "Deparment_Created_Job_Card"
    },
    {
      value: "Deparment Completed Job Card",
      id: "Deparment_Completed_Job_Card"
    }

  ]

}
