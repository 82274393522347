import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USERMENU_KEY = "auth_user_menu;"

@Injectable({
  providedIn: 'root'
})
export class TokenstorageService {

  constructor(private router:Router) { }

  signOut() {
    window.sessionStorage.clear();
    this.router.navigate(['']);
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    var token = sessionStorage.getItem(TOKEN_KEY);
    return token;
  }

  public saveMenu(menu : string) {
    window.sessionStorage.removeItem(USERMENU_KEY);
    window.sessionStorage.setItem(USERMENU_KEY, menu);
  }

  public getMenu() {
    var menu = sessionStorage.getItem(USERMENU_KEY);
    return menu;
  }

  public isUserLoggedIn() {
    var token = sessionStorage.getItem(TOKEN_KEY); 
    return !(token === null)    
  }
  
  public saveUser(user) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser() {    
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
}
