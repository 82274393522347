import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/services/authentication.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  loading = false;
  currentYear :  string  = String(new Date().getFullYear());

  constructor(private fb: FormBuilder, private authService: AuthenticationService, private tokenStorage: TokenstorageService, private router: Router,
    private route: ActivatedRoute, private snackBar: MatSnackBar) { }

  ngOnInit() {
    
    this.errorMessage = "";
    this.form = this.fb.group({
      username: [null, [Validators.required]],
      userPassword: [null, [Validators.required]]
    });
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.router.navigate(['/Home']);
    }
  }

  get f() { return this.form.controls; }

  onSubmit() {
    /*this.snackBar.open("Error Message", "Please Contact Administrator.License Module Expired", {
      duration: 4000,
    });
    return;*/
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.isLoggedIn = false;
    this.errorMessage = "";
    var userName = this.form.value.username;
    var userPassword = this.form.value.userPassword;
    if (userName && userPassword) {
      this.loading = true;
      this.authService.authenticate(userName, userPassword).subscribe(
        data => {
          this.loading = false;
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.reloadPage();
        },
        err => {
          this.loading = false;
          this.errorMessage = "The username and password were not recognised";
          this.isLoginFailed = true;
        }
      );
    }
  }

  reloadPage() {
    window.location.reload();
  }

}
