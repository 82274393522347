import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { InterLockByPassStatusService } from 'src/services/inter-lock-by-pass-status.service';
import { InterLockService } from 'src/services/inter-lock.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { AddEditInterLockComponent } from '../dialogs/add-edit-inter-lock/add-edit-inter-lock.component';
import { Globals } from '../global-values/globals';
import { entitydata } from '../list-entity/list-entity.component';

@Component({
  selector: 'app-list-inter-locks',
  templateUrl: './list-inter-locks.component.html',
  styleUrls: ['./list-inter-locks.component.css']
})
export class ListInterLocksComponent implements OnInit {

  form: FormGroup;
  loading = false;
  dsStatus: any[] = [];
  statusId: number = 1;
  loggedUserId: number;
  loggedUserDepartment: number;
  loggedUserRoleName: string;

  public displayedColumns: string[] = ['id', 'departmentName', 'createdName', 'departmentToName', 'tagName', 'startDate', 'endDate', 'statusName', 'stateName', 'createdAt'];

  public dsInterlock = new MatTableDataSource<interLockdata>();

  constructor(private fb: FormBuilder, private router: Router, private apiInterlockServ: InterLockService, private apiInterLockStatusServ: InterLockByPassStatusService,
    private tokenStorage: TokenstorageService, private dialog: MatDialog,
    private snackBar: MatSnackBar) {
    var user = this.tokenStorage.getUser();
    let global = new Globals();
    if (user) {
      this.loggedUserId = user.id;
      this.loggedUserDepartment = user.departmentId;
      this.loggedUserRoleName = user.roleName;
      if (this.loggedUserDepartment == global.processDepartmentId) {
        this.statusId = global.interLockStatusWaitingId;
      } else if (this.loggedUserDepartment == global.measurementControlDepartmentId) {
        if (this.loggedUserRoleName == global.technicianRoleName) {
          this.statusId = global.interLockStatusInprogressId;
        } else {
          this.statusId = global.interLockStatusApprovedId;
        }
      } else {
        this.statusId = global.interLockStatusApprovedId;
      }
    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.refreshData();
    this.loadStatus();
    this.createForm();
  }

  ngAfterViewInit(): void {
    this.dsInterlock.sort = this.sort;
    this.dsInterlock.paginator = this.paginator;
  }

  createForm() {
    this.form = this.fb.group({
      statusId: this.statusId
    });
  }

  loadStatus() {
    let ds: any[] = [];
    this.apiInterLockStatusServ.getAll().subscribe(data => {
      this.dsStatus = data as [];
      ds.length = 0;
      ds.push({ id: -1, statusName: "All" });
      for (let i = 0; i < this.dsStatus.length; i++) {
        ds.push({ id: this.dsStatus[i].id, statusName: this.dsStatus[i].statusName });
      }
      this.dsStatus = ds;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    });
  }

  refreshData() {
    this.apiInterlockServ.getByStatus(this.statusId).subscribe(data => {
      this.dsInterlock.data = data as interLockdata[];
    }, error => {
      if (error.status == 404) {
        console.log("No Record Found");
      } else {
        console.log(error);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsInterlock.filter = filterValue.trim().toLowerCase();

    if (this.dsInterlock.paginator) {
      this.dsInterlock.paginator.firstPage();
    }
  }

  onStatus(event) {
    this.statusId = event.value;
    this.refreshData();
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditInterLockComponent, {
      data: null,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditInterLockComponent, {
      data: row,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

}

export interface interLockdata {
  id: number;
  departmentId: number;
  departmentName: string,
  createdBy: number;
  createdName: string;
  createdAt: Date;
  editedBy: number;
  editedAt: Date;
  departmentIdTo: number;
  departmentToName: string;
  tagId: number;
  tagName: string;
  bypassReason: string;
  bypassReasonAssociation: string;
  isActive: boolean;
  statusDate: Date;
  statusId: number;
  statusName: string;
  startDate: Date;
  endDate: Date;
  stateId: number;
  stateName: string;
}
