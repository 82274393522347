<div class="container-fluid" style="margin-top: 10px;">
    <form fxLayout="column" [formGroup]="form">
    <mat-card>
        <mat-card-header>
            <mat-card-title>List Of Job Cards</mat-card-title><span class="example-spacer"></span>
            <button mat-raised-button color="primary" (click)="refreshData()">Refresh</button><div class="spaceBetweenButtons"></div><button mat-raised-button color="primary"
                (click)="onCreate()">Add</button>
        </mat-card-header>
        <br>
        <mat-card-content>
            <div class="row">
                <div class="col-md-2">
                    <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Enter Search">
                    </mat-form-field>
                </div>

                <div class="col-md-2">
                    <mat-form-field fxFlex>
                        <mat-select formControlName="departmentFrom" matInput placeholder="Department From" (selectionChange)="onDepartmentFrom($event)">
                            <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                                {{item.departmentName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-2">
                    <mat-form-field fxFlex>
                        <mat-select formControlName="departmentTo" matInput placeholder="Department To" (selectionChange)="onDepartmentTO($event)">
                            <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                                {{item.departmentName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>            

                <div class="col-md-2">
                    <mat-form-field>
                        <mat-label>Date From</mat-label>
                        <input matInput [matDatepicker]="dateFrom" formControlName="dateFrom" required (dateChange)="onStartDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                        <mat-datepicker #dateFrom></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field>
                        <mat-label>Date To</mat-label>
                        <input matInput [matDatepicker]="dateTO" formControlName="dateTO" required (dateChange)="onEndDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="dateTO"></mat-datepicker-toggle>
                        <mat-datepicker #dateTO></mat-datepicker>
                    </mat-form-field>
                </div>  
        
                <div class="col-md-2">
                    <mat-form-field fxFlex>
                        <mat-select formControlName="statusId" matInput placeholder="Status" (selectionChange)="onStatus($event)">
                            <mat-option *ngFor="let item of dsStatus" [value]="item.id">
                                {{item.statusName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table mat-table [dataSource]="dsJobCards" matSort class="mat-elevation-z8">

                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id-Track No </th>
                            <td mat-cell *matCellDef="let element">TR-{{element.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                            <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="jobCardTypeDesc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Card Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.jobCardTypeDesc}} </td>
                        </ng-container>

                        <ng-container matColumnDef="tagName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.tagName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="departmentCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept From </th>
                            <td mat-cell *matCellDef="let element"> {{element.departmentCode}} </td>
                        </ng-container>

                        <ng-container matColumnDef="createdByName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                            <td mat-cell *matCellDef="let element"> {{element.createdByName}} </td>
                        </ng-container>


                        <ng-container matColumnDef="departmentToCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dept TO </th>
                            <td mat-cell *matCellDef="let element"> {{element.departmentToCode}} </td>
                        </ng-container>

                        <ng-container matColumnDef="reqStartDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Req Start Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.reqStartDate | date : 'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="reqStartTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Req Start Time </th>
                            <td mat-cell *matCellDef="let element"> {{element.reqStartTime}} </td>
                        </ng-container>

                        <ng-container matColumnDef="reqEndDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Req End Date</th>
                            <td mat-cell *matCellDef="let element"> {{element.reqEndDate | date : 'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="reqEndTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Req End Time</th>
                            <td mat-cell *matCellDef="let element"> {{element.reqEndTime}} </td>
                        </ng-container>

                        <ng-container matColumnDef="completedOn">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Completed Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.completedOn | date : 'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="orderNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Number </th>
                            <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
                        </ng-container>

                        <ng-container matColumnDef="statusName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEdit(row)"></tr>
                    </table>
                </div>
            </div>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40, 50, 60 ,70, 80 ,90 ,100]">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
    </form>
</div>
