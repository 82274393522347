import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, Data } from '@angular/router';
import { JobcardTypeService } from 'src/services/jobcard-type.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-add-job-car-type',
  templateUrl: './add-job-car-type.component.html',
  styleUrls: ['./add-job-car-type.component.css']
})
export class AddJobCarTypeComponent implements OnInit {

  loading: boolean = true;
  form: FormGroup;
  btnName: string;
  dsCompanyType: any;
  loggedUserId: number;

  constructor(private fb: FormBuilder, private router: Router, private apiJobcardTypeServ: JobcardTypeService, private tokenStorage: TokenstorageService, private dialog: MatDialog, private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddJobCarTypeComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      description: [null, Validators.required]
    });
  }

  onSubmit(btnName) {

    if (btnName == "btnSave") {
      var formData = this.form.value;
      formData.id = 0;
      formData.createdBy = this.loggedUserId;
      formData.createdAt = new Date();
      this.apiJobcardTypeServ.create(formData).subscribe(data =>{
        this.dialogRef.close({ refresh : true});   
      },error => {
        this.snackBar.open("Error Message", error.message, {
          duration: 2000,
        });
      });
    } else {
      if(btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}
