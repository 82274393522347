import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobCardDepartmentScheduleService {

  API_URL: string = environment.serverUrl + "/jobCardDepartmentSchedule";

  constructor(private http: HttpClient) { }

  getById(id: number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  }

  getAllByDepartmentAndFromFromBetweenDates(departmentId: number, startDate: Date, endDate: Date,) {
    return this
      .http
      .get(`${this.API_URL}/getAllByDepartmentAndFromFromBetweenDates/${departmentId}/${startDate}/${endDate}`);
  }

  getJobCardSheduleByDateRange(startDate: Date, endDate: Date,) {
    return this
      .http
      .get(`${this.API_URL}/getJobCardSheduleByDateRange/${startDate}/${endDate}`);
  }

  create(jobCardCreateSchedule: any): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, jobCardCreateSchedule);
  }

  bulkJobCardSchedule(jobCardCreateSchedule: any): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}/bulkJobCardSchedule`, jobCardCreateSchedule);
  }

}
