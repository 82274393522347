import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-edit-job-card-calender-picker',
  templateUrl: './add-edit-job-card-calender-picker.component.html',
  styleUrls: ['./add-edit-job-card-calender-picker.component.css']
})
export class AddEditJobCardCalenderPickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
