<h1 mat-dialog-title>
    <mat-card-title>Weekly Schedule Active Job Cards </mat-card-title><button mat-raised-button color="primary" [ngStyle]="{'visibility':loading==false ? 'visible' : 'hidden'}" style="margin-right: 39px;position: absolute; right: 0;"(click)="saveSchedule()">Save</button>
</h1>
<mat-progress-bar mode="query" color="warn" [ngStyle]="{'visibility':loading==true ? 'visible' : 'hidden'}">
</mat-progress-bar>
<form fxLayout="column" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Enter Search">
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="department" matInput placeholder="Department"
                        (selectionChange)="onDepartment($event)">
                        <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field>
                    <mat-label>Day Of Week</mat-label>
                    <input matInput [matDatepicker]="dayOfWeek" formControlName="dayOfWeek" required
                        (dateChange)="onDayOfWeek($event)">
                    <mat-datepicker-toggle matSuffix [for]="dayOfWeek"></mat-datepicker-toggle>
                    <mat-datepicker #dayOfWeek></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <span class="mat-caption">&nbsp;</span><br>
                <span class="mat-body"> <span style="font-weight: 500;"> Start Week : </span> {{startWeek |
                    date: 'dd-MM-yyyy'}} - <span style="font-weight: 500;"> End Week : </span> {{endWeek | date:
                    'dd-MM-yyyy'}}</span> 
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <table mat-table [dataSource]="dsJobCards" matSort class="mat-elevation-z8">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Id-Track No</th>
                        <td mat-cell *matCellDef="let element">TR-{{element.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="departmentToCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                        <td mat-cell *matCellDef="let element"> {{element.departmentToCode}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tagName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.tagName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="jobCardTypeDesc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Card Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.jobCardTypeDesc}} </td>
                    </ng-container>

                    <ng-container matColumnDef="statusName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'dd-MM-yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element" class="action-link">
                            <button mat-button color="success" (click)="actionSchedule('schedule',element)">
                                Schedule</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50, 60 ,70, 80 ,90 ,100]">
                </mat-paginator>
            </div>
            <div class="col-md-4">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Id-Track-No</th>
                            <th>Type</th>
                            <th>Department</th>
                            <th>Day</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let item of dayScheduleJObCards; let i = index">
                        <td> TR-{{item.id}}</td>
                        <td> {{item.jobCardType}}</td>
                        <td> {{item.department}}</td>
                        <td>
                            <select class="form-control" id="item_Date_{{i}}" name="item_Date_{{i}}" (change)="onRowDaySelected($event,item , i)" style="font-size: smaller;height: 30px;">
                                <option *ngFor="let itemDay of item.dayWeek" value="{{itemDay}}">
                                    {{itemDay}}
                                </option>
                            </select>
                        </td>                      
                        <td>
                            <button mat-button color="warn" (click)='removeTask(i)'>X</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>   
</form>