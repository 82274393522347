import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { UserService } from 'src/services/user.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { RoleService } from 'src/services/role.service';
import { TitleService } from 'src/services/title.service';
import { EntityService } from 'src/services/entity.service';
import { DepartmentService } from 'src/services/department.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string;

  dsRoles: any;
  dsTitle: any;
  dsEntity : any;
  dsDepartment : any;

  constructor(private fb: FormBuilder, private apiRoleServ: RoleService, private apiTitleServ: TitleService, private apiEntityServ : EntityService, private apiUserServ : UserService, private tokenStorage: TokenstorageService, 
    private departmentServ : DepartmentService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddUserComponent>) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
      }
  }

  ngOnInit() {
    this.createForm();
    this.loadRoles();
    this.loadTitles();
    this.loadSystemEntity();
    this.loadSystemDepartment();
  }

  createForm() {
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      idNumber: [null, Validators.required],
      employeeNumber: [null, Validators.required],
      logUserName: [null, Validators.required],
      systemRoleId: [null, Validators.required],
      titleName: [null, Validators.required],
      logUserPassword: [null, Validators.required],
      systemEntityId : [null, Validators.required],
      departmentId : [null,Validators.required]
    });
  }

  loadRoles() {
    this.apiRoleServ.getAll().subscribe(data => {
      this.dsRoles = data
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadTitles() {  
    this.apiTitleServ.getAll().subscribe(data => {
      this.dsTitle = data
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadSystemEntity() {
    this.apiEntityServ.getAll().subscribe(data => {
      this.dsEntity = data;     
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadSystemDepartment() {
    this.departmentServ.getAll().subscribe(data => {
      this.dsDepartment = data;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      var formData = this.form.value;
      formData.id = 0;
      formData.createdBy = this.loggedUserId;
      formData.createdAt = new Date();      
      this.apiUserServ.create(formData).subscribe(data => {        
        this.dialogRef.close({ refresh : true});  
      });

    } else {
      if(btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}
