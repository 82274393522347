<div class="form-wraper" *ngIf="!isLoggedIn">
    <div class="form-container card">
        <div class="form-header">           
            <!-- UhuruMES Maintenance   -->
            <br>        
            <img src="../../assets/img/360Logo.png" width="250"/>
        </div>
        
        <form class="example-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-body">
                <mat-form-field class="justifier">
                    <input matInput placeholder="Enter UserName" type="text" class="form-input"
                        formControlName="username">
                </mat-form-field>      
                <mat-form-field class="justifier">
                    <input matInput placeholder="Enter Password" type="password" class="form-input"
                        formControlName="userPassword">
                </mat-form-field>
            </div>
            <div class="form-footer">
                <span style="color: red;">{{errorMessage}} </span>
                <button mat-raised-button class="btn custom-btn" *ngIf="!loading" style="margin-left: 3px;">
                    <mat-icon>input</mat-icon> Login
                </button>
            </div>            
            <mat-spinner style="margin-left: 40%;" *ngIf="loading"></mat-spinner>    
        </form>
        <div class="additional-link" style="color : #32365d;">
            Copyright &copy; {{currentYear}}. All rights reserved.
        </div>
        <div class="additional-link" style="color : #32365d;">
         Powered by <a href="https://brilliware.com" target="_blank">Brilliware</a>
        </div>
    </div>
</div>