import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { ThakaduEquipmentService } from 'src/services/thakadu-equipment.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { DocumentTypeService } from 'src/services/document-type.service';
import { DepartmentService } from 'src/services/department.service';

@Component({
  selector: 'app-add-edit-document-type',
  templateUrl: './add-edit-document-type.component.html',
  styleUrls: ['./add-edit-document-type.component.css']
})
export class AddEditDocumentTypeComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string;
  dsCompany: any;
  dsEquipment: any;
  dsDepartment : any;
  pageTitle: string;

  constructor(private fb: FormBuilder, private apiDepartmenntServ : DepartmentService, private apiDocumentTypeServ: DocumentTypeService, private tokenStorage: TokenstorageService,
    private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditDocumentTypeComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
      }
      if(data == null){
        this.pageTitle = "Add Document Type";
      }else{
        this.pageTitle = "Edit Document Type";
      }
  }

  ngOnInit(): void {
    this.createForm();
    this.loadDepartment();
    this.form.patchValue(
    {     
      documentTypeName: this.data.documentTypeName,
      departmentId :  this.data.departmentId            
    });
  }

  loadDepartment() {
    this.apiDepartmenntServ.getAll().subscribe(data => {
      this.dsDepartment = data as [];
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  createForm() {
    this.form = this.fb.group({
      documentTypeName: [null, [Validators.required]],
      departmentId :  [null, [Validators.required]]     
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      let formDocumentTypeData = null;
      var formData = this.form.value;  
      if(this.data != null)
      {
        formDocumentTypeData = {
          id: this.data.id, 
          documentTypeName: formData.documentTypeName, 
          departmentId :  formData.departmentId,                 
          editedBy : this.loggedUserId,
          editedAt : new Date()  
        }

      }else{
        formDocumentTypeData = {
          id: 0,
          documentTypeName: formData.documentTypeName, 
          departmentId :  formData.departmentId,       
          createdBy: this.loggedUserId,
          createdAt: new Date()
        }
      }
      this.apiDocumentTypeServ.create(formDocumentTypeData).subscribe(data => {       
        this.dialogRef.close({ refresh : true});  
      }, error => {
        this.snackBar.open("Error Message", error.message, {
          duration: 2000,
        });
      });

    } else {
      if(btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}


