import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGaurdServiceService } from 'src/services/auth-gaurd-service.service';
import { ListusersComponent } from './listusers/listusers.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { ListEntityComponent } from './list-entity/list-entity.component';
import { ListRolesComponent } from './list-roles/list-roles.component';
import { ListDepartmentComponent } from './list-department/list-department.component';
import { ListCompanyTypeComponent } from './list-company-type/list-company-type.component';
import { ListCompanyComponent } from './list-company/list-company.component';
import { ListEquipmentTypeComponent } from './list-equipment-type/list-equipment-type.component';
import { ListEquipmentComponent } from './list-equipment/list-equipment.component';
import { ListJobcardTypeComponent } from './list-jobcard-type/list-jobcard-type.component';
import { ListSupFunctionalLocationComponent } from './list-sup-functional-location/list-sup-functional-location.component';
import { ListFunctionLocationComponent } from './list-function-location/list-function-location.component';
import { ListJobCardsComponent } from './list-job-cards/list-job-cards.component';
import { ListThakaduEquipmentComponent } from './list-thakadu-equipment/list-thakadu-equipment.component';
import { ListDocumentsComponent } from './list-documents/list-documents.component';
import { ListDocumentTypesComponent } from './list-document-types/list-document-types.component';
import { ListUserDocumentAccessComponent } from './list-user-document-access/list-user-document-access.component';
import { ListReportsComponent } from './list-reports/list-reports.component';
import { ReportDepartmentDateRangeComponent } from './report-department-date-range/report-department-date-range.component';
import { ListInterLocksComponent } from './list-inter-locks/list-inter-locks.component';
import { ListDepartmentScheduleComponent } from './list-department-schedule/list-department-schedule.component';
import { ListRoutineMaintenanceTagPlanComponent } from './list-routine-maintenance-tag-plan/list-routine-maintenance-tag-plan.component';
import { ListEquipmentCategoryComponent } from './list-equipment-category/list-equipment-category.component';
import { ListEquipmentComponentComponent } from './list-equipment-component/list-equipment-component.component';
import { ListEquipmentFailureTypeComponent } from './list-equipment-failure-type/list-equipment-failure-type.component';
import { ListEquipmentFailureRootCauseComponent } from './list-equipment-failure-root-cause/list-equipment-failure-root-cause.component';
import { ListEquipmentRepairTypeComponent } from './list-equipment-repair-type/list-equipment-repair-type.component';
import { ListEquipmentRepairTypeSymptomLinksComponent } from './list-equipment-repair-type-symptom-links/list-equipment-repair-type-symptom-links.component';

const routes: Routes = [
  { 
    path: 'Home',
    component: HomeComponent , canActivate:[AuthGaurdServiceService]
  },
  {
    path : "UsersList",
    component : ListusersComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "AdminMenu",
    component : AdminMenuComponent, canActivate:[AuthGaurdServiceService]
  }, 
  {
    path : "EntityList",
    component : ListEntityComponent, canActivate:[AuthGaurdServiceService]
  }, 
  {
    path : "RolesList",
    component : ListRolesComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "DepartmentList",
    component : ListDepartmentComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "CompanyTypeList",
    component : ListCompanyTypeComponent  ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "CompanyList",
    component : ListCompanyComponent  ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "EquipmentTypeList",
    component : ListEquipmentTypeComponent  ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "EquipmentList",
    component : ListEquipmentComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "DepartmentScheduleList",
    component : ListDepartmentScheduleComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "JobcardTypeList",
    component : ListJobcardTypeComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "JobCardList",
    component : ListJobCardsComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "SupFunationalLocationList",
    component : ListSupFunctionalLocationComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "FunctionLocationList",
    component : ListFunctionLocationComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "ThakaduEquipmentTypeList",
    component : ListThakaduEquipmentComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "EquipmentComponentList",
    component : ListEquipmentComponentComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "EquipmentFailureTypeList",
    component : ListEquipmentFailureTypeComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "EquipmentRootCauseFailureList",
    component : ListEquipmentFailureRootCauseComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "EquipmentRepairTypeList",
    component : ListEquipmentRepairTypeComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "EquipmentRepairTypeSymptonsList",
    component : ListEquipmentRepairTypeSymptomLinksComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "ThakaduEquipmentCategoryList",
    component : ListEquipmentCategoryComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "adminDocumentList",
    component : ListDocumentsComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "DocumentTypeList",
    component : ListDocumentTypesComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "InterLockList",
    component : ListInterLocksComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "ReportList",
    component : ListReportsComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "ReportDepartByDateRange",
    component : ReportDepartmentDateRangeComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "DocumentList",
    component : ListUserDocumentAccessComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "RoutineMaintenanceTagPlanList",
    component : ListRoutineMaintenanceTagPlanComponent ,canActivate:[AuthGaurdServiceService]
  },
  {
    path : "",
    component : LoginComponent 
  },
  {
    path: '**',   
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
