<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    {{data.title}}
</h1>
<div mat-dialog-content>
    {{data.message}}
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" value="Confirm" [mat-dialog-close]="true">Confirm</button>
    <button mat-raised-button color="primary" value="Cancel" [mat-dialog-close]="false">Cancel</button>
</div>