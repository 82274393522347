export class CreateUpdateSystemUserDocumentActionDTO {

    id : number;
	
	createdBy : number;
	
	createdAt : Date;
	
	editedBy : number;
	
	editedAt : Date;
	
	acceptedDowloadedDocument : boolean;
	
    documentId : number;
    
}
