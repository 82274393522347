import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ThakaduEquipmentService {

  API_URL: string = environment.serverUrl + "/equipmentThakadu";

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this
      .http
      .get(`${this.API_URL}`);
  }

  getEquipmentAutoComplete(): Observable<any> {   
    return this.
      http.
        get(`${this.API_URL}`).pipe(
          map((data: any) => data.map((
            { id, instrumentTag }) => ({ id, instrumentTag }
          ))
    ));
  }

  getById(id: number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  }

  create(debtor: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, debtor);
  }

}