import { Data } from '@angular/router';

export class CreateUpdateJobCardTask {

    private id: number;

    private departmentId: number;

    private assigneeId: number;

    private startDate: Date;

    private startTime: string;

    private endDate: Date;

    private endTime: string;

    private actualStartDate: Date;

    private actualStartTime: string;

    private actualEndDate: Date;

    private actualEndTime: String;

    private description: string;

    private createdBy: number;

    private createdAt: Date;

    private editedBy: number;

    private editedAt: Date;

    private delayReasonId: number;

    private otherReason: string;

    private isActive: boolean;

    private jobCardId: number;

    private statusId: number;

    private finalComment: string;

    private jobCardTaskActionId : number;

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getDepartmentId(): number {
        return this.departmentId;
    }

    public setDepartmentId(departmentId: number): void {
        this.departmentId = departmentId;
    }

    public getAssigneeId(): number {
        return this.assigneeId;
    }

    public setAssigneeId(assigneeId: number): void {
        this.assigneeId = assigneeId;
    }

    public getStartDate(): Date {
        return this.startDate;
    }

    public setStartDate(startDate: Date): void {
        this.startDate = startDate;
    }

    public getStartTime(): string {
        return this.startTime;
    }

    public setStartTime(startTime: string): void {
        this.startTime = startTime;
    }

    public getEndDate(): Date {
        return this.endDate;
    }

    public setEndDate(endDate: Date): void {
        this.endDate = endDate;
    }

    public getEndTime(): string {
        return this.endTime;
    }

    public setEndTime(endTime: string): void {
        this.endTime = endTime;
    }

    public getActualStartDate(): Date {
        return this.actualStartDate;
    }

    public setActualStartDate(actualStartDate: Date): void {
        this.actualStartDate = actualStartDate;
    }

    public getActualStartTime(): string {
        return this.actualStartTime;
    }

    public setActualStartTime(actualStartTime: string): void {
        this.actualStartTime = actualStartTime;
    }

    public getActualEndDate(): Date {
        return this.actualEndDate;
    }

    public setActualEndDate(actualEndDate: Date): void {
        this.actualEndDate = actualEndDate;
    }

    public getActualEndTime(): String {
        return this.actualEndTime;
    }

    public setActualEndTime(actualEndTime: String): void {
        this.actualEndTime = actualEndTime;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string): void {
        this.description = description;
    }

    public getCreatedBy(): number {
        return this.createdBy;
    }

    public setCreatedBy(createdBy: number): void {
        this.createdBy = createdBy;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(createdAt: Date): void {
        this.createdAt = createdAt;
    }

    public getEditedBy(): number {
        return this.editedBy;
    }

    public setEditedBy(editedBy: number): void {
        this.editedBy = editedBy;
    }

    public getEditedAt(): Date {
        return this.editedAt;
    }

    public setEditedAt(editedAt: Date): void {
        this.editedAt = editedAt;
    }

    public getDelayReasonId(): number {
        return this.delayReasonId;
    }

    public setDelayReasonId(delayReasonId: number): void {
        this.delayReasonId = delayReasonId;
    }

    public getOtherReason(): string {
        return this.otherReason;
    }

    public setOtherReason(otherReason: string): void {
        this.otherReason = otherReason;
    }

    public isIsActive(): boolean {
        return this.isActive;
    }

    public setIsActive(isActive: boolean): void {
        this.isActive = isActive;
    }

    public getJobCardId(): number {
        return this.jobCardId;
    }

    public setJobCardId(jobCardId: number): void {
        this.jobCardId = jobCardId;
    }

    public getStatusId(): number {
        return this.statusId;
    }

    public setStatusId(statusId: number): void {
        this.statusId = statusId;
    }

    public getFinalComment(): string {
        return this.finalComment;
    }

    public setFinalComment(finalComment: string): void {
        this.finalComment = finalComment;
    }

    public getJobCardTaskActionId(): number {
        return this.jobCardTaskActionId
    }

    public setJobCardTaskActionId(jobCardTaskActionId: number): void {
        this.jobCardTaskActionId = jobCardTaskActionId;
    }
    
}
