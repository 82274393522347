import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Data, Router } from '@angular/router';
import { debounce } from 'rxjs/operators';
import { DateRangeCreateWeeksService } from 'src/services/date-range-create-weeks.service';
import { DepartmentService } from 'src/services/department.service';
import { JobCardDepartmentScheduleService } from 'src/services/job-card-department-schedule.service';
import { JobCardService } from 'src/services/job-card.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';

@Component({
  selector: 'app-add-edit-department-job-card-schedule',
  templateUrl: './add-edit-department-job-card-schedule.component.html',
  styleUrls: ['./add-edit-department-job-card-schedule.component.css']
})
export class AddEditDepartmentJobCardScheduleComponent implements OnInit {

  form: FormGroup;
  loading = false;
  dsDepartment: any[];
  loggedUserId: number;
  loggedUserDepartment: number;
  loggedUserRoleName: string;
  selectedDepartment: number;
  startWeek: Date;
  endWeek: Date;
  public dayScheduleJObCards: jobCardSchedule[] = []

  public displayedColumns: string[] = ['id', 'jobCardTypeDesc', 'departmentToCode', 'tagName', 'statusName', 'createdAt', 'action'];

  public dsJobCards = new MatTableDataSource<jobCardData>();

  constructor(private fb: FormBuilder, private router: Router, private apiJobCardServ: JobCardService,
    private tokenStorage: TokenstorageService, private apiDepartmentServ: DepartmentService, private apiCreateWeekCalendarServ: DateRangeCreateWeeksService,
    private apiJobCardScheduleServ : JobCardDepartmentScheduleService ,private dialog: MatDialog, private snackBar: MatSnackBar,public dialogRef: MatDialogRef<AddEditDepartmentJobCardScheduleComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.loggedUserDepartment = user.departmentId;
      this.selectedDepartment = user.departmentId;
      this.loggedUserRoleName = user.roleName;
    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.loadDepartment();
    this.createForm();
    this.loadDepartmentAtciveJobCards();
    this.refreshData(new Date());  
  }


  ngAfterViewInit(): void {
    this.dsJobCards.sort = this.sort;
    this.dsJobCards.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsJobCards.filter = filterValue.trim().toLowerCase();

    if (this.dsJobCards.paginator) {
      this.dsJobCards.paginator.firstPage();
    }
  }

  createForm() {
    this.form = this.fb.group({
      department: this.selectedDepartment,
      dayOfWeek: [null]
    });
    this.form.patchValue({
      department: this.selectedDepartment,
      dayOfWeek: new Date()
    });
  }

  loadDepartment() {
    this.apiDepartmentServ.getAll().subscribe(data => {
      let departList: any = [];
      departList.push({ id: -1, departmentName: "All" });
      for (let o of data) {
        departList.push({ id: o.id, departmentName: o.departmentName });
      }
      this.dsDepartment = departList;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
    });
  }

  loadDepartmentAtciveJobCards() {
    this.loading = true;
    this.apiJobCardServ.getAllActiveJobCardsOrByDepartment(this.selectedDepartment).subscribe(data => {
      this.dsJobCards.data = data as jobCardData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  onDepartment(event) {
    this.selectedDepartment = event.value;
    this.loadDepartmentAtciveJobCards();
  }

  actionSchedule(actionType, row) {
    let newRow = new jobCardSchedule();
    newRow.id = row.id;
    newRow.jobCardType = row.jobCardTypeDesc;
    newRow.department = row.departmentCode;
    newRow.departmentId = row.departmentIdTo;    
    newRow.dayWeek = new Array('Select', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday');
    var found = this.dayScheduleJObCards.find(o => o.id === row.id)
    if (!found) {
      this.dayScheduleJObCards.push(newRow);
    }
  }

  removeTask(index: number) {
    if (index > -1) {
      this.dayScheduleJObCards.splice(index, 1);
    }
  }

  onRowDaySelected(event : any, row : any , index : number) { 
    let selectedLaw : any = event.target.value;
    for(let i = 0 ;this.dayScheduleJObCards.length ; i++) {
      if(i == index) {
        this.dayScheduleJObCards[i].itemDay = selectedLaw; 
        break;      
      }
    }      
  }

  onDayOfWeek(event) {   
    let selectedDateTo = new Date(this.form.value["dayOfWeek"]); 
    this.refreshData(selectedDateTo);   
  }

  refreshData(selectedDate: Date) {
    const weekDateRange = this.apiCreateWeekCalendarServ.startAndEndOfWeek(selectedDate);
    this.startWeek = new Date(weekDateRange[0]);
    this.endWeek = new Date(weekDateRange[1]);
  }

  saveSchedule() {
    this.loading = true;
    let bulkSchedule : any[] = [];
    debugger;
    this.dayScheduleJObCards.forEach(item => {
      debugger;
      let row  = {
        id : 0,
        departmentId : item.departmentId,
        jobCardId : item.id,
        createdBy : this.loggedUserId,
        createdAt : new Date(),
        editedBy : this.loggedUserId,
        editedAt : new Date(),
        startDate : this.startWeek,
        endDate : this.endWeek,
        dayOfWeek : item.itemDay,
        isActive : true,
        startTime : "08:00",
        endTime : "17:00"
      }
      bulkSchedule.push(row);
     });
   this.apiJobCardScheduleServ.bulkJobCardSchedule(bulkSchedule).subscribe(res => {
    this.loading = false;
    this.dialogRef.close({ refresh: true });
   },error => {
    this.loading = false;
    this.snackBar.open("Error Message", error.message, {
      duration: 2000,
    });
   });
  }
}

export interface jobCardData {

  id: number;

  departmentId: number;

  departmentName: string;

  departmentCode: string;

  departmentIdTo: number;

  departnameTO: string;

  departmentToCode: string;

  statusId: number;

  statusName: string;

  startTime: Date;

  endTime: Date;

  completedOn: Date;

  description: string;

  createdBy: number;

  createdByName: String;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  isActive: boolean;

  jobCardTypeDesc: string;

  jobCardTypeId: number;

  isApproved: boolean;

  tagId: number;

  tagName: string;

  slaPercentage: number;

  orderNumber: string;

}

export class jobCardSchedule {
  public id: number;
  public jobCardType: string;
  public department: string;
  public departmentId : number
  public itemDay : string;
  public scheduleDay: Date;
  public dayWeek: any[];
}

