import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { JobCardTaskService } from 'src/services/job-card-task.service';
import { JobCardDelayReasonService } from 'src/services/job-card-delay-reason.service';
import { UserService } from 'src/services/user.service';
import { SystemUser } from 'src/models/system-user';
import { JobCardTaskDelayReason } from 'src/models/job-card-task-delay-reason';
import { CreateUpdateJobCardTask } from 'src/models/create-update-job-card-task'
import { DepartmentService } from 'src/services/department.service';
import { Department } from 'src/models/department';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { HourTimes } from 'src/models/hour-times';
import { JobCardTaskStatusService } from 'src/services/job-card-task-status.service';
import { JobCardService } from 'src/services/job-card.service';
import { Globals } from 'src/app/global-values/globals';
import { JobCardTaskActionService } from 'src/services/job-card-task-action.service';
import { JobCardTaskEquipmentComponentActionService } from 'src/services/job-card-task-equipment-component-action.service';
import { JobCardTaskEquipmentFailureRootCauseActionService } from 'src/services/job-card-task-equipment-failure-root-cause-action.service';
import { JobCardTaskEquipmentRepairTypeActionService } from 'src/services/job-card-task-equipment-repair-type-action.service';
import { JobCardTaskEquipmentFailureActionService } from 'src/services/job-card-task-equipment-failure-action.service';
import { EquipmentCategoryRootCauseFailureLinkService } from 'src/services/equipment-category-root-cause-failure-link.service';
import { EquipmentCategoryFailureTypeLinkService } from 'src/services/equipment-category-failure-type-link.service';
import { EquipmentCategoryRepairTypeLinkService } from 'src/services/equipment-category-repair-type-link.service';
import { EquipmentCategoryComponentLinkService } from 'src/services/equipment-category-component-link.service';

@Component({
  selector: 'app-edit-job-card-task',
  templateUrl: './edit-job-card-task.component.html',
  styleUrls: ['./edit-job-card-task.component.css']
})
export class EditJobCardTaskComponent implements OnInit {

  loggedUserId: number;
  loggedUserRoleId: number;
  loggedUserDepartmentId: number;
  btnName: string;
  form: FormGroup;
  dsDepartment: Department[];
  dsPerson: SystemUser[];
  dsTimes: any[];
  dsStatus: any[] = [];
  dsJobCardDelayReason: JobCardTaskDelayReason[];
  dsJobCardTaskAction: any[] = [];
  otherReasonActivate: boolean = false;
  minDate: Date;
  allowSpecialApproval: boolean = false;
  allowTaskAction: boolean = true;
  user: any;
  dsRepairType: any[] = [];
  dsRootCause: any[] = [];
  dsFailureType: any[] = [];
  dsComponent: any[] = [];
  equipmentCategoryId: number;
  equipmentId : number;

  constructor(private fb: FormBuilder, private apiJobCardTaskServ: JobCardTaskService, private apiDepartmentServ: DepartmentService, private apiJobcardTaskStatusServ: JobCardTaskStatusService, private globalValues: Globals,
    private apiJobCardServ: JobCardService, private apiPersonServ: UserService, private apiJobCardDelayReasonServ: JobCardDelayReasonService, private tokenStorage: TokenstorageService,
    private apiJobCardTaskActionServ: JobCardTaskActionService, private apiEquipmentCategoryRootCauseLinkServ: EquipmentCategoryRootCauseFailureLinkService,
    private apiEquipmentCategoryRepairTypeLinkServ: EquipmentCategoryRepairTypeLinkService,private apiEquipmentCategoryComponentLinkServ: EquipmentCategoryComponentLinkService, private apiEquipmentCategoryFailureTypeLinkServ: EquipmentCategoryFailureTypeLinkService, private snackBar: MatSnackBar, public dialog: MatDialog,
    private apiJobCardTaskEquipmentFailureActionServ: JobCardTaskEquipmentFailureActionService, private apiJobCardTaskEquipmentRepairTypeActionService: JobCardTaskEquipmentRepairTypeActionService,
    private apiJobCardTaskEquipmentFailureRootCauseActionService: JobCardTaskEquipmentFailureRootCauseActionService, private apiJobCardTaskEquipmentComponentActionServ: JobCardTaskEquipmentComponentActionService,
    public dialogRef: MatDialogRef<EditJobCardTaskComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    this.user = this.tokenStorage.getUser();
    if (this.user) {
      this.loggedUserId = this.user.id;
      this.loggedUserRoleId = this.user.roleId;
      this.loggedUserDepartmentId = this.user.departmentId;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.loadWorkDepartment();
    this.loadSystemPerson(this.data.departmentId);
    this.loadJobCardDelayReason();
    this.loadJobCarTimes();
    this.loadJobCardStatus();
    this.loadJobCardActionTask();
    this.loadAllJobCardTagSymptonsAction();

    let dtoData = this.data as jobcardTaskData;
    this.apiJobCardServ.getById(this.data.jobCardId).subscribe(res => {
      this.equipmentCategoryId = res["tagCategoryId"]
      this.equipmentId = res["tagId"]
      this.loadAllJobCardTagSymptonsAction();
      this.bindFormData(dtoData);
      this.otherReasonActivate = dtoData.otherReason ? true : false;
      let checkSpecialApprovalResults: any[] = [];
      if (dtoData.jobCardStatus != "Awaiting") {
        checkSpecialApprovalResults = this.checkSpecialApproval("Approved");
      }
      if (this.data.departmentId != this.loggedUserDepartmentId) {
        if (checkSpecialApprovalResults.length > 0 && this.user.roleName == "Supervisor") {
          this.form.controls['statusId'].enable();
          this.form.controls['assigneeId'].enable();
          this.form.controls['repairType'].enable();
          this.form.controls['failureType'].enable();
          this.form.controls['component'].enable();
          this.form.controls['rootCause'].enable();
          this.form.controls['jobCardTaskActionId'].enable();
          this.allowSpecialApproval = true;
        } else {
          this.form.controls['statusId'].disable();
        }
        this.form.controls['actualStartDate'].disable();
        this.form.controls['actualStartTime'].disable();
        this.form.controls['actualEndDate'].disable();
        this.form.controls['actualEndTime'].disable();
        this.form.controls['delayReasonId'].disable();
        this.form.controls['otherReason'].disable();
      } else {
        switch (this.data.jobCardStatus) { //disabled field based on jobcard
          case "Awaiting": {
            this.disableAllTheFields();
            break;
          }
          case "Completed": {
            this.disableAllTheFields();
            break;
          }
          case "Closed" : {
            this.disableAllTheFields();
            break;
          }
          case "Rejected": {
            this.disableAllTheFields();
            break;
          }
          case "OnHold": {
            this.disableAllTheFields();
            break;
          }
          default: {            
            let global = new Globals();
            if (this.tokenStorage.getUser().roleName != global.technicianRoleName) {
              switch (this.data["statusName"]) {//disabled fields based on task
                case "Completed": {
                  this.disableAllTheFields();
                  break;
                }
                case "Rejected": {
                  this.disableAllTheFields();
                  break;
                }
                default: {
                  this.form.controls['assigneeId'].enable();
                  break;
                }
              }

            } else {
              if(!this.data.assigneeId) {
                this.disableAllTheFields();
              }else{
                this.form.get('actualStartDate').enable();
                this.form.get('actualStartTime').enable();
                this.form.get('actualEndDate').enable();
                this.form.get('actualEndTime').enable();
                this.form.controls['repairType'].enable();
                this.form.controls['failureType'].enable();
                this.form.controls['component'].enable();
                this.form.controls['rootCause'].enable();
                this.form.controls['jobCardTaskActionId'].enable();
              }              
            }
            break;
          }
        }
      }
    });    
  }

  disableAllTheFields() {
    this.allowTaskAction = false;
    this.form.get('departmentId').disable();
    this.form.get('assigneeId').disable();
    this.form.get('startDate').disable();
    this.form.get('startTime').disable();
    this.form.get('endDate').disable();
    this.form.get('endTime').disable();
    this.form.get('description').disable();
    this.form.controls['statusId'].disable();
    this.form.controls['actualStartDate'].disable();
    this.form.controls['actualStartTime'].disable();
    this.form.controls['actualEndDate'].disable();
    this.form.controls['actualEndTime'].disable();
    this.form.controls['delayReasonId'].disable();
    this.form.controls['otherReason'].disable();
    this.form.controls['finalComment'].disable();
    this.form.controls['repairType'].disable();
    this.form.controls['failureType'].disable();
    this.form.controls['component'].disable();
    this.form.controls['rootCause'].disable();
    this.form.controls['jobCardTaskActionId'].disable();
  }

  async bindFormData(dtoData: jobcardTaskData) {

    let defaultStartTime = new Date().toTimeString().slice(0, 2) + ":00";
    var today = new Date();
    today.setHours(today.getHours() + 1);
    let defaultsEndTime = today.toTimeString().slice(0, 2) + ":00";
    let date = new Date();
    this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const listEquipmentRootCause = await this.getEquipmentRootCauseJobCardId(this.data.jobCardId) as any[];
    let listEquipmentRootCauseResult: any[] = [];
    for (let i = 0; i < listEquipmentRootCause.length; i++) {
      let equipmentFailureRootCauseId = listEquipmentRootCause[i].equipmentFailureRootCauseId;
      listEquipmentRootCauseResult.push(equipmentFailureRootCauseId);
    }

    const listEquipmentRepairType = await this.getEquipmentRepairTypeJobCardId(this.data.jobCardId) as any[];
    let listEquipmentRepairTypeResult: any[] = [];
    for (let i = 0; i < listEquipmentRepairType.length; i++) {
      let equipmentFailureRootCauseId = listEquipmentRepairType[i].equipmentRepairTypeId;
      listEquipmentRepairTypeResult.push(equipmentFailureRootCauseId);
    }

    const listEquipmentFailureType = await this.getEquipmentFailureTypeJobCardId(this.data.jobCardId) as any[];
    let listEquipmentFailureTypeResult: any[] = [];
    for (let i = 0; i < listEquipmentFailureType.length; i++) {
      let equipmentFailureTypeId = listEquipmentFailureType[i].equipmentFailureTypeId;
      listEquipmentFailureTypeResult.push(equipmentFailureTypeId);
    }

    const listEquipmentComponent = await this.getEquipmentComponentJobCardId(this.data.jobCardId) as any[];
    let listEquipmentComponentResults: any[] = [];
    for (let i = 0; i < listEquipmentComponent.length; i++) {
      let equipmentComponentId = listEquipmentComponent[i].equipmentComponentId;
      listEquipmentComponentResults.push(equipmentComponentId);
    }

    this.form.patchValue(
      {
        assigneeId: dtoData.assigneeId == 0 ? null : dtoData.assigneeId,
        departmentId: dtoData.departmentId,
        startDate: new Date(dtoData.startDate),
        startTime: dtoData.startTime,
        endDate: new Date(dtoData.endDate),
        endTime: dtoData.endTime,
        actualStartDate: dtoData.actualStartDate ? new Date(dtoData.actualStartDate) : new Date(),
        actualStartTime: dtoData.actualStartTime ? dtoData.actualStartTime : defaultStartTime,
        actualEndDate: dtoData.actualEndDate ? new Date(dtoData.actualEndDate) : new Date(),
        actualEndTime: dtoData.actualEndTime ? dtoData.actualEndTime : defaultsEndTime,
        delayReasonId: dtoData.delayReasonId,
        otherReason: dtoData.otherReason,
        description: dtoData.description,
        statusId: dtoData.statusId,
        finalComment: dtoData.finalComment,
        jobCardTaskActionId: dtoData.jobCardTaskActionId,
        repairType: listEquipmentRepairTypeResult,
        failureType: listEquipmentFailureTypeResult,
        component: listEquipmentComponentResults,
        rootCause: listEquipmentRootCauseResult
      });
  }

  checkSpecialApproval(status: String): any {
    let dsJobCardStatus: any[] = []
    dsJobCardStatus = this.data.dsJobCardStatus.filter(r => r.statusName == status);
    return dsJobCardStatus;
  }

  createForm() {
    this.form = this.fb.group({
      departmentId: [null, [Validators.required]],
      assigneeId: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      actualStartDate: [null, [Validators.required]],
      actualStartTime: [null, [Validators.required]],
      actualEndDate: [null, [Validators.required]],
      actualEndTime: [null, [Validators.required]],
      delayReasonId: [null],
      otherReason: [null],
      description: [null, [Validators.required]],
      statusId: [null, [Validators.required]],
      finalComment: [null],
      jobCardTaskActionId: [null, [Validators.required]],
      repairType: [null, [Validators.required]],
      failureType: [null, [Validators.required]],
      component: [null, [Validators.required]],
      rootCause: [null, [Validators.required]]
    });
    this.form.get('departmentId').disable();
    this.form.get('assigneeId').disable();
    this.form.get('startDate').disable();
    this.form.get('startTime').disable();
    this.form.get('endDate').disable();
    this.form.get('endTime').disable();
    this.form.get('description').disable();    
  }

  loadWorkDepartment() {
    this.apiDepartmentServ.getAll().subscribe(data => {
      this.dsDepartment = data as Department[];
    });
  }

  loadSystemPerson(department: number) {
    this.apiPersonServ.getAllUserByDepartmentId(department).subscribe(data => {
      this.dsPerson = data as SystemUser[];
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadJobCardActionTask() {
    this.apiJobCardTaskActionServ.getAll().subscribe(data => {
      this.dsJobCardTaskAction = data as any[];
    })
  }

  loadJobCarTimes() {
    let hourTimes = new HourTimes();
    this.dsTimes = hourTimes.getAllTimes();
  }

  loadJobCardDelayReason() {
    this.apiJobCardDelayReasonServ.getAll().subscribe(data => {
      this.dsJobCardDelayReason = data as JobCardTaskDelayReason[];
    })
  }

  loadJobCardStatus() {
    this.apiJobcardTaskStatusServ.getAll().subscribe(res => {
      this.dsStatus = res as any[];
      let jobCardTaskNextStatus = res.filter(r => r["id"] == this.data.statusId);
      let nextStatusList = jobCardTaskNextStatus[0].statusManagement.split(",") as [];
      this.dsStatus = this.getNextStatus(nextStatusList);
      if (this.dsStatus.length == 1) {
        this.form.get('actualStartDate').disable();
        this.form.get('actualStartTime').disable();
        this.form.get('actualEndDate').disable();
        this.form.get('actualEndTime').disable();
        this.form.get('delayReasonId').disable();
        this.form.get('otherReason').disable();
        this.form.get('statusId').disable();
        this.form.get('jobCardTaskActionId').disable();
        this.form.get('finalComment').disable();
        this.form.controls['repairType'].disable();
        this.form.controls['failureType'].disable();
        this.form.controls['component'].disable();
        this.form.controls['rootCause'].disable();
        this.form.controls['jobCardTaskActionId'].disable();
      }     
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  get checkIfTaskAssigned() : boolean {
    let result =  this.form.controls['assigneeId'].status == "DISABLED" ? true : false;
    return result;
  }

  async loadAllJobCardTagSymptonsAction() { 
    if(this.equipmentCategoryId) {
      const listEquipmentComponentLinked = await this.getAllEquipmentCategoryComponentLinkByEquipmentCategoryId(this.equipmentCategoryId) as any[];
      this.dsComponent = listEquipmentComponentLinked;

      const listEquipmentRootCauseLink = await this.getAllEquipmentCategoryRootCauseLinkByEquipmentCategoryId(this.equipmentCategoryId) as any[];
      this.dsRootCause = listEquipmentRootCauseLink;

      const listEquipmentRepairTypeLink = await this.getAllEquipmentCategoryRepairTypeLinkByEquipmentCategoryId(this.equipmentCategoryId) as any[];
      this.dsRepairType = listEquipmentRepairTypeLink;

      const listEquipmentFailureTypeLink = await this.getAllEquipmentCategoryFailureTypeLinkByEquipmentCategoryId(this.equipmentCategoryId) as any[];
      this.dsFailureType = listEquipmentFailureTypeLink;
    }
  }

  getAllEquipmentCategoryComponentLinkByEquipmentCategoryId(selectedEquipmentCategoryId: number) {
    return this.apiEquipmentCategoryComponentLinkServ.getAllEquipmentCategoryComponentLinkByCategoryId(selectedEquipmentCategoryId).toPromise();
  }

  getAllEquipmentCategoryRootCauseLinkByEquipmentCategoryId(selectedEquipmentCategoryId: number) {
    return this.apiEquipmentCategoryRootCauseLinkServ.getAllEquipmentCategoryRootCauseLinkByCategoryId(selectedEquipmentCategoryId).toPromise();
  }

  getAllEquipmentCategoryRepairTypeLinkByEquipmentCategoryId(selectedEquipmentCategoryId: number) {
    return this.apiEquipmentCategoryRepairTypeLinkServ.getAllEquipmentCategoryRepairTypeLinkByCategoryId(selectedEquipmentCategoryId).toPromise();
  }

  getAllEquipmentCategoryFailureTypeLinkByEquipmentCategoryId(selectedEquipmentCategoryId: number) {
    return this.apiEquipmentCategoryFailureTypeLinkServ.getAllEquipmentCategoryFailureTypeLinkByEquipmentCategoryId(selectedEquipmentCategoryId).toPromise();
  }


  getNextStatus(jobCardTaskNextStatus: any[]): any {
    let dsNextStatus: any[] = [];
    jobCardTaskNextStatus.forEach(element => {
      let res = this.dsStatus.filter(r => r["id"] == element);
      dsNextStatus.push({
        id: res[0].id,
        statusName: res[0].statusName,
        statusManagement: res[0].statusManagement,
        statusRoleManagement: res[0].statusRoleManagement,
        statusTatHrs: res[0].statusTatHrs,
        statusSequence: res[0].statusSequence
      });
    });
    return dsNextStatus;
  }

  onChangeStatus(event) {
    let global = new Globals();
    this.data.statusId = event.value;
    if (this.data.statusId == global.jobCardTaskStatusCompletedId) {
      if (!this.form.controls["finalComment"].value) {
        this.form.controls['finalComment'].setErrors({ 'incorrect': true });
      }
    }
    if (this.data.statusId == global.jobCardTaskStatusInProgresId || this.data.statusId == global.jobCardTaskStatusAwaitingId) {
      if (!this.form.controls["assigneeId"].value) {
        this.form.controls['assigneeId'].setErrors({ 'incorrect': true });
      }
      if (this.data.statusId == global.jobCardTaskStatusInProgresId) {
        this.form.get('actualStartDate').setValue(null);
        this.form.get('actualStartTime').setValue(null);
        this.form.get('actualEndDate').setValue(null);
        this.form.get('actualEndTime').setValue(null);
        this.form.controls['repairType'].enable();
        this.form.controls['failureType'].enable();
        this.form.controls['component'].enable();
        this.form.controls['rootCause'].enable();
        this.form.controls['jobCardTaskActionId'].enable();
      }
    }
  }

  onChangeReason(event) {
    this.data.delayReasonId = event.value;
  }

  async onSubmit(btnName) {
    if (btnName == "btnSave" && this.form.valid) {
      let selectedRootCauseList = this.form.value.rootCause;
      if (selectedRootCauseList) {
        let listEquipmentRootCause: any[] = [];
        for (let i = 0; i < selectedRootCauseList.length; i++) {
          listEquipmentRootCause.push({
            id: 0,
            createdBy: this.loggedUserId,
            createdAt: new Date(),
            editedBy: this.loggedUserId,
            editedAt: new Date(),
            equipmentFailureRootCauseId: selectedRootCauseList[i],
            tagId: this.equipmentId,
            jobCardId: this.data.jobCardId
          });
        }
        const equipmentRootCause = await this.saveEquipmentRootCause(listEquipmentRootCause);
      }

      //Insert Repair Type
      let selectedRepairTypeList = this.form.value.repairType;
      if (selectedRepairTypeList) {
        let listEquipmentRepairType: any[] = [];
        for (let i = 0; i < selectedRepairTypeList.length; i++) {
          listEquipmentRepairType.push({
            id: 0,
            createdBy: this.loggedUserId,
            createdAt: new Date(),
            editedBy: this.loggedUserId,
            editedAt: new Date(),
            equipmentRepairTypeId: selectedRepairTypeList[i],
            tagId: this.equipmentId,
            jobCardId: this.data.jobCardId
          });
        }
        const equipmentRepair = await this.saveEquipmentRepairType(listEquipmentRepairType);
      }

      //Insert failure Type
      let selectedFailureTypeList = this.form.value.failureType;
      if (selectedFailureTypeList) {
        let listEquipmentFailureType: any[] = [];
        for (let i = 0; i < selectedFailureTypeList.length; i++) {
          listEquipmentFailureType.push({
            id: 0,
            createdBy: this.loggedUserId,
            createdAt: new Date(),
            editedBy: this.loggedUserId,
            editedAt: new Date(),
            equipmentFailureTypeId: selectedFailureTypeList[i],
            tagId: this.equipmentId,
            jobCardId: this.data.jobCardId
          });
        }
        const equipmentFailureType = await this.saveEquipmentFailureType(listEquipmentFailureType);
      }
      //Insert Component
      let selectedComponentList = this.form.value.component;
      if (selectedComponentList) {
        let listEquipmentComponent: any[] = [];
        for (let i = 0; i < selectedComponentList.length; i++) {
          listEquipmentComponent.push({
            id: 0,
            createdBy: this.loggedUserId,
            createdAt: new Date(),
            editedBy: this.loggedUserId,
            editedAt: new Date(),
            equipmentComponentId: selectedComponentList[i],
            tagId: this.equipmentId,
            jobCardId: this.data.jobCardId
          });
        }
        const equipmentComponent = await this.saveEquipmentComponent(listEquipmentComponent);
      }
      const taskDetails = await this.saveJobCardTask();

      this.dialogRef.close({ refresh: true });

    }else if(btnName == "btnAssignTechnician"){
      const taskDetails = await this.saveJobCardTask();
      this.dialogRef.close({ refresh: true });
    } else {
      if (btnName == "btnCancel") {
        this.dialogRef.close({ refresh: true });
      }
    }
  }

  saveEquipmentRootCause(rootCauseList: any) {
    return this.apiJobCardTaskEquipmentFailureRootCauseActionService.bulkInsertJobCardTaskEquipmentFailureRootCauseAction(rootCauseList).toPromise();
  }

  saveEquipmentRepairType(repairTypeList: any) {
    return this.apiJobCardTaskEquipmentRepairTypeActionService.bulkInsertJobCardTaskEquipmentRepairTypeAction(repairTypeList).toPromise();
  }

  saveEquipmentFailureType(failureTypeList: any) {
    return this.apiJobCardTaskEquipmentFailureActionServ.bulkInsertJobCardTaskEquipmentFailureAction(failureTypeList).toPromise();
  }

  saveEquipmentComponent(componentList: any) {
    return this.apiJobCardTaskEquipmentComponentActionServ.bulkInsertJobCardTaskEquipmentComponentAction(componentList).toPromise();
  }

  getEquipmentRootCauseJobCardId(jobCardId: number) {
    return this.apiJobCardTaskEquipmentFailureRootCauseActionService.getJobCardTaskEquipmentFailureRootCauseActionByJobCardId(jobCardId).toPromise();
  }

  getEquipmentRepairTypeJobCardId(jobCardId: number) {
    return this.apiJobCardTaskEquipmentRepairTypeActionService.getAllJobCardTaskEquipmentRepairTypeActionByJobCardId(jobCardId).toPromise();
  }

  getEquipmentFailureTypeJobCardId(jobCardId: number) {
    return this.apiJobCardTaskEquipmentFailureActionServ.getAllJobCardTaskEquipmentFailureActionByJobCardId(jobCardId).toPromise();
  }

  getEquipmentComponentJobCardId(jobCardId: number) {
    return this.apiJobCardTaskEquipmentComponentActionServ.getAllJobCardTaskEquipmentComponentActionByJobCardId(jobCardId).toPromise();
  }

  saveJobCardTask() {
    let global = new Globals();
    let dtoJobCardTask = new CreateUpdateJobCardTask();
    dtoJobCardTask.setId(this.data.id);
    dtoJobCardTask.setEditedBy(this.loggedUserId);
    dtoJobCardTask.setEditedAt(new Date());
    dtoJobCardTask.setDepartmentId(this.form.controls["departmentId"].value);
    dtoJobCardTask.setAssigneeId(this.form.controls["assigneeId"].value);
    dtoJobCardTask.setStartDate(this.form.controls["startDate"].value);
    dtoJobCardTask.setStartTime(this.form.controls["startTime"].value);
    dtoJobCardTask.setEndDate(this.form.controls["endDate"].value);
    dtoJobCardTask.setEndTime(this.form.controls["endTime"].value);
    dtoJobCardTask.setActualStartDate(this.form.controls["actualStartDate"].value);
    dtoJobCardTask.setActualStartTime(this.form.controls["actualStartTime"].value);
    dtoJobCardTask.setActualEndDate(this.form.controls["actualEndDate"].value);
    dtoJobCardTask.setActualEndTime(this.form.controls["actualEndTime"].value);
    dtoJobCardTask.setStatusId(this.form.controls["statusId"].value);
    dtoJobCardTask.setJobCardId(this.data.id);
    dtoJobCardTask.setDelayReasonId(this.form.controls["delayReasonId"].value);
    dtoJobCardTask.setOtherReason(this.form.controls["otherReason"].value);
    dtoJobCardTask.setDescription(this.form.controls["description"].value);
    dtoJobCardTask.setFinalComment(this.form.controls["finalComment"].value);
    dtoJobCardTask.setJobCardTaskActionId(this.form.controls["jobCardTaskActionId"].value);
    return this.apiJobCardTaskServ.create(dtoJobCardTask).toPromise();
  }

}

export interface jobcardTaskData {

  id: number;

  departmentId: number;

  departmentName: string;

  assigneeEmployeeNumber: number;

  assigneeName: number;

  assigneeId: number;

  startDate: Date;

  startTime: string;

  endDate: Date;

  endTime: string;

  actualStartDate: Date;

  actualStartTime: string;

  actualEndDate: Date;

  actualEndTime: string;

  description: string;

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  delayReasonName: string;

  delayReasonId: number;

  otherReason: string;

  jobCardId: number;

  statusId: number;

  finalComment: string;

  jobCardStatus: string;

  jobCardTaskActionId: number;

}
