import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteTrigger } from '@angular/material';
import { JobcardTypeService } from 'src/services/jobcard-type.service';
import { FunctionLocationService } from 'src/services/function-location.service';
import { JobCardService } from 'src/services/job-card.service';
import { DepartmentService } from 'src/services/department.service';
import { Department } from 'src/models/department';
import { CreateUpdateJobCardDTO } from 'src/models/create-update-job-card-dto';
import { HourTimes } from 'src/models/hour-times';
import { ThakaduEquipmentService } from 'src/services/thakadu-equipment.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/services/user.service';
import { SystemUser } from 'src/models/system-user';
import { CreateUpdateJobCardTask } from 'src/models/create-update-job-card-task';
import { Globals } from 'src/app/global-values/globals';
import { JobCardTaskService } from 'src/services/job-card-task.service';
import { SortListPipe } from 'src/app/pipes/sort-list.pipe';

@Component({
  selector: 'app-add-job-card',
  templateUrl: './add-job-card.component.html',
  styleUrls: ['./add-job-card.component.css']
})
export class AddJobCardComponent implements OnInit {

  form: FormGroup;
  loading = false;
  loggedUserId: number;
  btnName: string;
  dsJobCardType: any;
  dsEquipment: any[] = [];
  dsDepartment: any;
  dsPerson: SystemUser[];
  dsTimes: any[];
  minDate: Date;

  equipmentTagControl = new FormControl();
  equipmentTagOptions: Observable<equipTagData[]>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  constructor(private fb: FormBuilder, private apiDepartment: DepartmentService, private apiJobCardTypeServ: JobcardTypeService, private apiEquipmentServ: ThakaduEquipmentService,
    private apiJobCardServ: JobCardService, private apiJobCardTaskServ: JobCardTaskService, private apiPersonServ: UserService, private tokenStorage: TokenstorageService, 
    private sortList : SortListPipe,private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddJobCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {

    let date = new Date();
    this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.createForm();
    this.loadJobCardType();
    this.loadDepartment();
    this.loadJobCarTimes();
    this.loadEquipmentTag();

    this.equipmentTagOptions = this.equipmentTagControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterEquipmentTag(name) : this.dsEquipment.slice())
      );
  }

  displayEquipmentFn(equipment: equipTagData): string {
    return equipment && equipment.instrumentTag ? equipment.instrumentTag : '';
  }

  private _filterEquipmentTag(name: string): equipTagData[] {
    const filterValue = name.toLowerCase();
    return this.dsEquipment.filter(option => option.instrumentTag.toLowerCase().indexOf(filterValue) === 0);
  }

  createForm() {

    let defaultStartTime = new Date().toTimeString().slice(0, 2) + ":00";
    var today = new Date();
    today.setHours(today.getHours() + 1);
    let defaultsEndTime = today.toTimeString().slice(0, 2) + ":00";

    this.form = this.fb.group({
      jobCardTypeId: [null, [Validators.required]],
      reqStartTime: [defaultStartTime, [Validators.required]],
      reqStartDate: [new Date(), [Validators.required]],
      reqEndDate: [new Date(), [Validators.required]],
      reqEndTime: [defaultsEndTime, [Validators.required]],
      description: [null, [Validators.required]],
      departmentIdTo: [null, [Validators.required]],
      equipmentTagControl: [null]

    });
  }


  loadJobCardType() {
    this.apiJobCardTypeServ.getAll().subscribe(data => {
      this.dsJobCardType = data;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadDepartment() {
    this.apiDepartment.getAll().subscribe(data => {
      this.dsDepartment = data as Department[];
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadJobCarTimes() {
    let hourTimes = new HourTimes();
    this.dsTimes = hourTimes.getAllTimes();
  }

  loadEquipmentTag() {
    this.apiEquipmentServ.getAll().subscribe(data => {
      //let ds = data as any[];
      let ds = data as any[];
      const sortedArr = this.sortList.transform(ds, "asc", "instrumentTag");
      for (let i = 0; i < sortedArr.length; i++) {
        var res = {
          id: sortedArr[i].id,
          instrumentTag: sortedArr[i].instrumentTag
        };
        this.dsEquipment.push(res);
      }
      return this.dsEquipment as equipTagData[];
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  onChangeDepartment(event) {
    this.loadSystemPerson(event.value);
  }

  loadSystemPerson(departmenId: number) {
    this.apiPersonServ.getAllUserByDepartmentId(departmenId).subscribe(data => {
      this.dsPerson = data as SystemUser[];
    })
  }

  async onSubmit(btnName) {
    if (btnName == "btnSave") {
      this.loading = true;
      let message = "Please select a tag.Please make use of selection."
      let equipmentTagControl = this.equipmentTagControl.value != null ? this.equipmentTagControl.value : null;
      if (!equipmentTagControl) {
        this.loading = false;
        this.equipmentTagControl.reset();
        this.snackBar.open("Error Message", message, {
          duration: 2000,
        });
        return;
      }

      if (!equipmentTagControl.instrumentTag) {
        this.equipmentTagControl.reset();
        this.loading = false;
        this.snackBar.open("Error Message", message, {
          duration: 2000,
        });
        return
      }

      if (this.form.valid) {
        const jobCardResults = await this.createJobCard(equipmentTagControl.id);        
        const jobCardTaskResults = await this.createDefaultJobCardTask(jobCardResults["id"]); 
        this.loading = false;      
        this.dialogRef.close({ refresh: true });
      } else {
        if (btnName == "btnCancel") {
          this.dialogRef.close();
        }
      }
    }else{
      if (btnName == "btnCancel") {
        this.loading = false;
        this.dialogRef.close();
      }
    }
  }

  createJobCard(tagId: number) {   
    var user = this.tokenStorage.getUser();
    let dtoJobCard = new CreateUpdateJobCardDTO();
    dtoJobCard.setId(0);
    dtoJobCard.setDepartmentId(user.departmentId);
    dtoJobCard.setDepartmentIdTo(this.form.value.departmentIdTo);
    dtoJobCard.setReqStartDate(this.form.value.reqStartDate);
    dtoJobCard.setReqStartTime(this.form.value.reqStartTime);
    dtoJobCard.setReqEndDate(this.form.value.reqEndDate);
    dtoJobCard.setReqEndTime(this.form.value.reqEndTime);
    dtoJobCard.setDescription(this.form.value.description);
    dtoJobCard.setCreatedBy(this.loggedUserId);
    dtoJobCard.setCreatedAt(new Date());
    dtoJobCard.setIsApproved(false);
    dtoJobCard.setStatusId(1);
    dtoJobCard.setIsActive(false);
    dtoJobCard.setSlaPercentage(0);
    dtoJobCard.setJobCardTypeId(this.form.value.jobCardTypeId);
    dtoJobCard.setTagId(tagId);
    dtoJobCard.setRejectionReasonId(0);
    dtoJobCard.setOtherRejectionReason(null);
    return this.apiJobCardServ.create(dtoJobCard).toPromise();
  }

  createDefaultJobCardTask(jobCardId: number) {
    let dtoJobCardTask = new CreateUpdateJobCardTask();
    dtoJobCardTask.setId(0);
    dtoJobCardTask.setCreatedBy(this.loggedUserId);
    dtoJobCardTask.setCreatedAt(new Date());
    dtoJobCardTask.setDepartmentId(this.form.value.departmentIdTo);
    dtoJobCardTask.setAssigneeId(0);
    dtoJobCardTask.setStartDate(this.form.value.reqStartDate);
    dtoJobCardTask.setStartTime(this.form.value.reqStartTime);
    dtoJobCardTask.setEndDate(this.form.value.reqEndDate);
    dtoJobCardTask.setEndTime(this.form.value.reqEndTime);
    dtoJobCardTask.setJobCardId(jobCardId);
    dtoJobCardTask.setDescription(this.form.value.description);
    let global = new Globals();
    dtoJobCardTask.setStatusId(global.jobCardStatusAwaitingId);
    dtoJobCardTask.setJobCardTaskActionId(1);
    return this.apiJobCardTaskServ.create(dtoJobCardTask).toPromise();
  }

}

export interface equipTagData {
  id: number
  instrumentTag: string;
}