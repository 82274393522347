import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipmentCategoryFailureTypeLinkService {

  API_URL: string = environment.serverUrl + "/equipmentCategoryFailureTypeLink";

  constructor(private http : HttpClient) { }

  getAll(): Observable<any> {
    return this
      .http
      .get(`${this.API_URL}`);
  }

  getById(id : number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  } 

  getAllEquipmentCategoryFailureTypeLinkByEquipmentCategoryId(equipmentCategoryId : number) {   
    return this
      .http
      .get(`${this.API_URL}/getAllEquipmentCategoryFailureTypeLinkByEquipmentCategoryId/${equipmentCategoryId}`);    
  }

  create(equipmentFailureType: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, equipmentFailureType);
  }

  bulkInsertEquipmentCategoryFailureTypeLink(equipmentFailureType: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}/bulkInsertEquipmentCategoryFailureTypeLink`, equipmentFailureType);
  }

}