import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobCardTaskEquipmentFailureActionService {

  API_URL: string = environment.serverUrl + "/jobCardTaskEquipmentFailureAction";

  constructor(private http : HttpClient) { }

  getById(id : number) {
    return this
      .http
      .get(`${this.API_URL}/${id}`);
  } 

  getAllJobCardTaskEquipmentFailureActionByJobCardId(jobCardId : number) {   
    return this
      .http
      .get(`${this.API_URL}/getAllJobCardTaskEquipmentFailureActionByJobCardId/${jobCardId}`);    
  }

  create(createUpdateJobCardTaskEquipmentFailureAction: object): Observable<object> {
    return this
      .http
      .post(`${this.API_URL}`, createUpdateJobCardTaskEquipmentFailureAction);
  }

  bulkInsertJobCardTaskEquipmentFailureAction(createUpdateJobCardTaskEquipmentFailureAction: object): Observable<object> {
    debugger;
    return this
      .http
      .post(`${this.API_URL}/bulkInsertJobCardTaskEquipmentFailureAction`, createUpdateJobCardTaskEquipmentFailureAction);
  }

}
