
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './home/home.component';
import { AdministratorComponent } from './administrator/administrator.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ListusersComponent } from './listusers/listusers.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { authInterceptorProviders } from './_helper/AuthInterceptor';
import { AddUserComponent } from './dialogs/add-user/add-user.component';
import { EditUserComponent } from './dialogs/edit-user/edit-user.component';
import { ListEntityComponent } from './list-entity/list-entity.component';
import { ListRolesComponent } from './list-roles/list-roles.component';
import { AddEntityComponent } from './dialogs/add-entity/add-entity.component';
import { EditEntityComponent } from './dialogs/edit-entity/edit-entity.component';
import { AddRoleComponent } from './dialogs/add-role/add-role.component';
import { EditRoleComponent } from './dialogs/edit-role/edit-role.component';
import { ListDepartmentComponent } from './list-department/list-department.component';
import { AddDepartmentComponent } from './dialogs/add-department/add-department.component';
import { EditDepartmentComponent } from './dialogs/edit-department/edit-department.component';
import { ListEquipmentComponent } from './list-equipment/list-equipment.component';
import { ListCompanyTypeComponent } from './list-company-type/list-company-type.component';
import { AddEditcompanyTypeComponent } from './dialogs/add-editcompany-type/add-editcompany-type.component';
import { ListCompanyComponent } from './list-company/list-company.component';
import { AddCompanyComponent } from './dialogs/add-company/add-company.component';
import { EditCompanyComponent } from './dialogs/edit-company/edit-company.component';
import { ListEquipmentTypeComponent } from './list-equipment-type/list-equipment-type.component';
import { AddEditEquipmentTypeComponent } from './dialogs/add-edit-equipment-type/add-edit-equipment-type.component';
import { AddEquipmentComponent } from './dialogs/add-equipment/add-equipment.component';
import { EditEquipmentComponent } from './dialogs/edit-equipment/edit-equipment.component';
import { ListJobcardTypeComponent } from './list-jobcard-type/list-jobcard-type.component';
import { EditJobCarTypeComponent } from './dialogs/edit-job-car-type/edit-job-car-type.component';
import { AddJobCarTypeComponent } from './dialogs/add-job-car-type/add-job-car-type.component';
import { ListSupFunctionalLocationComponent } from './list-sup-functional-location/list-sup-functional-location.component';
import { AddSupFunctionalLocationComponent } from './dialogs/add-sup-functional-location/add-sup-functional-location.component';
import { EditSupFunctionalLocationComponent } from './dialogs/edit-sup-functional-location/edit-sup-functional-location.component';
import { ListFunctionLocationComponent } from './list-function-location/list-function-location.component';
import { AddFunctionLocationComponent } from './dialogs/add-function-location/add-function-location.component';
import { EditFunctionLocationComponent } from './dialogs/edit-function-location/edit-function-location.component';
import { ListJobCardsComponent } from './list-job-cards/list-job-cards.component';
import { AddJobCardComponent } from './dialogs/add-job-card/add-job-card.component';
import { EditJobCardComponent } from './dialogs/edit-job-card/edit-job-card.component';
import { AddJobCardTaskComponent } from './dialogs/add-job-card-task/add-job-card-task.component';
import { EditJobCardTaskComponent } from './dialogs/edit-job-card-task/edit-job-card-task.component';
import { ListThakaduEquipmentComponent } from './list-thakadu-equipment/list-thakadu-equipment.component';
import { AddEditThakaduEquipmentComponent } from './dialogs/add-edit-thakadu-equipment/add-edit-thakadu-equipment.component';
import { ListDocumentsComponent } from './list-documents/list-documents.component';
import { ListDocumentTypesComponent } from './list-document-types/list-document-types.component';
import { AddEditDocumentTypeComponent } from './dialogs/add-edit-document-type/add-edit-document-type.component';
import { AddEditDepartmentDocumentComponent } from './dialogs/add-edit-department-document/add-edit-department-document.component';
import { AddEditDocumentUploadComponent } from './dialogs/add-edit-document-upload/add-edit-document-upload.component';
import { ListUserDocumentAccessComponent } from './list-user-document-access/list-user-document-access.component';
import { AddEditDocumentAccessComponent } from './dialogs/add-edit-document-access/add-edit-document-access.component';
import { ConfirmDeleteDocumentComponent } from './dialogs/confirm-delete-document/confirm-delete-document.component';
import { ConfirmApproveDocumentMessageComponent } from './list-user-document-access/confirm-approve-document-message/confirm-approve-document-message.component';
import { ListReportsComponent } from './list-reports/list-reports.component';
import { ReportDepartmentDateRangeComponent } from './report-department-date-range/report-department-date-range.component';
import { ListInterLocksComponent } from './list-inter-locks/list-inter-locks.component';
import { AddEditInterLockComponent } from './dialogs/add-edit-inter-lock/add-edit-inter-lock.component';
import { SortListPipe } from './pipes/sort-list.pipe';
import { Globals } from './global-values/globals';
import { ListDepartmentScheduleComponent } from './list-department-schedule/list-department-schedule.component';
import { AddEditDepartmentJobCardScheduleComponent } from './list-department-schedule/add-edit-department-job-card-schedule/add-edit-department-job-card-schedule.component';
import { AddEditJobCardCalenderPickerComponent } from './list-department-schedule/add-edit-job-card-calender-picker/add-edit-job-card-calender-picker.component';
//import { ChartsModule } from 'ng2-charts';
import { MaterialModuleModule } from './material-module/material-module.module';
import { Router, RouterModule, ROUTES } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { ListRoutineMaintenanceTagPlanComponent } from './list-routine-maintenance-tag-plan/list-routine-maintenance-tag-plan.component';
import { AddEditRoutineMaintenanceTagPlanComponent } from './dialogs/add-edit-routine-maintenance-tag-plan/add-edit-routine-maintenance-tag-plan.component';
import { ListEquipmentCategoryComponent } from './list-equipment-category/list-equipment-category.component';
import { AddEditEquipmentCategoryComponent } from './dialogs/add-edit-equipment-category/add-edit-equipment-category.component';
import { ListEquipmentComponentComponent } from './list-equipment-component/list-equipment-component.component';
import { AddEditEquipmentComponentComponent } from './dialogs/add-edit-equipment-component/add-edit-equipment-component.component';
import { ListEquipmentFailureTypeComponent } from './list-equipment-failure-type/list-equipment-failure-type.component';
import { AddEditEquipmentFailureTypeComponent } from './dialogs/add-edit-equipment-failure-type/add-edit-equipment-failure-type.component';
import { ListEquipmentFailureRootCauseComponent } from './list-equipment-failure-root-cause/list-equipment-failure-root-cause.component';
import { AddEditEquipmentFailureRootCauseComponent } from './dialogs/add-edit-equipment-failure-root-cause/add-edit-equipment-failure-root-cause.component';
import { ListEquipmentRepairTypeComponent } from './list-equipment-repair-type/list-equipment-repair-type.component';
import { AddEditEquipmentRepairTypeComponent } from './dialogs/add-edit-equipment-repair-type/add-edit-equipment-repair-type.component';
import { ListEquipmentRepairTypeSymptomLinksComponent } from './list-equipment-repair-type-symptom-links/list-equipment-repair-type-symptom-links.component';

@NgModule({
  declarations: [
    AppComponent,    
    NavigationComponent,
    HomeComponent,
    AdministratorComponent,
    LoginComponent,
    NotFoundComponent,
    ListusersComponent,
    AdminMenuComponent,
    AddUserComponent,
    EditUserComponent,
    ListEntityComponent,
    ListRolesComponent,
    AddEntityComponent,
    EditEntityComponent,
    AddRoleComponent,
    EditRoleComponent,
    ListDepartmentComponent,
    AddDepartmentComponent,
    EditDepartmentComponent,
    ListEquipmentComponent,
    ListCompanyTypeComponent,
    AddEditcompanyTypeComponent,
    ListCompanyComponent,
    AddCompanyComponent,
    EditCompanyComponent,
    ListEquipmentTypeComponent,
    AddEditEquipmentTypeComponent,
    AddEquipmentComponent,
    EditEquipmentComponent,
    ListJobcardTypeComponent,
    EditJobCarTypeComponent,
    AddJobCarTypeComponent,
    ListSupFunctionalLocationComponent,
    AddSupFunctionalLocationComponent,
    EditSupFunctionalLocationComponent,
    ListFunctionLocationComponent,
    AddFunctionLocationComponent,
    EditFunctionLocationComponent,
    ListJobCardsComponent,
    AddJobCardComponent,
    EditJobCardComponent,
    AddJobCardTaskComponent,
    EditJobCardTaskComponent,
    ListThakaduEquipmentComponent,
    AddEditThakaduEquipmentComponent,
    ListDocumentsComponent,
    ListDocumentTypesComponent,
    AddEditDocumentTypeComponent,
    AddEditDepartmentDocumentComponent,
    AddEditDocumentUploadComponent,
    ListUserDocumentAccessComponent,
    AddEditDocumentAccessComponent,
    ConfirmDeleteDocumentComponent,
    ConfirmApproveDocumentMessageComponent,
    ListReportsComponent,
    ReportDepartmentDateRangeComponent,
    ListInterLocksComponent,
    AddEditInterLockComponent,
    SortListPipe,
    ListDepartmentScheduleComponent,
    AddEditDepartmentJobCardScheduleComponent,
    AddEditJobCardCalenderPickerComponent,
    ListRoutineMaintenanceTagPlanComponent,
    AddEditRoutineMaintenanceTagPlanComponent,
    ListEquipmentCategoryComponent,
    AddEditEquipmentCategoryComponent,
    ListEquipmentComponentComponent,
    AddEditEquipmentComponentComponent,
    ListEquipmentFailureTypeComponent,
    AddEditEquipmentFailureTypeComponent,
    ListEquipmentFailureRootCauseComponent,
    AddEditEquipmentFailureRootCauseComponent,
    ListEquipmentRepairTypeComponent,
    AddEditEquipmentRepairTypeComponent,
    ListEquipmentRepairTypeSymptomLinksComponent
  ], 
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModuleModule,
    FormsModule,
    ReactiveFormsModule ,
    ChartsModule,   
  ], 
  entryComponents: [
    AddUserComponent,
    EditUserComponent,
    AddEntityComponent,
    EditEntityComponent,
    AddRoleComponent,
    EditRoleComponent,
    AddDepartmentComponent,
    EditDepartmentComponent,
    AddEditEquipmentTypeComponent,
    AddEquipmentComponent,
    EditEquipmentComponent,
    AddJobCarTypeComponent,
    EditJobCarTypeComponent,
    AddJobCardComponent,
    EditJobCardComponent,
    AddJobCardTaskComponent,
    AddEditThakaduEquipmentComponent,
    AddEditDocumentUploadComponent,
    ConfirmDeleteDocumentComponent,
    AddEditInterLockComponent,
    AddEditDepartmentJobCardScheduleComponent,
    AddEditJobCardCalenderPickerComponent
  ],
  providers: [authInterceptorProviders,Globals,SortListPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
