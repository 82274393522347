import { Component, OnInit, Inject } from '@angular/core';
import { Router, Data } from '@angular/router';
import { CompanyTypeService } from 'src/services/company-type.service';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { CompanyService } from 'src/services/company.service';
import { ContactService } from 'src/services/contact.service';


@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

  loading: boolean = true;
  form: FormGroup;
  btnName: string;
  dsCompanyType: any;
  loggedUserId: number;
  dsContactInfo : any;

  constructor(private fb: FormBuilder, private router: Router, private apiCompanyTypeServ: CompanyTypeService, private apiCompanyServ : CompanyService,private apiContactServ: ContactService, private tokenStorage: TokenstorageService, private dialog: MatDialog, private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditCompanyComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.loadCompanyType();    
    this.loadFormData() ;
  }

  loadFormData() {
    this.loading = true;
    this.form.patchValue(
    {
      companyTypeId: this.data.companyTypeId,
      companyName : this.data.companyName,
      description : this.data.description
    });
    this.apiContactServ.getByEntityId(this.data["id"], 'Company').subscribe(dataContact => {  
      this.loading = false;
      this.dsContactInfo = dataContact;
      this.form.patchValue(
      {
        physicalAddressLine1: this.dsContactInfo[0].physicalAddressLine1,
        physicalAddressLine2: this.dsContactInfo[0].physicalAddressLine2,
        physicalAddressCode: this.dsContactInfo[0].physicalAddressCode,
        postalAddressLine1: this.dsContactInfo[0].postalAddressLine1,
        postalAddressLine2: this.dsContactInfo[0].postalAddressLine2,
        postalAddressCode: this.dsContactInfo[0].postalAddressCode,
        faxNumber: this.dsContactInfo[0].faxNumber,
        phoneNumber: this.dsContactInfo[0].phoneNumber,
        cell: this.dsContactInfo[0].cellNumber,
        email: this.dsContactInfo[0].email
      });

    });

  }

  createForm() {
    this.form = this.fb.group({
      companyTypeId: [null, [Validators.required]],
      companyName: [null, [Validators.required]],
      description: [null, Validators.required],
      cell: [""],
      email: ["", Validators.required],
      phoneNumber: [""],
      faxNumber: [""],
      physicalAddressLine1: ["", Validators.required],
      physicalAddressLine2: ["", Validators.required],
      physicalAddressCode: ["", Validators.required],
      postalAddressLine1: [""],
      postalAddressLine2: [""],
      postalAddressCode: [""] 
    });
  }

  loadCompanyType() {
    this.loading = true;
    this.apiCompanyTypeServ.getAll().subscribe(data => {
      this.dsCompanyType = data;     
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  }

  async onSubmit(btnName) {
    if (btnName == "btnSave") {
      this.loading = true;
      try {
        const companyName = await this.submitCompany();
        const contact = await this.submitContact(companyName["id"]);
        this.dialogRef.close();
      } catch (error) {
        this.snackBar.open("Error Message", error, {
          duration: 2000,
        });
        return;
      }
    } else {
      if (btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

  submitCompany(){
    var formData = this.form.value;
    formData.editedBy = this.loggedUserId;
    formData.editedAt = new Date();
    formData.id = this.data.id;      
    return this.apiCompanyServ.create(formData).toPromise();
  }

  submitContact(entityId : number){
    var formContactData = {
      id: this.dsContactInfo[0].id,
      physicalAddressLine1: this.form.value.physicalAddressLine1,
      physicalAddressLine2: this.form.value.physicalAddressLine2,
      physicalAddressCode: this.form.value.physicalAddressCode,
      postalAddressLine1: this.form.value.postalAddressLine1,
      postalAddressLine2: this.form.value.postalAddressLine2,
      postalAddressCode: this.form.value.postalAddressCode,
      faxNumber: this.form.value.faxNumber,
      phoneNumber: this.form.value.phoneNumber,
      cellNumber: this.form.value.cell,
      email: this.form.value.email,
      isActive: true,
      entityName: "Company",
      entityId: entityId,
    }
    return this.apiContactServ.create(formContactData).toPromise();
  }

}
