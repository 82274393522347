export class CreateUpdateInterLockByPassRequestDTO {

    id : number;

    departmentId : number;

    createdBy : number;

    createdAt : Date;

    editedBy : number;

    editedAt : Date;

    departmentIdTo : number;

    tagId : number;

    processRisk : String;

    engineeringRisk : String;

    productionRisk  : string;    

    isActive : boolean;

    statusDate : Date;

    statusId : number;

    startDate : Date;

    endDate : Date;

    stateId : number;

    allocatedTo : number;

    approvedBy : number;

    approvedDate : Date;

    comment : string;

    requestDesc :  string;

    interLocks : string;

}
