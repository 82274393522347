export class CreateUpdateJobCardDTO {

    private id: number;

    private departmentId: number;

    private departmentIdTo: number;

    private orderNumber: string; 

    private orderNumberDate :  Date; 

    private orderNumberGenBy : number;

    private reqStartDate: Date;

    private reqStartTime: String;

    private reqEndDate: Date;

    private reqEndTime: String;

    private startDate : Date;

    private startTime: string;

    private endDate : Date;

    private endTime: string;

    private completedOn: Date;

    private description: string;

    private createdBy: number;

    private createdAt: Date;

    private editedBy: number;

    private editedAt: Date;

    private isApproved: boolean;

    private statusId: number;

    private statusDate: Date;

    private isActive: boolean;

    private slaPercentage: number;

    private jobCardTypeId: number;

    private tagId: number;

    private rejectionReasonId : number	

    private otherRejectionReason : string;

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getDepartmentId(): number {
        return this.departmentId;
    }

    public setDepartmentId(departmentId: number): void {
        this.departmentId = departmentId;
    }

    public getDepartmentIdTo(): number {
        return this.departmentIdTo;
    }

    public setDepartmentIdTo(departmentIdTo: number): void {
        this.departmentIdTo = departmentIdTo;
    }

    public getOrderNumber(): string {
        return this.orderNumber;
    }

    public setOrderNumber(orderNumber: string): void {
        this.orderNumber = orderNumber;
    }

    public getOrderNumberDate(): Date {
        return this.orderNumberDate;
    }

    public setOrderNumberDate(orderNumberDate: Date): void {
        this.orderNumberDate = orderNumberDate;
    }

    public getOrderNumberGenBy(): number {
        return this.orderNumberGenBy;
    }

    public setOrderNumberGenBy(orderNumberGenBy: number): void {
        this.orderNumberGenBy = orderNumberGenBy;
    }

    public getReqStartDate(): Date {
        return this.reqStartDate;
    }

    public setReqStartDate(reqStartDate: Date): void {
        this.reqStartDate = reqStartDate;
    }

    public getReqStartTime(): String {
        return this.reqStartTime;
    }

    public setReqStartTime(reqStartTime: String): void {
        this.reqStartTime = reqStartTime;
    }

    public getReqEndDate(): Date {
        return this.reqEndDate;
    }

    public setReqEndDate(reqEndDate: Date): void {
        this.reqEndDate = reqEndDate;
    }

    public getReqEndTime(): String {
        return this.reqEndTime;
    }

    public setReqEndTime(reqEndTime: String): void {
        this.reqEndTime = reqEndTime;
    }

    public getStartDate(): Date {
        return this.startDate;
    }

    public setStartDate(startDate: Date): void {
        this.startDate = startDate;
    }

    public getStartTime(): string {
        return this.startTime;
    }

    public setStartTime(startTime: string): void {
        this.startTime = startTime;
    }

    public getEndDate(): Date {
        return this.endDate;
    }

    public setEndDate(endDate: Date): void {
        this.endDate = endDate;
    }

    public getEndTime(): string {
        return this.endTime;
    }

    public setEndTime(endTime: string): void {
        this.endTime = endTime;
    }

    public getCompletedOn(): Date {
        return this.completedOn;
    }

    public setCompletedOn(completedOn: Date): void {
        this.completedOn = completedOn;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string): void {
        this.description = description;
    }

    public getCreatedBy(): number {
        return this.createdBy;
    }

    public setCreatedBy(createdBy: number): void {
        this.createdBy = createdBy;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(createdAt: Date): void {
        this.createdAt = createdAt;
    }

    public getEditedBy(): number {
        return this.editedBy;
    }

    public setEditedBy(editedBy: number): void {
        this.editedBy = editedBy;
    }

    public getEditedAt(): Date {
        return this.editedAt;
    }

    public setEditedAt(editedAt: Date): void {
        this.editedAt = editedAt;
    }

    public isIsApproved(): boolean {
        return this.isApproved;
    }

    public setIsApproved(isApproved: boolean): void {
        this.isApproved = isApproved;
    }

    public getStatusId(): number {
        return this.statusId;
    }

    public setStatusId(statusId: number): void {
        this.statusId = statusId;
    }

    public getStatusDate(): Date {
        return this.statusDate;
    }

    public setStatusDate(statusDate: Date): void {
        this.statusDate = statusDate;
    }

    public isIsActive(): boolean {
        return this.isActive;
    }

    public setIsActive(isActive: boolean): void {
        this.isActive = isActive;
    }

    public getSlaPercentage(): number {
        return this.slaPercentage;
    }

    public setSlaPercentage(slaPercentage: number): void {
        this.slaPercentage = slaPercentage;
    }

    public getJobCardTypeId(): number {
        return this.jobCardTypeId;
    }

    public setJobCardTypeId(jobCardTypeId: number): void {
        this.jobCardTypeId = jobCardTypeId;
    }

    public getTagId(): number {
        return this.tagId;
    }

    public setTagId(tagId: number): void {
        this.tagId = tagId;
    }

    public getRejectionReasonId(): number {
        return this.rejectionReasonId;
    }

    public setRejectionReasonId(rejectionReasonId: number): void {
        this.rejectionReasonId = rejectionReasonId;
    }

    public getOtherRejectionReason(): string {
        return this.otherRejectionReason;
    }

    public setOtherRejectionReason(otherRejectionReason: string): void {
        this.otherRejectionReason = otherRejectionReason;
    }
        
}
