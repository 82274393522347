<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Add User
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-select formControlName="titleName" matInput placeholder="Title">
                        <mat-option *ngFor="let item of dsTitle" [value]="item.titleName">
                            {{item.titleName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName" required>               
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName" required>                
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Id Number</mat-label>
                    <input matInput formControlName="idNumber" required>                
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Employee Number</mat-label>
                    <input matInput formControlName="employeeNumber" required>               
                </mat-form-field>
            </div>            
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-select formControlName="systemRoleId" matInput placeholder="Role">
                        <mat-option *ngFor="let item of dsRoles" [value]="item.id">
                            {{item.roleName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-select formControlName="systemEntityId" matInput placeholder="Entity">
                        <mat-option *ngFor="let item of dsEntity" [value]="item.id">
                            {{item.systemEntityName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>             
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Log UserName</mat-label>
                    <input matInput formControlName="logUserName" required>
                </mat-form-field>
            </div>  
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="logUserPassword" required>               
                </mat-form-field>
            </div> 
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-select formControlName="departmentId" matInput placeholder="Department">
                        <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>             
        </div>
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')" [disabled]="!form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>


