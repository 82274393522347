import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { RoutineMaintenanceTypeService } from 'src/services/routine-maintenance-type.service';
import { RoutineMaintenanceTagPlanService } from 'src/services/routine-maintenance-tag-plan.service';
import { ThakaduEquipmentService } from 'src/services/thakadu-equipment.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DepartmentService } from 'src/services/department.service';
import { JobcardTypeService } from 'src/services/jobcard-type.service';

@Component({
  selector: 'app-add-edit-routine-maintenance-tag-plan',
  templateUrl: './add-edit-routine-maintenance-tag-plan.component.html',
  styleUrls: ['./add-edit-routine-maintenance-tag-plan.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditRoutineMaintenanceTagPlanComponent implements OnInit {

  pageTitle: string = "";
  form: FormGroup;
  loading = false;
  loggedUserId: number;
  btnName: string;
  dsEquipment: any[] = [];
  equipmentTagControl = new FormControl();
  dsRoutineMatenanceType : any[] = [];
  dsDepartment : any[] = [];
  dsJobCardType : any[] = [];
  minDate: Date;
  hideButtons: boolean = true;
  equipmentTagOptions: Observable<equipTagData[]>;

  constructor(private fb: FormBuilder, private apiRoutineMaintenanceTypeServ: RoutineMaintenanceTypeService, private apiEquipmentServ: ThakaduEquipmentService,
     private apiRoutineMaintenanceTagPlanServ: RoutineMaintenanceTagPlanService, private apiSystemDepartmentServ : DepartmentService, private apiJobCardTypeServ : JobcardTypeService,
    private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditRoutineMaintenanceTagPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
     let currentDate = new Date();
     currentDate.setDate(currentDate.getDate() + 1);
     this.minDate = currentDate;
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.loadEquipmentTag();
    this.loadSystemDepartment();
    this.loadJobCardType();
    if (this.data) {
      this.pageTitle = "Edit Routine Maintenance Job";
      this.form.patchValue(
        {
          routineMaintenanceTypeId : this.data.routineMaintenanceTypeId,
          jobCardTypeId : this.data.jobCardTypeId,
          startDate : new Date(this.data.startDate),
          frequencyDays : this.data.frequencyDays,
          frequecyRepeat : this.data.frequecyRepeat == true ? "true" : "false",
          departmentId : this.data.departmentId,
          instruction : this.data.instruction 
        });     
    } else {
      this.pageTitle = "Add Routine Maintenance Job";
    }
    this.loadRoutineMaintenanceType();
    this.equipmentTagOptions = this.equipmentTagControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.instrumentTag),
      map(instrumentTag => instrumentTag ? this._filterEquipmentTag(instrumentTag) : this.dsEquipment.slice())
    );
  }

  displayEquipmentFn(equipment: equipTagData): string {
    return equipment && equipment.instrumentTag ? equipment.instrumentTag : '';
  }

  private _filterEquipmentTag(name: string): equipTagData[] {
    const filterValue = name.toLowerCase();
    return this.dsEquipment.filter(option => option.instrumentTag.toLowerCase().indexOf(filterValue) === 0);
  }

  loadEquipmentTag() {
    if (this.data) {
      this.equipmentTagControl.setValue({ id: this.data["tagId"], instrumentTag: this.data["tagName"] });
    }
    this.apiEquipmentServ.getEquipmentAutoComplete().subscribe(data => {
      this.dsEquipment = data as equipTagData[];
      return this.dsEquipment;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadRoutineMaintenanceType() {
    this.apiRoutineMaintenanceTypeServ.getAll().subscribe(data => {
      this.dsRoutineMatenanceType = data as [];
    },error => {
      console.log(error);
    });
  }

  loadSystemDepartment() {
    this.apiSystemDepartmentServ.getAll().subscribe(data => {
      this.dsDepartment = data as [];
    },error =>{
      console.log(error);
    })
  }

  loadJobCardType() {
     this.apiJobCardTypeServ.getAll().subscribe(data => {
      this.dsJobCardType = data as [];
     },error =>{
      console.log(error);
     });
  }

  createForm() {
    this.form = this.fb.group({
      "routineMaintenanceTypeId" : [null, [Validators.required]],
      "jobCardTypeId": [{value: 1}, [Validators.required]],
      "startDate" : [null, [Validators.required]],
      "frequencyDays" : [0, [Validators.required]],
      "frequecyRepeat" : [null, [Validators.required]],
      "departmentId": [null, [Validators.required]],
      "instruction" : [null, [Validators.required]]
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      let equipmentTagControl = this.equipmentTagControl.value != null ? this.equipmentTagControl.value : null;
      var formData = this.form.value;
      if (this.data) {
        formData.jobCardTypeId = formData.jobCardTypeId;
        formData.editedBy = this.loggedUserId;
        formData.tagId = equipmentTagControl.id;
        formData.editedAt = new Date();
        formData.id = this.data.id;
      } else {
        formData.id = 0;
        formData.jobCardTypeId = formData.jobCardTypeId;
        formData.tagId = equipmentTagControl.id;
        formData.createdBy = this.loggedUserId;
        formData.createdAt = new Date();
      }
      this.apiRoutineMaintenanceTagPlanServ.create(formData).subscribe(data => {       
        this.dialogRef.close({ refresh : true});
      });
    } else {
      if(btnName == "btnCancel"){
        this.dialogRef.close();
      }
    }
  }
  
}

export interface equipTagData {
  id: number,
  instrumentTag: string;
}
