import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { SortListPipe } from 'src/app/pipes/sort-list.pipe';
import { EquipmentRepairTypeService } from 'src/services/equipment-repair-type.service';
import { EquipmentFailureRootCauseService } from 'src/services/equipment-failure-root-cause.service';
import { EquipmentFailureTypeService } from 'src/services/equipment-failure-type.service';
import { EquipmentComponentService } from 'src/services/equipment-component.service';
import { EquipmentCategoryService } from 'src/services/equipment-category.service';
import { EquipmentCategoryRootCauseFailureLinkService } from 'src/services/equipment-category-root-cause-failure-link.service';
import { EquipmentCategoryRepairTypeLinkService } from 'src/services/equipment-category-repair-type-link.service';
import { EquipmentCategoryComponentLinkService } from 'src/services/equipment-category-component-link.service';
import { MatSnackBar } from '@angular/material';
import { EquipmentCategoryFailureTypeLinkService } from 'src/services/equipment-category-failure-type-link.service';

@Component({
  selector: 'app-list-equipment-repair-type-symptom-links',
  templateUrl: './list-equipment-repair-type-symptom-links.component.html',
  styleUrls: ['./list-equipment-repair-type-symptom-links.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListEquipmentRepairTypeSymptomLinksComponent implements OnInit {
  form: FormGroup;
  loading = false;
  loggedUserId: number;
  btnName: string;
  dsEquipmentCategory: any[] = [];
  dsRepairType: any[] = [];
  dsRootCause: any[] = [];
  dsFailureType: any[] = [];
  dsComponent: any[] = [];

  constructor(private fb: FormBuilder, private apiEquipmentCategoryServ: EquipmentCategoryService, private tokenStorage: TokenstorageService,
    private apiRepairTypeServ: EquipmentRepairTypeService, private apiEquipmentRootCauseServ: EquipmentFailureRootCauseService,
    private apiFailureTypeServ: EquipmentFailureTypeService, private apiLoadComponentServ: EquipmentComponentService,
    private apiRootEquipmentCategoryCauseLinkServ: EquipmentCategoryRootCauseFailureLinkService, private apiRepairTypeEquipmentCategoryLinkServ: EquipmentCategoryRepairTypeLinkService,
    private apiComponentEquipmentCategoryLinkServ: EquipmentCategoryComponentLinkService, private apiFailureTypeEquipmentCategoryLinkServ: EquipmentCategoryFailureTypeLinkService,
    private sortList: SortListPipe, private snackBar: MatSnackBar) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.loadEquipmentCategory();
    this.loadRepairType();
    this.loadRootCauses();
    this.loadFailureType();
    this.LoadComponents();    
  }

  createForm() {   
    this.form = this.fb.group({
      equipmentCategoryId:  [null, [Validators.required]],
      repairType: [null, [Validators.required]],
      failureType: [null, [Validators.required]],
      component: [null, [Validators.required]],
      rootCause: [null, [Validators.required]]
    });
  }
  
    

  async onSelectEquipmentCategory(event) {
    let equipmentCategoryId = event.value;
    const listEquipmentCategoryComponentLinked = await this.getAllEquipmentCategoryComponentLinkByCategoryId(equipmentCategoryId) as any[];
    let listEquipmentCategoryComponentLinkedResults: any[] = [];
    for (let i = 0; i < listEquipmentCategoryComponentLinked.length; i++) {
      let equipmentComponentId = listEquipmentCategoryComponentLinked[i].equipmentComponentId;
      if (listEquipmentCategoryComponentLinkedResults.indexOf(listEquipmentCategoryComponentLinked[i]) === -1) {
        listEquipmentCategoryComponentLinkedResults.push(equipmentComponentId);
      }
    }

    const listEquipmentCategoryRootCauseLink = await this.getAllEquipmentCategoryRootCauseLinkByCategoryId(equipmentCategoryId) as any[];
    let listEquipmentCategoryRootCauseLinkResult: any[] = [];
    for (let i = 0; i < listEquipmentCategoryRootCauseLink.length; i++) {
      let equipmentFailureRootCauseId = listEquipmentCategoryRootCauseLink[i].equipmentFailureRootCauseId;
        if(listEquipmentCategoryRootCauseLinkResult.indexOf(listEquipmentCategoryRootCauseLink[i]) === -1){
          listEquipmentCategoryRootCauseLinkResult.push(equipmentFailureRootCauseId);
        }    
    }

    const listEquipmentCategoryRepairTypeLink = await this.getAllEquipmentCategoryRepairTypeLinkByCategoryId(equipmentCategoryId) as any[];
    let listEquipmentCategoryRepairTypeLinkResult: any[] = [];
    for (let i = 0; i < listEquipmentCategoryRepairTypeLink.length; i++) {
      let equipmentFailureRootCauseId = listEquipmentCategoryRepairTypeLink[i].equipmentRepairTypeId;
      if (listEquipmentCategoryRepairTypeLinkResult.indexOf(listEquipmentCategoryRepairTypeLink[i]) === -1) {
        listEquipmentCategoryRepairTypeLinkResult.push(equipmentFailureRootCauseId);
      }
    }

    const listEquipmentCategoryFailureTypeLink = await this.getAllEquipmentCategoryFailureTypeLinkByCategoryId(equipmentCategoryId) as any[];
    let listEquipmentCategoryFailureTypeLinkResult: any[] = [];
    for (let i = 0; i < listEquipmentCategoryFailureTypeLink.length; i++) {
      let equipmentFailureTypeId = listEquipmentCategoryFailureTypeLink[i].equipmentFailureTypeId;
      if(listEquipmentCategoryFailureTypeLinkResult.indexOf(listEquipmentCategoryFailureTypeLink[i]) === -1){
        listEquipmentCategoryFailureTypeLinkResult.push(equipmentFailureTypeId);
      }
    }

    this.form.patchValue(
      {
        equipmentCategoryId: this.form.value.equipmentCategoryId,
        repairType: listEquipmentCategoryRepairTypeLinkResult,
        failureType: listEquipmentCategoryFailureTypeLinkResult,
        component: listEquipmentCategoryComponentLinkedResults,
        rootCause: listEquipmentCategoryRootCauseLinkResult,
      });
  }

  getAllEquipmentCategoryComponentLinkByCategoryId(equipmentCategoryId: number) {
    return this.apiComponentEquipmentCategoryLinkServ.getAllEquipmentCategoryComponentLinkByCategoryId(equipmentCategoryId).toPromise();
  }

  getAllEquipmentCategoryRootCauseLinkByCategoryId(equipmentCategoryId: number) {
    return this.apiRootEquipmentCategoryCauseLinkServ.getAllEquipmentCategoryRootCauseLinkByCategoryId(equipmentCategoryId).toPromise();
  }

  getAllEquipmentCategoryRepairTypeLinkByCategoryId(equipmentCategoryId: number) {
    return this.apiRepairTypeEquipmentCategoryLinkServ.getAllEquipmentCategoryRepairTypeLinkByCategoryId(equipmentCategoryId).toPromise();
  }

  getAllEquipmentCategoryFailureTypeLinkByCategoryId(equipmentCategoryId: number) {
    return this.apiFailureTypeEquipmentCategoryLinkServ.getAllEquipmentCategoryFailureTypeLinkByEquipmentCategoryId (equipmentCategoryId).toPromise();
  }

  loadEquipmentCategory() {
    this.loading = true;
    this.apiEquipmentCategoryServ.getAll().subscribe(data => {
      this.loading = false;
      this.dsEquipmentCategory = data as any[];
    }, error => {
      this.loading = false;
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadRepairType() {
    this.loading = true;
    this.apiRepairTypeServ.getAll().subscribe(data => {
      this.dsRepairType = data as any[];
      this.loading = false;
    }, error => {
      this.loading = false;
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });      
    });
  }

  loadRootCauses() {
    this.loading = true;
    this.apiEquipmentRootCauseServ.getAll().subscribe(data => {
      this.dsRootCause = data as any[];
      this.loading = false;
    }, error => {
      this.loading = false;
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });      
    });
  }

  loadFailureType() {
    this.loading = true;
    this.apiFailureTypeServ.getAll().subscribe(data => {
      this.loading = false;
      this.dsFailureType = data as any[];
    }, error => {
      this.loading = false;
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });     
    });
  }

  LoadComponents() {
    this.loading = true;
    this.apiLoadComponentServ.getAll().subscribe(data => {
      this.loading = false;
      this.dsComponent = data as any[];
    }, error => {
      this.loading = false;
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });      
    });
  }

  async onSubmit(btnName) {
    if (btnName == "btnSave") {
      this.loading = true;      
      var formData = this.form.value;
      //Insert Root Cause    
      let selectedRootCauseList = this.form.value.rootCause;
      let selectedEquipmentCategoryId = this.form.value.equipmentCategoryId;
      let listEquipmentRootCause: any[] = [];
      for (let i = 0; i < selectedRootCauseList.length; i++) {
        listEquipmentRootCause.push({
          id: 0,
          createdBy: this.loggedUserId,
          createdAt: new Date(),
          editedBy: this.loggedUserId,
          editedAt: new Date(),
          equipmentFailureRootCauseId: selectedRootCauseList[i],
          equipmentCategoryId: selectedEquipmentCategoryId
        });
      }
      const equipmentRootCause = await this.saveEquipmentRootCauseLink(listEquipmentRootCause);
      //Insert Repair Type
      let selectedRepairTypeList = this.form.value.repairType;
      let listEquipmentRepairType: any[] = [];
      for (let i = 0; i < selectedRepairTypeList.length; i++) {
        listEquipmentRepairType.push({
          id: 0,
          createdBy: this.loggedUserId,
          createdAt: new Date(),
          editedBy: this.loggedUserId,
          editedAt: new Date(),
          equipmentRepairTypeId: selectedRepairTypeList[i],
          equipmentCategoryId: selectedEquipmentCategoryId
        });
      }
      const equipmentRepair = await this.saveEquipmentRepairTypeLink(listEquipmentRepairType);
      //Insert failure Type
      let selectedFailureTypeList = this.form.value.failureType;
      let listEquipmentFailureType: any[] = [];
      for (let i = 0; i < selectedFailureTypeList.length; i++) {
        listEquipmentFailureType.push({
          id: 0,
          createdBy: this.loggedUserId,
          createdAt: new Date(),
          editedBy: this.loggedUserId,
          editedAt: new Date(),
          equipmentFailureTypeId: selectedFailureTypeList[i],
          equipmentCategoryId: selectedEquipmentCategoryId
        });
      }
      const equipmentFailureType = await this.saveEquipmentFailureTypeLink(listEquipmentFailureType);
      //Insert Component
      let selectedComponentList = this.form.value.component;
      let listEquipmentComponent: any[] = [];
      for (let i = 0; i < selectedComponentList.length; i++) {
        listEquipmentComponent.push({
          id: 0,
          createdBy: this.loggedUserId,
          createdAt: new Date(),
          editedBy: this.loggedUserId,
          editedAt: new Date(),
          equipmentComponentId: selectedComponentList[i],
          equipmentCategoryId: selectedEquipmentCategoryId
        });
      }
      const equipmentComponent = await this.saveEquipmentComponentLink(listEquipmentComponent);
      this.form.reset();
      this.loading = false;
    }
    else {
      if (btnName == "btnCancel") {
        this.loading = false;
      }
    }
  }

  saveEquipmentRootCauseLink(rootCauseList: any) {
    return this.apiRootEquipmentCategoryCauseLinkServ.bulkInsertEquipmentCategoryRootCauseLink(rootCauseList).toPromise();
  }

  saveEquipmentRepairTypeLink(repairTypeList: any) {
    return this.apiRepairTypeEquipmentCategoryLinkServ.bulkInsertEquipmentCategoryRepairTypeLink(repairTypeList).toPromise();
  }

  saveEquipmentFailureTypeLink(failureTypeList: any) {
    return this.apiFailureTypeEquipmentCategoryLinkServ.bulkInsertEquipmentCategoryFailureTypeLink(failureTypeList).toPromise();
  }

  saveEquipmentComponentLink(componentList: any) {
    return this.apiComponentEquipmentCategoryLinkServ.bulkInsertEquipmentCategoryComponentLink(componentList).toPromise();
  }

}
