<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Edit Job Card Type
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>
            </div>  
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" required>
                </mat-form-field>
            </div>                    
        </div>      
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')" [disabled]="form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>
