import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { UserService } from 'src/services/user.service';
import { AddUserComponent } from '../dialogs/add-user/add-user.component';
import { EditUserComponent } from '../dialogs/edit-user/edit-user.component';

@Component({
  selector: 'app-listusers',
  templateUrl: './listusers.component.html',
  styleUrls: ['./listusers.component.css']
})
export class ListusersComponent implements OnInit {

  public displayedColumns: string[] = ['id', 'firstName', 'lastName', 'idNumber', 'employeeNumber', 'roleName', 'departmentName', 'createdAt'];

  public dsUsers = new MatTableDataSource<userdata>();

  constructor(private router: Router, private apiUserServ: UserService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsUsers.sort = this.sort;
    this.dsUsers.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsUsers.filter = filterValue.trim().toLowerCase();

    if (this.dsUsers.paginator) {
      this.dsUsers.paginator.firstPage();
    }
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddUserComponent, {
      data: { userData : ''},    
      maxWidth: '85vw',
      maxHeight: '85vh',
      width: '85vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(EditUserComponent, {
      data : row,
      maxWidth: '85vw',
      maxHeight: '85vh',
      width: '85vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      debugger;
      if (result) {
        this.refreshData();
      }
    });
  }

  refreshData() {
    this.apiUserServ.getAll().subscribe(data => {
      this.dsUsers.data = data as userdata[];
    }, error => {
      if (error.status == 404) {
        console.log("No Record Found");
      } else {
        console.log(error);
      }
    });
  }

}

export interface userdata {
  id: number;
  firstName: string;
  lastName: string;
  titleName: string;
  idNumber: string;
  logUserName: string;
  employeeNumber: string;
  createdBy: number;
  createdAt: Date;
  editedBy: number;
  editedAt: Date;
  isActive: boolean;
  entityId: number;
  entityName: string;
  roleId: number;
  roleName: string;
}
