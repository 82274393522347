import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { EquipmentFailureRootCauseService } from 'src/services/equipment-failure-root-cause.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';

@Component({
  selector: 'app-add-edit-equipment-failure-root-cause',
  templateUrl: './add-edit-equipment-failure-root-cause.component.html',
  styleUrls: ['./add-edit-equipment-failure-root-cause.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditEquipmentFailureRootCauseComponent implements OnInit {

  pageTitle: string = "";
  form: FormGroup;
  loggedUserId: number;
  btnName: string;

  constructor(private fb: FormBuilder, private apiEquipmentRootCauseFailureServ: EquipmentFailureRootCauseService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar,
    public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditEquipmentFailureRootCauseComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
    }
  }

  ngOnInit(): void {
    this.createForm();
    if (this.data) {
      this.pageTitle = "Edit Root Cause";
      this.form.patchValue(
        {
          rootCauseDesc: this.data.rootCauseDesc
        });
    } else {
      this.pageTitle = "Add Root Cause";
    }
  }

  createForm() {
    this.form = this.fb.group({
      "rootCauseDesc": [null, [Validators.required]]
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      var formData = this.form.value;
      if (this.data) {
        formData.editedBy = this.loggedUserId;
        formData.editedAt = new Date();
        formData.id = this.data.id;
      } else {
        formData.id = 0;
        formData.createdBy = this.loggedUserId;
        formData.createdAt = new Date();
      }
      this.apiEquipmentRootCauseFailureServ.create(formData).subscribe(data => {
        this.dialogRef.close({ refresh: true });
      });

    } else {
      if (btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

}

