<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Add Job Card Task
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="departmentId" matInput placeholder="Department" (selectionChange)="onChangeDepartment($event)">
                        <mat-option *ngFor="let item of dsDepartment" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-select formControlName="assigneeId" matInput placeholder="Person">
                        <mat-option *ngFor="let item of dsPerson" [value]="item.id">
                            {{item.firstName}} {{item.lastName}} - {{item.employeeNumber}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
        </div>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startDate" formControlName="startDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="startTime" matInput placeholder="Start Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="endDate" formControlName="endDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>

            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="endTime" matInput placeholder="End Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> 
        <div class="row">
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="jobCardTaskActionId" matInput placeholder="Job Action Type">
                        <mat-option *ngFor="let item of dsJobCardTaskAction" [value]="item.id">
                            {{item.jobTaskActionDesc}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>      

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="description" required></textarea>
                  </mat-form-field>
            </div>
        </div>    
         <div class="row" *ngIf="edited">
            <mat-form-field fxFlex>
                <mat-select formControlName="delayReasonId" matInput placeholder="Delay Reason">
                    <mat-option *ngFor="let item of dsJobCardDelayReason" [value]="item.id">
                        {{item.delayReason}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')" [disabled]="form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>
