import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { error } from 'protractor';
import { AwsS3DocumentService } from 'src/services/aws-s3-document.service';
import { DepartmentAssignedDocumentService } from 'src/services/department-assigned-document.service';
import { DepartmentDocumentService } from 'src/services/department-document.service';
import { DepartmentService } from 'src/services/department.service';
import { DocumentTypeService } from 'src/services/document-type.service';
import { DocumentService } from 'src/services/document.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { AddEditDepartmentDocumentComponent } from '../dialogs/add-edit-department-document/add-edit-department-document.component';
import { AddEditDocumentAccessComponent } from '../dialogs/add-edit-document-access/add-edit-document-access.component';
import { AddEditDocumentTypeComponent } from '../dialogs/add-edit-document-type/add-edit-document-type.component';
import { AddEditDocumentUploadComponent } from '../dialogs/add-edit-document-upload/add-edit-document-upload.component';
import { ConfirmDeleteDocumentComponent } from '../dialogs/confirm-delete-document/confirm-delete-document.component';
import { documentTypedata } from '../list-document-types/list-document-types.component';
import { department } from '../list-user-document-access/list-user-document-access.component';


@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListDocumentsComponent implements OnInit {
  form: FormGroup;
  loading = false;
  loggedUserId: number;
  loggedUserDepartmentId: number;
  dsDepartment: any[] = [];

  public displayedColumns: string[] = ['id', 'departmentName', 'departmentCode', 'documentTypeName', 'documentName', 'createdAt', 'action'];

  public dsDocument = new MatTableDataSource<documentdata>();

  constructor(private fb: FormBuilder,private router: Router, private apiDocumentServ: DocumentService, private awsS3DocumentServ: AwsS3DocumentService,
    private apiDepartmentServ: DepartmentService,private tokenStorage: TokenstorageService, private dialog: MatDialog) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
        this.loggedUserDepartmentId = user.departmentId;
        this.loading = false;
      }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.createForm();
    this.loadDepartments();
    this.refreshData();   
    this.form.patchValue({
      department: this.loggedUserDepartmentId
    });
  }

  createForm() {
    this.form = this.fb.group({
      department: new FormControl({ value: this.loggedUserDepartmentId }, Validators.compose([Validators.required])),
    });
  }

  loadDepartments() {
    this.apiDepartmentServ.getAll().subscribe(data => {
      let dsResults = data as department[];
      this.dsDepartment.push({
        id: -1,
        departmentName: "-- All --",    
        departmentCode: "",
      });
      for(let i = 0 ; i < dsResults.length ; i++) {
        this.dsDepartment.push({
          id: dsResults[i].id,
          departmentName: dsResults[i].departmentName,  
          departmentCode: dsResults[i].departmentCode,  
        });
      }   
    });
  }

  ngAfterViewInit(): void {
    this.dsDocument.sort = this.sort;
    this.dsDocument.paginator = this.paginator;
  }

  refreshData() {
    debugger;
    this.apiDocumentServ.getDepartmentDocumentById(this.loggedUserDepartmentId).subscribe(data => {
      this.dsDocument.data = data as documentdata[];
      this.loading = false;
    }); 
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsDocument.filter = filterValue.trim().toLowerCase();
    if (this.dsDocument.paginator) {
      this.dsDocument.paginator.firstPage();
    }
  }

  onClickDepartment(event) {   
    this.loading = true;
    if(event.value == -1) {
      this.apiDocumentServ.getAll().subscribe(data => {
        this.dsDocument.data = data as documentdata[];
        this.loading = false;
      });
    }else{
      let department = this.dsDepartment.filter(r => r.id == event.value);
      if (department) {       
        this.apiDocumentServ.getDepartmentDocumentById(department[0].id).subscribe(data => {
          this.dsDocument.data = data as documentdata[];
          this.loading = false;
        });
      }
    }
  }

  actionDocument(actionType, row) {
    this.loading = true;
    if (actionType == "download") {
      let fileName = row.awsImageRef;
      this.awsS3DocumentServ.getByFileName(fileName).subscribe(res => {
        const file = res;
        const url = window.URL.createObjectURL(new Blob([res as BlobPart], { type: res.type }));
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;
        link.download = fileName;
        link.click();
        this.loading = false;
      }, error => {
        console.log(error);
      });
    } else if (actionType == "delete") {
      this.loading = false;
      const confirmDialog = this.dialog.open(ConfirmDeleteDocumentComponent, {
        data: {
          title: 'Confirm Delete Document',
          message: 'Are you sure, you want to delete document : ' + row.documentName
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.awsS3DocumentServ.deleteFile(row.awsImageRef).subscribe(res => {  
            this.apiDocumentServ.delete(row.id).subscribe(res => {     
              this.refreshData();  
            },error => {
              console.log(error);
            });
            
          },
            error => {
              console.log(error);
            });
        }
      });
    }
  }

  onCreate() {
    const dialogRef = this.dialog.open(AddEditDocumentUploadComponent, {
      data: null,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditDocumentUploadComponent, {
      data: row,
      maxWidth: '55vw',
      maxHeight: '85vh',
      width: '55vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData();
      }
    });
  }

}

export interface documentdata {

  id: number;

  documentTypeId: number;

  documentTypeName: string;

  documentName: string;

  departmentName: string;

  departmentCode: string;

  departmentId: number;

  awsImageRef: string;

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  isActive: boolean;
}
