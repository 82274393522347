import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { EquipmentTypeService } from 'src/services/equipment-type.service';
import { AddEditEquipmentTypeComponent } from '../dialogs/add-edit-equipment-type/add-edit-equipment-type.component';

@Component({
  selector: 'app-list-equipment-type',
  templateUrl: './list-equipment-type.component.html',
  styleUrls: ['./list-equipment-type.component.css']
})
export class ListEquipmentTypeComponent implements OnInit {

  loading = false;

  public displayedColumns: string[] = ['id', 'description', 'createdAt'];

  public dsEquipmentType = new MatTableDataSource<equipmentTypeData>();

  constructor(private router: Router, private apiEquipmentType: EquipmentTypeService, private dialog: MatDialog, private snackBar: MatSnackBar) { }
  
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.dsEquipmentType.sort = this.sort;
    this.dsEquipmentType.paginator = this.paginator;
  }

  refreshData() {
    this.loading = true;
    this.apiEquipmentType.getAll().subscribe(data => {  
      debugger;   
      this.dsEquipmentType.data = data as equipmentTypeData[];
      this.loading = false;
    }, error => {
      this.snackBar.open("Error Message", error, {
        duration: 2000,
      });
      this.loading = false;
    });
  } 

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEquipmentType.filter = filterValue.trim().toLowerCase();

    if (this.dsEquipmentType.paginator) {
      this.dsEquipmentType.paginator.firstPage();
    }
  }  

  onCreate() {
    const dialogRef = this.dialog.open(AddEditEquipmentTypeComponent, {
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });   
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditEquipmentTypeComponent, {
      data: row,
      maxWidth: '97vw',
      maxHeight: '99vh',
      width: '97vw',
    });
  }

}

export interface equipmentTypeData {
  
  id : number;  

  description : string;

  createdBy : number;

  createdAt : Date;
  
  editedBy : number;
  
  editedAt : Date;
  
  isActive : boolean;
  
}

