<mat-sidenav-container fullscreen *ngIf="isLoggedIn">
  <mat-sidenav mode="side" #sidenav style="transform: none;visibility: visible;background-color: ghostwhite;"
    mode="over">
    <div style="font-size: medium;font-weight: 500; color: white; padding-left: 20px; background-color: #0A0045;">
      UhuruMES</div>
    <mat-nav-list class="list-container">
      <span *ngFor="let itemMenu of menuAccess">

        <mat-list-item [routerLink]="'/Home'" class="menu-text"
          *ngIf="itemMenu.menuName == 'Home' && itemMenu.active == true">
          <mat-icon mat-list-icon>home</mat-icon>
          <h4 mat-line *ngIf="isExpanded">Home</h4>
        </mat-list-item>

        <mat-list-item [routerLink]="'/JobCardList'" class="menu-text"
          *ngIf="itemMenu.menuName == 'JobCard' && itemMenu.active == true">
          <mat-icon mat-list-icon>receipt_long</mat-icon>
          <h4 mat-line *ngIf="isExpanded">Job Card</h4>
        </mat-list-item>

        <mat-list-item [routerLink]="'/AdminMenu'" class="menu-text"
          *ngIf="itemMenu.menuName == 'Admin' && itemMenu.active == true">
          <mat-icon mat-list-icon>people</mat-icon>
          <h4 mat-line *ngIf="isExpanded">Admin</h4>
        </mat-list-item>

        <mat-list-item [routerLink]="'/DocumentList'" class="menu-text"
          *ngIf="itemMenu.menuName == 'Documents' && itemMenu.active == true">
          <mat-icon mat-list-icon>style</mat-icon>
          <h4 mat-line *ngIf="isExpanded">Documents</h4>
        </mat-list-item>

        <mat-list-item [routerLink]="'/ReportList'" class="menu-text"
          *ngIf="itemMenu.menuName == 'Reports' && itemMenu.active == true">
          <mat-icon mat-list-icon>library_books</mat-icon>
          <h4 mat-line *ngIf="isExpanded">Reports</h4>
        </mat-list-item>

        <mat-list-item [routerLink]="'/InterLockList'" class="menu-text"
          *ngIf="itemMenu.menuName == 'Interlock Request' && itemMenu.active == true">
          <mat-icon mat-list-icon>lock</mat-icon>
          <h4 mat-line *ngIf="isExpanded">Interlock</h4>
        </mat-list-item>

        <mat-list-item [routerLink]="'/RoutineMaintenanceTagPlanList'" class="menu-text"
        *ngIf="itemMenu.menuName == 'Routine Jobs' && itemMenu.active == true">
        <mat-icon mat-list-icon>history</mat-icon>
        <h4 mat-line *ngIf="isExpanded">Routine Jobs</h4>
      </mat-list-item>

        <mat-list-item [routerLink]="'/DepartmentScheduleList'" class="menu-text"
        *ngIf="itemMenu.menuName == 'Schedule' && itemMenu.active == true">
        <mat-icon mat-list-icon>alarm</mat-icon>
        <h4 mat-line *ngIf="isExpanded">Schedule</h4>
      </mat-list-item>

        <mat-list-item (click)="onLogout()" class="menu-text" href="" mat-list-item
          *ngIf="itemMenu.menuName == 'Logout' && itemMenu.active == true">
          <mat-icon mat-list-icon>vpn_key</mat-icon>
          <h4 mat-line *ngIf=" isExpanded">Logout</h4>
        </mat-list-item>

      </span>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" style="font-size: medium;font-weight: 500; padding-right: 18px; padding-left: 10px;">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon> Menu
      </button>
      <span class="toolbar-filler"></span>
      <span class="material-icons">
        person
      </span>
      {{currentLoggedUser}}
    </mat-toolbar>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>