<h1 mat-dialog-title class="Mat-Pop-Up-color-title">
    Edit Job Card
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="alert alert-warning" role="alert" *ngIf="jobCardStatus == 'Awaiting'">
            Please note for a jobcard to be actioned must be approved by the supervisor
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="showActionWarningMessage">
            Please note for a jobcard to be completed all tasks must be completed
        </div>
        <div class="row">
            <div class="col-md-1">
                <mat-form-field fxFlex>
                    <mat-label>Track No</mat-label>
                    <input matInput type="text" formControlName="id">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field fxFlex>
                    <mat-select formControlName="departmentId" matInput placeholder="Department From">
                        <mat-option *ngFor="let item of dsDepartmentFrom" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-label>Created By</mat-label>
                    <input matInput type="text" formControlName="createdByName">
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="departmentIdTo" matInput placeholder="Department TO">
                        <mat-option *ngFor="let item of dsDepartmentTo" [value]="item.id">
                            {{item.departmentName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <div class="circle">
                    <p>{{percentageCompleted}}%</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-label>Order Number</mat-label>
                    <input matInput type="text" formControlName="orderNumber">
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="jobCardTypeId" matInput placeholder="Job Card Type">
                        <mat-option *ngFor="let item of dsJobCardType" [value]="item.id">
                            {{item.name}} - {{item.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="tagId" matInput placeholder="Tag ID">
                        <mat-option *ngFor="let item of dsEquipment" [value]="item.id">
                            {{item.instrumentTag}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Required Start Date</mat-label>
                    <input matInput [matDatepicker]="reqStartDate" formControlName="reqStartDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="reqStartDate"></mat-datepicker-toggle>
                    <mat-datepicker #reqStartDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="reqStartTime" matInput placeholder="Required Start Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Required End Date</mat-label>
                    <input matInput [matDatepicker]="reqEndDate" formControlName="reqEndDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="reqEndDate"></mat-datepicker-toggle>
                    <mat-datepicker #reqEndDate></mat-datepicker>
                </mat-form-field>

            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="reqEndTime" matInput placeholder="Required End Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Main Description</mat-label>
                    <textarea matInput formControlName="description" required></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startDate" formControlName="startDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="startTime" matInput placeholder="Start Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="endDate" formControlName="endDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="endTime" matInput placeholder="End Time">
                        <mat-option *ngFor="let item of dsTimes" [value]="item.id">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field fxFlex>
                    <mat-select formControlName="statusId" matInput placeholder="Status"
                        (selectionChange)="onChangeStatus($event)">
                        <mat-option *ngFor="let item of dsJobCardStatus" [value]="item.id">
                            {{item.statusName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex *ngIf="data.statusId == 3">
                    <mat-select formControlName="rejectionReasonId" matInput placeholder="Rejection Reason"
                        (selectionChange)="onChangeReason($event)">
                        <mat-option *ngFor="let item of dsRejectionReason" [value]="item.id">
                            {{item.reasonName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex *ngIf="data.statusId == 7">
                    <mat-select formControlName="rejectionReasonId" matInput placeholder="OnHold Reason"
                        (selectionChange)="onChangeReason($event)">
                        <mat-option *ngFor="let item of dsRejectionReason" [value]="item.id">
                            {{item.reasonName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col-md-3">
                <mat-form-field fxFlex *ngIf="data.rejectionReasonId == 1 && data.statusId != 7">
                    <mat-label>Other Rejection Reason</mat-label>
                    <input matInput type="text" formControlName="otherRejectionReason">
                </mat-form-field>

                <mat-form-field fxFlex *ngIf="data.statusId == 7 && data.rejectionReasonId == 1">
                    <mat-label>OnHold Reason</mat-label>
                    <input matInput type="text" formControlName="otherRejectionReason">
                </mat-form-field>
            </div>
            <div class="col-md-3" align="end" *ngIf="dsJobCardStatus.length != 1 && data.departmentIdTo == loggedUserDepartmentId && statusAllowUser">
                <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')">Cancel</button>
                <div class="divider"></div>
                <button mat-raised-button color="primary" id="btnSave" style="margin-right: 15px;"
                    (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
            </div>
            <div class="col-md-3" align="end" *ngIf="dsSpecialApproval.length > 0 && statusAllowUser">
                <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')">Cancel</button>
                <div class="divider"></div>
                <button mat-raised-button color="primary" id="btnSave" style="margin-right: 15px;"
                    (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
            </div>
        </div>
        <h1 mat-dialog-title class="Mat-Pop-Up-color-title">
            List Of Tasks
        </h1>

        <div class="row">
            <div class="col-md-12">
                <table mat-table [dataSource]="dsJobCardTask" matSort class="mat-elevation-z8">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Create Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'dd-MM-yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="departmentName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                        <td mat-cell *matCellDef="let element"> {{element.departmentName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="assigneeEmployeeNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Person Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.assigneeEmployeeNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.startDate | date : 'dd-MM-yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="startTime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.startTime}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.endDate | date : 'dd-MM-yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="endTime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> End Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.endTime}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actualStartDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Start Date </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.actualStartDate | date : 'dd-MM-yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actualStartTime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Start Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.actualStartTime}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actualEndDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual End Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.actualEndDate | date : 'dd-MM-yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actualEndTime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual End Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.actualEndTime}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="statusName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEdit(row)"></tr>
                </table>
            </div>
        </div>

    </div>
</form>