import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { UserService } from 'src/services/user.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { EquipmentService } from 'src/services/equipment.service';
import { SupFunctionalLocationService } from 'src/services/sup-functional-location.service';
import { FunctionLocationService } from 'src/services/function-location.service';

@Component({
  selector: 'app-add-function-location',
  templateUrl: './add-function-location.component.html',
  styleUrls: ['./add-function-location.component.css']
})
export class AddFunctionLocationComponent implements OnInit {

  form: FormGroup;
  loggedUserId: number;
  btnName: string;

  dsEquipment: any;
  dsSupFunctionalLocation: any;

  constructor(private fb: FormBuilder,private apiUserServ : UserService, private apiEquipmentServ : EquipmentService,private apiSupFunctionLocationServ : SupFunctionalLocationService,
     private apiFunctionLocationServ : FunctionLocationService, private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddFunctionLocationComponent>) {
      var user = this.tokenStorage.getUser();
      if (user) {
        this.loggedUserId = user.id;
      }
  }

  ngOnInit(): void {
    this.createForm();
    this.loadEquipment();
    this.loadSupFunctionalLocation();
  }

  createForm() {
    this.form = this.fb.group({
      functionalLocName: [null, [Validators.required]],
      description: [null, [Validators.required]],
      supFunctionalLocationId: [null, [Validators.required]],
      equipmentId: [null, [Validators.required]],    
    });
  }

  loadEquipment() {  
    this.apiEquipmentServ.getAll().subscribe(data => {    
     this.dsEquipment = data ;   
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadSupFunctionalLocation(){
    this.apiSupFunctionLocationServ.getAll().subscribe(data => {
      this.dsSupFunctionalLocation = data
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  onSubmit(btnName) {
    if (btnName == "btnSave") {
      var formData = this.form.value;
      formData.id = 0;
      formData.createdBy = this.loggedUserId;
      formData.createdAt = new Date();      
      this.apiFunctionLocationServ.create(formData).subscribe(data => {        
        this.dialogRef.close();
      });

    } else {
      this.dialogRef.close();
    }
  }

}
