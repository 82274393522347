import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatSort, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CreateUpdateDepartmentDocumentDTO } from 'src/models/create-update-department-document-dto';
import { AwsS3DocumentService } from 'src/services/aws-s3-document.service';
import { DepartmentDocumentService } from 'src/services/department-document.service';
import { DocumentTypeService } from 'src/services/document-type.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';

@Component({
  selector: 'app-add-edit-department-document',
  templateUrl: './add-edit-department-document.component.html',
  styleUrls: ['./add-edit-department-document.component.css']
})
export class AddEditDepartmentDocumentComponent implements OnInit {

  loading = false;
  form: FormGroup;
  loggedUserId: number;
  dsDocumentType: any;
  selectedFile: File;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;
  imageName: any;
  pageTitle: string;
  btnName: string;

  @ViewChild('inputUploadFile', { static: false }) fileUploader: ElementRef;

  constructor(private formBuilder: FormBuilder, private documentTypeServ: DocumentTypeService, private apiDepartmentDocumentServ: DepartmentDocumentService, private tokenStorage: TokenstorageService,
    private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditDepartmentDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data, private httpClient: HttpClient, private awsS3DocumentServ: AwsS3DocumentService) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.loading = false;
    }
    this.pageTitle = "Upload Documents";
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.documentTypes();
    this.createForm();
    this.form.patchValue({     
      documentTypeId: this.data.documentTypeId           
    }); 
  }

  createForm() {
    this.form = this.formBuilder.group({
      fileName: ['', Validators.required],
      documentTypeId: ['', Validators.required]
    });
  }

  documentTypes() {
    this.documentTypeServ.getAll().subscribe(data => {
      this.dsDocumentType = data;
    });
  }

  public onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  async onSubmit(buttonType) {
    if (buttonType == "btnSave") {
      if (this.form.valid) {    
      const uploadImageData = new FormData();
      uploadImageData.append("file", this.selectedFile, this.selectedFile.name);
      var serveUrl = environment.serverUrl + "/awsS3Upload/upload";
      this.httpClient.post(serveUrl, uploadImageData, { responseType: 'text' })
        .subscribe((response) => {
          let newDocumentInfo = new CreateUpdateDepartmentDocumentDTO();
          newDocumentInfo.id = 0;
          newDocumentInfo.awsImageRef = response;
          newDocumentInfo.createdBy = this.loggedUserId;
          newDocumentInfo.createdAt = new Date();
          newDocumentInfo.visibleToDepartment = "1,2";
          newDocumentInfo.documentTypeId = this.form.value.documentTypeId;
          this.apiDepartmentDocumentServ.create(newDocumentInfo).subscribe(results => {
            this.dialogRef.close({ refresh: true });
          }, error => {
            debugger;
          });
        }, error => {
          debugger;
        });
      }

    } else {
      if (buttonType == "btnCancel") {
        this.dialogRef.close({ refresh: true });
      }
    }

  }

}
