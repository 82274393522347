import { Component, OnInit, Inject, ViewChild, DebugElement } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TokenstorageService } from 'src/services/tokenstorage.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { JobcardTypeService } from 'src/services/jobcard-type.service';
import { FunctionLocationService } from 'src/services/function-location.service';
import { JobCardService } from 'src/services/job-card.service';
import { Data } from '@angular/router';
import { JobCardTaskService } from 'src/services/job-card-task.service';
import { DepartmentService } from 'src/services/department.service';
import { Department } from 'src/models/department';
import { JobCardStatusService } from 'src/services/job-card-status.service';
import { JobCardStatus } from 'src/models/job-card-status';
import { CreateUpdateJobCardDTO } from 'src/models/create-update-job-card-dto';
import { AddJobCardTaskComponent } from '../add-job-card-task/add-job-card-task.component';
import { EditJobCardTaskComponent } from '../edit-job-card-task/edit-job-card-task.component';
import { HourTimes } from 'src/models/hour-times';
import { debug } from 'console';
import { ThakaduEquipmentService } from 'src/services/thakadu-equipment.service';
import { Globals } from 'src/app/global-values/globals';
import { JobCardRejectionReasonService } from 'src/services/job-card-rejection-reason.service';

@Component({
  selector: 'app-edit-job-card',
  templateUrl: './edit-job-card.component.html',
  styleUrls: ['./edit-job-card.component.css']
})
export class EditJobCardComponent implements OnInit {

  isDisabled: boolean = true;
  form: FormGroup;
  loggedUserId: number;
  loggedUserRoleId: number;
  loggedUserDepartmentId: number;
  btnName: string;
  dsJobCardType: any[] = [];
  panelOpenState = false;
  dsEquipment: any[];
  isFirst: boolean = true;
  dsDepartmentFrom: any[] = [];
  dsDepartmentTo: any[] = [];
  dsJobCardStatus: any[] = [];
  dsTimes: any[] = [];
  dsRejectionReason: any[] = [];
  percentageCompleted: string = "0";
  allowToAddTask: boolean = false;
  minDate: Date;
  jobCardStatus: string = "";
  otherRejectionReason: boolean = false;
  statusAllowUser: boolean = false;
  dsSpecialApproval : any[] = [];
  showActionWarningMessage : boolean = false;

  public displayedColumns: string[] = ['id', 'createdAt', 'departmentName', 'assigneeEmployeeNumber', 'startDate', 'startTime', 'endDate', 'endTime', 'actualStartDate', 'actualStartTime',
    'actualEndDate', 'actualEndTime', 'statusName'];

  public dsJobCardTask = new MatTableDataSource<jobcardTaskData>();

  constructor(private fb: FormBuilder, private apiJobCardTypeServ: JobcardTypeService, private apiEquipmentServ: ThakaduEquipmentService,
    private apiJobCardServ: JobCardService, private apiJobCardTaskServ: JobCardTaskService, private apiDepartmentServ: DepartmentService,
    private apiJobCardStatusServ: JobCardStatusService, private apiJobCardRejectionReasonServ: JobCardRejectionReasonService,
    private tokenStorage: TokenstorageService, private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<EditJobCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data ,private globalValues : Globals) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.loggedUserRoleId = user.roleId;
      this.loggedUserDepartmentId = user.departmentId;
    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.createForm();
    this.loadJobCardType();
    this.loadEquipmentTag();
    this.loadJobCardStatus();
    this.loadDepartment();
    this.loadJobCarTimes();
    this.loadJobCardRejectionReason();
    this.form.patchValue(
      {
        id: this.data.id,
        jobCardTypeId: this.data.jobCardTypeId,
        description: this.data.description,
        departmentIdTo: this.data.departmentIdTo,
        departmentId: this.data.departmentId,
        orderNumber: this.data.orderNumber,
        statusId: this.data.statusId,
        reqStartTime: this.data.reqStartTime,
        reqStartDate: new Date(this.data.reqStartDate),
        reqEndDate: new Date(this.data.reqEndDate),
        reqEndTime: this.data.reqEndTime,
        startDate: this.data.startDate ? new Date(this.data.startDate) : new Date(),
        startTime: this.data.startTime ? this.data.startTime : null,
        endDate: this.data.endDate ? new Date(this.data.endDate) : new Date(),
        endTime: this.data.endTime ? this.data.endTime : null,
        tagId: this.data.tagId,
        rejectionReasonId: this.data.rejectionReasonId,
        otherRejectionReason: this.data.otherRejectionReason,
        createdByName: this.data.createdByName
      });
    this.loadJobCardTask(this.data.id);
    if (this.data.departmentIdTo != this.loggedUserDepartmentId) {
      this.form.controls['statusId'].disable();
      this.form.controls['startDate'].disable();
      this.form.controls['startTime'].disable();
      this.form.controls['endDate'].disable();
      this.form.controls['endTime'].disable();
    }
  }

  ngAfterViewInit(): void {
    this.dsJobCardTask.sort = this.sort;
    this.dsJobCardTask.paginator = this.paginator;
  }

  createForm() {
    this.form = this.fb.group({
      id: [null],
      tagId: [null, [Validators.required]],
      jobCardTypeId: [null, [Validators.required]],
      reqStartTime: [null, [Validators.required]],
      reqStartDate: [null, [Validators.required]],
      reqEndDate: [null, [Validators.required]],
      reqEndTime: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      description: [null, [Validators.required]],
      departmentIdTo: [null, [Validators.required]],
      departmentId: [null, [Validators.required]],
      orderNumber: [null],
      statusId: [null, [Validators.required]],
      rejectionReasonId: [null],
      otherRejectionReason: [null],
      createdByName: [null]
    });
    this.form.controls['tagId'].disable();
    this.form.controls['departmentIdTo'].disable();;
    this.form.controls['departmentId'].disable();
    this.form.controls['orderNumber'].disable();
    this.form.controls['jobCardTypeId'].disable();
    this.form.controls['reqStartDate'].disable();
    this.form.controls['reqStartTime'].disable();
    this.form.controls['reqEndDate'].disable();
    this.form.controls['reqEndTime'].disable();
    this.form.controls['description'].disable();
    this.form.controls['createdByName'].disable();
    this.form.controls['id'].disable();
  }

  loadJobCardType() {
    this.apiJobCardTypeServ.getAll().subscribe(data => {
      this.dsJobCardType = data;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadJobCarTimes() {
    let hourTimes = new HourTimes();
    this.dsTimes = hourTimes.getAllTimes();
  }

  loadJobCardTask(jobCardId: number) {   
    this.apiJobCardTaskServ.getAllJobCardTaskByJobCardId(jobCardId).subscribe(data => {
      this.dsJobCardTask.data = data as jobcardTaskData[];
      let total = this.dsJobCardTask.data.length;
      let completed = this.dsJobCardTask.data.filter(r => r.delayReasonName == "Completed");
      if (completed.length == 0) {
        this.percentageCompleted = "0";
      } else {
        this.percentageCompleted = (completed.length / total * 100).toFixed(2).toString();
      }
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadDepartment() {
    this.apiDepartmentServ.getAll().subscribe(data => {
      this.dsDepartmentFrom = data as Department[];
      this.dsDepartmentTo = data as Department[];
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadEquipmentTag() {
    this.apiEquipmentServ.getAll().subscribe(data => {
      this.dsEquipment = data;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadJobCardRejectionReason() {
    this.apiJobCardRejectionReasonServ.getAll().subscribe(data => {
      this.dsRejectionReason = data;
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  loadJobCardStatus() {
    let global = new Globals();
    this.apiJobCardStatusServ.getAll().subscribe(data => {      
      this.dsJobCardStatus = data as JobCardStatus[]; 
      let jobCardNextStatus = this.dsJobCardStatus.filter(r => r["id"] == this.data.statusId);
      if(jobCardNextStatus.length > 0) {
        this.dsJobCardStatus = this.getNextStatus(jobCardNextStatus);
        if (jobCardNextStatus[0].id == global.jobCardStatusCompleteId) {
          let checkIfCanActionClose = this.dsJobCardStatus.filter(r => r["id"] == global.jobCardStatusCloseId);
          let checkIfCanActionCloseJobcard = checkIfCanActionClose[0].statusRoleManagement.split(",") as [];
          this.statusAllowUser = checkIfCanActionCloseJobcard.filter(r => r == this.loggedUserRoleId).length > 0 ? true : false;
        }
        this.disableJobCardBaseOnStatus();       
      }else{
        this.form.controls['statusId'].disable();
        this.form.controls['startDate'].disable();
        this.form.controls['startTime'].disable();
        this.form.controls['endDate'].disable();
        this.form.controls['endTime'].disable();
        this.form.controls['otherRejectionReason'].disable();
        this.form.controls['rejectionReasonId'].disable();
      }
    }, error => {
      this.snackBar.open("Error Message", error.message, {
        duration: 2000,
      });
    });
  }

  getNextStatus(jobCardNextStatus : any[]) : any {
    let dsNextStatus: any[] = [];
    let nextActionStatus = jobCardNextStatus[0].statusManagement.split(",") as [];
    nextActionStatus.forEach(element => {
      let res = this.dsJobCardStatus.filter(r => r.id == element);
      dsNextStatus.push({
        id: res[0].id,
        statusName: res[0].statusName,
        statusManagement: res[0].statusManagement,
        statusRoleManagement: res[0].statusRoleManagement,
        statusTatHrs: res[0].statusTatHrs,
        statusSequence: res[0].statusSequence,
        specialDepartmentStatusRoleManagement : res[0].specialDepartmentStatusRoleManagement
      });
    });
    return dsNextStatus;
  }

  disableJobCardBaseOnStatus() {  
    let currentStatus = this.dsJobCardStatus.filter(r => r.id == this.data.statusId);
    if (currentStatus.length > 0) {
      this.jobCardStatus = currentStatus[0].statusName;
      this.form.controls['statusId'].disable();
      this.form.controls['startDate'].disable();
      this.form.controls['startTime'].disable();
      this.form.controls['endDate'].disable();
      this.form.controls['endTime'].disable();
      this.form.controls['otherRejectionReason'].disable();
      this.form.controls['rejectionReasonId'].disable();
      switch (currentStatus[0].statusName) {
        case "Approved": {
          let departmentStatusRoleManagement = currentStatus[0].specialDepartmentStatusRoleManagement.split(",");
          let filteredSpecialApprovalDepartment = departmentStatusRoleManagement.filter(r => r == this.loggedUserDepartmentId);
          let statusRoleManagement = currentStatus[0].statusRoleManagement.split(",") as any[];
          let results = statusRoleManagement.filter(r => r == this.loggedUserRoleId);
          if(results) {
            this.statusAllowUser = true;
            this.showActionWarningMessage = true;
            this.form.controls['statusId'].enable();
            this.dsSpecialApproval = filteredSpecialApprovalDepartment;       
            this.form.controls['startDate'].enable();
            this.form.controls['startTime'].enable();
            this.form.controls['endDate'].enable();
            this.form.controls['endTime'].enable();
          }else{
            this.allowToAddTask = true;
            this.showActionWarningMessage = true;
          }               
          break;
        }
        case "Awaiting" : {
          this.allowToAddTask = false;         
          let departmentStatusRoleManagement = currentStatus[0].specialDepartmentStatusRoleManagement.split(",");
          let filteredSpecialApprovalDepartment = departmentStatusRoleManagement.filter(r => r == this.loggedUserDepartmentId);
          if((this.loggedUserRoleId == this.globalValues.supervisorRoleId && (filteredSpecialApprovalDepartment && filteredSpecialApprovalDepartment.length > 0))
            || (this.loggedUserRoleId == this.globalValues.supervisorRoleId && this.data.departmentIdTo == this.loggedUserDepartmentId)) {
            this.statusAllowUser = true;
            this.form.controls['statusId'].enable();
            this.dsSpecialApproval = filteredSpecialApprovalDepartment;       
            this.form.controls['startDate'].enable();
            this.form.controls['startTime'].enable();
            this.form.controls['endDate'].enable();
            this.form.controls['endTime'].enable();
          } 
          break;        
        }
        case "InProgress": {         
          this.showActionWarningMessage = true;
          let departmentStatusRoleManagement = currentStatus[0].specialDepartmentStatusRoleManagement.split(",");         
          let filteredSpecialApprovalDepartment = departmentStatusRoleManagement.filter(r => r == this.loggedUserDepartmentId);
          this.dsSpecialApproval = filteredSpecialApprovalDepartment;
          if((this.loggedUserRoleId == this.globalValues.supervisorRoleId && (filteredSpecialApprovalDepartment && filteredSpecialApprovalDepartment.length > 0))
            || (this.loggedUserRoleId == this.globalValues.supervisorRoleId && this.data.departmentIdTo == this.loggedUserDepartmentId)) {           
            this.form.controls['startDate'].enable();
            this.form.controls['startTime'].enable();
            this.form.controls['endDate'].enable();
            this.form.controls['endTime'].enable();
            this.form.controls['statusId'].enable(); 
            this.allowToAddTask = true;
            this.statusAllowUser = true;     
          }              
          break;
        }  
        case "Completed": {         
          this.showActionWarningMessage = true;
          if(this.loggedUserRoleId == this.globalValues.supervisorRoleId && this.data.departmentIdTo == this.loggedUserDepartmentId) {           
            this.form.controls['statusId'].enable();  
            this.allowToAddTask = true;
            this.statusAllowUser = true; 
          }
          break;
        }  
        case "OnHold": {   
          this.showActionWarningMessage = true;  
          let departmentStatusRoleManagement = currentStatus[0].specialDepartmentStatusRoleManagement.split(",");
          let filteredSpecialApprovalDepartment = departmentStatusRoleManagement.filter(r => r == this.loggedUserDepartmentId);
          this.dsSpecialApproval = filteredSpecialApprovalDepartment;
          if((this.loggedUserRoleId == this.globalValues.supervisorRoleId && (filteredSpecialApprovalDepartment && filteredSpecialApprovalDepartment.length > 0))
            || (this.loggedUserRoleId == this.globalValues.supervisorRoleId && this.data.departmentIdTo == this.loggedUserDepartmentId)) {
            this.form.controls['statusId'].enable();  
            this.allowToAddTask = true; 
            this.statusAllowUser = true;  
          }   
          break;
        }       
        default: {
          this.allowToAddTask = false;          
          break;
        }
      }
    }

  }

  onChangeStatus(event) {
    let selectedStatus = {
      value: event.value,
      text: event.source.triggerValue
    };
    if(selectedStatus.text === "OnHold"){
      this.form.controls['otherRejectionReason'].enable();
      this.form.controls['rejectionReasonId'].enable();
     
    }
    this.form.controls['otherRejectionReason'].setValue(null);
    this.form.controls['rejectionReasonId'].setValue(null);
    this.data.statusId = event.value;
    this.data.rejectionReasonId = null;
  }

  onChangeReason(event) {
    this.data.rejectionReasonId = event.value;
  }

  onSubmit(btnName) {
    let global = new Globals;
    if (btnName == "btnSave") {
      if (this.form.controls["statusId"].value == global.jobCardStatusCompleteId) {
        for (let i = 0; i < this.dsJobCardTask.data.length; i++) {
          if (this.dsJobCardTask.data[i].statusId != global.jobCardTaskStatusRejectedId && this.dsJobCardTask.data[i].statusId != global.jobCardTaskStatusCompletedId) {
            this.snackBar.open("Message", "Please note all task(s) must be completed.", {
              duration: 3000,
            });
            return
          }
        }
      }

      var user = this.tokenStorage.getUser();
      let dtoJobCard = new CreateUpdateJobCardDTO();
      dtoJobCard.setId(this.data.id);
      dtoJobCard.setDepartmentId(user.departmentId);
      dtoJobCard.setDepartmentIdTo(this.form.controls["departmentIdTo"].value);
      dtoJobCard.setReqStartDate(this.form.controls["reqStartDate"].value);
      dtoJobCard.setReqStartTime(this.form.controls["reqStartTime"].value);
      dtoJobCard.setReqEndDate(this.form.controls["reqEndDate"].value);
      dtoJobCard.setReqEndTime(this.form.controls["reqEndTime"].value);
      dtoJobCard.setStartDate(this.form.controls["startDate"].value);
      dtoJobCard.setStartTime(this.form.controls["startTime"].value);
      dtoJobCard.setEndDate(this.form.controls["endDate"].value);
      dtoJobCard.setEndTime(this.form.controls["endTime"].value);
      dtoJobCard.setDescription(this.form.controls["description"].value);
      dtoJobCard.setEditedBy(this.loggedUserId);
      dtoJobCard.setEditedAt(new Date());
      dtoJobCard.setStatusId(this.form.controls["statusId"].value);
      dtoJobCard.setOrderNumber(this.form.controls["orderNumber"].value);
      dtoJobCard.setRejectionReasonId(this.form.controls["rejectionReasonId"].value);
      dtoJobCard.setOtherRejectionReason(this.form.controls["otherRejectionReason"].value);
      dtoJobCard.setIsActive(false);
      if (!this.form.value.orderNumber) {
        var dt = new Date();
        let dsDepartment = this.dsDepartmentFrom.filter(r => r.id == this.form.controls["departmentIdTo"].value);
        dtoJobCard.setOrderNumber(dsDepartment[0].departmentCode + '-' + dt.getDate() + "-" + (dt.getMonth() + 1) + "-" + dt.getFullYear() + "-" + this.data.id);
        dtoJobCard.setOrderNumberDate(new Date());
        dtoJobCard.setOrderNumberGenBy(this.loggedUserId);
      }
      dtoJobCard.setJobCardTypeId(this.form.controls["jobCardTypeId"].value);
      dtoJobCard.setTagId(this.form.controls["tagId"].value);
      if (this.form.value.statusId == global.jobCardStatusRejectionId || this.form.value.statusId == global.jobCardStatusCompleteId
        || this.form.value.statusId == global.jobCardStatusCloseId) {
        dtoJobCard.setCompletedOn(new Date());
      }
      this.apiJobCardServ.create(dtoJobCard).subscribe(data => {
        this.data = data;
        this.ngOnInit();
        this.snackBar.open("Message", "JobCard Updated Successfully", {
          duration: 2000,
        });
      }, error => {
        console.log(error);
      });
    } else if (btnName == "btnAddTask") {
      const dialogRef = this.dialog.open(AddJobCardTaskComponent, {
        maxWidth: '80vw',
        maxHeight: '99vh',
        width: '80vw',
        data: this.data
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loadJobCardTask(this.data.id);
        }
      });
    }
    else {
      if (btnName == "btnCancel") {
        this.dialogRef.close();
      }
    }
  }

  onEdit(row) {
    row.jobCardStatus = this.jobCardStatus;
    if(this.dsSpecialApproval){
      row.dsJobCardStatus = this.dsJobCardStatus;
    }
    const dialogRef = this.dialog.open(EditJobCardTaskComponent, {
      data: row,
      maxWidth: '80vw',
      maxHeight: '99vh',
      width: '80vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
}

export interface jobcardTaskData {

  id: number;

  departmentId: number;

  departmentName: string;

  assigneeEmployeeNumber: number;

  assigneeName: number;

  assigneeId: number;

  startDate: Date;

  startTime: string;

  endDate: Date;

  endTime: string;

  actualStartDate: Date;

  actualStartTime: string;

  actualEndDate: Date;

  actualEndTime: string;

  description: string;

  createdBy: number;

  createdAt: Date;

  editedBy: number;

  editedAt: Date;

  delayReasonName: string;

  delayReasonId: number;

  otherReason: string;

  jobCardId: number;

  statusId: number;

}
