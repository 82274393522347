import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatSort, MAT_DIALOG_DATA } from '@angular/material';
import { Data } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CreateUpdateDepartmentDocumentDTO } from 'src/models/create-update-department-document-dto';
import { CreateUpdateDocumentDTO } from 'src/models/create-update-document-dto';
import { AwsS3DocumentService } from 'src/services/aws-s3-document.service';
import { DepartmentDocumentService } from 'src/services/department-document.service';
import { DepartmentService } from 'src/services/department.service';
import { DocumentTypeService } from 'src/services/document-type.service';
import { DocumentService } from 'src/services/document.service';
import { TokenstorageService } from 'src/services/tokenstorage.service';;

@Component({
  selector: 'app-add-edit-document-upload',
  templateUrl: './add-edit-document-upload.component.html',
  styleUrls: ['./add-edit-document-upload.component.css']
})
export class AddEditDocumentUploadComponent implements OnInit {

  loading = false;
  form: FormGroup;
  loggedUserId: number;
  dsDocumentType: any;
  dsDepartment: any;
  selectedFile: File;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;
  imageName: any;
  pageTitle: string;
  btnName: string;

  @ViewChild('inputUploadFile', { static: false }) fileUploader: ElementRef;

  constructor(private formBuilder: FormBuilder, private documentTypeServ: DocumentTypeService, private apiDocumentServ: DocumentService,
    private apiDepartmentServ: DepartmentService, private tokenStorage: TokenstorageService,
    private snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<AddEditDocumentUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data, private httpClient: HttpClient, private awsS3DocumentServ: AwsS3DocumentService) {
    var user = this.tokenStorage.getUser();
    if (user) {
      this.loggedUserId = user.id;
      this.loading = false;
    }
    this.pageTitle = "Upload Document";
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.loadDocumentTypes();
    this.loadDepartments();
    this.createForm();
    if (this.data) {
      this.form.patchValue({
        documentTypeId: this.data.documentTypeId,
        documentName: this.data.documentName
      });
    }
  }

  createForm() {
    this.form = this.formBuilder.group({
      departmentId: ['', Validators.required],
      fileName: ['', Validators.required],
      documentTypeId: ['', Validators.required],
      documentName: ['', Validators.required]
    });
  }

  loadDocumentTypes() {
    this.documentTypeServ.getAll().subscribe(data => {
      this.dsDocumentType = data;
    });
  }

  loadDepartments() {
    this.apiDepartmentServ.getAll().subscribe(data => {
      this.dsDepartment = data;
      debugger;
    })
  }

  onChangeDepartment(event) {
    this.documentTypeServ.getByDepartmentId(event.value).subscribe(data => {
      this.dsDocumentType = data;
    })
  }

  public onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  async onSubmit(buttonType) {
    if (buttonType == "btnSave") {
      if (this.form.valid) {
        this.loading = true;           
        const uploadImageData = new FormData();
        uploadImageData.append("file", this.selectedFile, this.selectedFile.name);
        var serveUrl = environment.serverUrl + "/awsS3Upload/upload";
        this.httpClient.post(serveUrl, uploadImageData, { responseType: 'text' })
          .subscribe((response) => {
            let newDocumentInfo = new CreateUpdateDocumentDTO();
            newDocumentInfo.id = 0;
            newDocumentInfo.awsImageRef = response;
            newDocumentInfo.createdBy = this.loggedUserId;
            newDocumentInfo.createdAt = new Date();
            newDocumentInfo.documentTypeId = this.form.value.documentTypeId;
            newDocumentInfo.documentName = this.form.value.documentName;
            this.apiDocumentServ.create(newDocumentInfo).subscribe(results => {
              this.loading = false;
              this.dialogRef.close({ refresh: true });
            }, error => {
            });
          }, error => {
          });
      }

    } else {
      if (buttonType == "btnCancel") {
        this.dialogRef.close({ refresh: true });
      }
    }

  }

}
