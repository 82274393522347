<h1 mat-dialog-title>
    Add Company
</h1>
<form fxLayout="column" (ngSubmit)="onSubmit(btnName)" [formGroup]="form">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-select formControlName="companyTypeId" matInput placeholder="Company Type">
                        <mat-option *ngFor="let item of dsCompanyType" [value]="item.id">
                            {{item.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Company Name</mat-label>
                    <input matInput formControlName="companyName" required>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field fxFlex>
                    <mat-label>Company Desc</mat-label>
                    <input matInput formControlName="description" required>
                </mat-form-field>
            </div>
        </div>
        <h1 mat-dialog-title>
            Contact Information
        </h1>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Cell</mat-label>
                    <input matInput type="text" formControlName="cell">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Email</mat-label>
                    <input matInput type="text" formControlName="email" required>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Tel</mat-label>
                    <input matInput type="text" formControlName="phoneNumber">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Fax Number</mat-label>
                    <input matInput type="text" formControlName="faxNumber">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Physical Address Line 1</mat-label>
                    <input matInput type="text" formControlName="physicalAddressLine1" required>
                </mat-form-field>

            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Postal Address Line 1</mat-label>
                    <input matInput type="text" formControlName="postalAddressLine1">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Physical Address Line 2</mat-label>
                    <input matInput type="text" formControlName="physicalAddressLine2" required>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Postal Address Line 2</mat-label>
                    <input matInput type="text" formControlName="postalAddressLine2">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Physical Address Code</mat-label>
                    <input matInput type="text" formControlName="physicalAddressCode" required>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field fxFlex>
                    <mat-label>Postal Address Code</mat-label>
                    <input matInput type="text" formControlName="postalAddressCode">
                </mat-form-field>
            </div>
        </div>
    </div>
    <span class="spacer"></span>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onSubmit('btnCancel')" [disabled]="form.invalid">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit('btnSave')" [disabled]="form.invalid">Save</button>
    </div>
</form>